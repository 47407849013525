import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useClickAway } from 'react-use'
import arrow from './img/arrow.svg'

export type SelectItemProps = {
  name: string | number | boolean
  key: any
}

export type SelectProps = {
  label?: string
  id?: string
  options: Array<SelectItemProps>
  value: SelectItemProps['name']
  onSelect?: (data: SelectItemProps) => void
}

const SelectBoxWrapper = styled.div`
  position: relative;
`

const SelectBox = styled.div`
  height: 40px;
  background: #040e23;
  border: 1px solid #162c57;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  position: relative;
`

const SelectLabel = styled.label`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #98abd0;
  margin-bottom: 10px;
  display: block;
`

const SelectBoxArrow = styled.div<{ open: boolean }>`
  width: 12px;
  height: 6px;
  position: absolute;
  right: 10px;
  background: url(${arrow}) no-repeat;
  top: 50%;
  margin-top: -3px;
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
`

const SelectPopup = styled.div<{ open: boolean }>`
  position: absolute;
  display: ${(props) => (props.open ? 'block' : 'none')};
  padding: 10px;
  background: #040e23;
  border: 1px solid #162c57;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  z-index: 2;
`

const SelectPopupItem = styled.div`
  display: block;
  padding: 5px 0;

  &:hover {
    filter: brightness(80%);
    cursor: pointer;
  }
`

const Select = (props: SelectProps): JSX.Element => {
  const { id, label, options, onSelect, value } = props
  const selectRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useClickAway(selectRef, () => {
    setIsOpen(false)
  })

  return (
    <SelectBoxWrapper ref={selectRef}>
      {label && <SelectLabel htmlFor={id}>{label}</SelectLabel>}
      <SelectBox
        id={id}
        onClick={(e) => {
          e.preventDefault()
          setIsOpen(!isOpen)
        }}>
        {value}
        <SelectBoxArrow open={isOpen} />
      </SelectBox>
      <SelectPopup open={isOpen}>
        {options.map((item) => (
          <SelectPopupItem
            key={item.key}
            onClick={(e) => {
              e.preventDefault()
              setIsOpen(false)

              if (onSelect) onSelect(item)
            }}>
            {item.name}
          </SelectPopupItem>
        ))}
      </SelectPopup>
    </SelectBoxWrapper>
  )
}

export default Select
