import { FC, useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { StarFilled } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { LobbyGamesSection, SelectBox } from '../index'
import { ImgWrapper, RightActionBar, StyledFigure, StyledAddFavoriteButton } from './styles'
import routes from '../../core/routes'
import { SelectBoxOption } from '../SelectBox'
import { ISlotListGames } from '../../api/slot'
import { useStore } from '../../stores'

type ListGamesSlotProps = {
  title: string
  games: ISlotListGames
  onlyFavorite?: boolean
}

const ListGamesSlot: FC<ListGamesSlotProps> = observer((props) => {
  const { title, games, onlyFavorite } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { favoriteSlotListGamesStore } = useStore()
  const [options, setOptions] = useState<SelectBoxOption<number>[]>([])
  const defaultSelectBoxOption: SelectBoxOption<number> = {
    value: -1,
    label: t('all'),
  }
  const [selectedGameValue, setSelectedGameValue] = useState<SelectBoxOption<number>>(defaultSelectBoxOption)
  const [filter, setFilter] = useState<SelectBoxOption<number>>(defaultSelectBoxOption)

  useEffect(() => {
    setOptions([
      defaultSelectBoxOption,
      ...games.content.gameLabels.map((gameLabel, gameLabelIndex) => ({
        value: gameLabelIndex,
        label: gameLabel.split('_').join(' '),
      })),
    ])
  }, [games])

  useEffect(() => {
    setFilter(selectedGameValue)
  }, [selectedGameValue])

  return (
    <LobbyGamesSection
      title={title}
      titleChildren={
        <RightActionBar>
          {!onlyFavorite && (
            <SelectBox
              id="labels-slot-game"
              value={selectedGameValue}
              options={options}
              onChange={(newVal) => {
                setSelectedGameValue(newVal as SelectBoxOption<number>)
              }}
            />
          )}
        </RightActionBar>
      }>
      {games?.content.gameList
        .filter((game) => {
          if (filter.value === defaultSelectBoxOption.value) return true
          const filterValue = filter.label.toUpperCase()
          const gameLabel = game.label.split('_').join(' ').toUpperCase()

          return filterValue === gameLabel
        })
        .filter((game) => {
          if (onlyFavorite) {
            return favoriteSlotListGamesStore.isFavoriteSlotGame(game.id)
          }
          return true
        })
        .map((game) => {
          return (
            <StyledFigure
              key={game.id}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                navigate(`${routes.slot}/${game.id}`, {})
              }}>
              <ImgWrapper>
                <LazyLoadImage src={game.img} alt={game.name} width={160} />

                <StyledAddFavoriteButton
                  active={favoriteSlotListGamesStore.isFavoriteSlotGame(game.id)}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    favoriteSlotListGamesStore.toggleSlotGameId(game.id)
                  }}>
                  <StarFilled />
                </StyledAddFavoriteButton>
              </ImgWrapper>
              <figcaption>{game.name}</figcaption>
            </StyledFigure>
          )
        })}
    </LobbyGamesSection>
  )
})

export default ListGamesSlot
