import styled from '@emotion/styled'

export const WrapperTextNumberCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 0;
`

export const TextNumberCell = styled.div<{ bg: string }>`
  height: 50%;
  width: 25%;
  background-image: ${(props) => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

export const ChipContainer = styled.div`
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`
