import { Global, css } from '@emotion/react'
import { mediaQueries, theme } from './theme'
import pattern from '../assets/img/pattern.png'
import 'normalize.css'

export const GlobalStyles = (): JSX.Element => {
  return (
    <Global
      styles={css`
        html,
        body,
        #root {
          width: 100%;
          height: 100%;
          min-height: 100%;
          padding: 0;
          margin: 0;
        }

        body {
          font-family: 'Roboto', sans-serif;
          color: ${theme.colors.var1};
          //background-color: #000;
          background-repeat: repeat;
          background-image: url(${pattern});
        }

        input {
          touch-action: none;
        }

        *,
        *:after,
        *:before {
          box-sizing: border-box;
        }

        :root {
          --toastify-toast-min-height: 44px;
        }

        .Toastify__toast-container--top-right {
          top: 1.5em;
          right: 1.5em;
        }

        .Toastify__toast-body {
          font-size: 12px;

          ${mediaQueries.medium} {
            font-size: 14px;
          }
        }
      `}
    />
  )
}
