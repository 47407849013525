import 'webrtc-adapter'
import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Global } from '@emotion/react'
import { io } from 'socket.io-client'
import { useEvent, useIdle, useMountedState } from 'react-use'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../stores'
import {
  LastBalls,
  Timer,
  Informer,
  FieldSwitcher,
  ActionBar,
  AddBalancePopup,
  Box,
  ExitGameBtn,
  WebrtcStream,
  VolumeBtn,
  PopupForm,
  VolumeRange,
  MainRouletteField,
} from '../../components'
import Container from './Container'
import routes from '../../core/routes'
import {
  Fields,
  GlobalStyles,
  Main,
  Mid,
  PreTop,
  SubContainer,
  TopActionLeft,
  TopActionRight,
  TopActions,
  TopInfoContainer,
  TopInfoOfGame,
} from './styles'
import { rulSocketEvents } from '../../constants/rulSocketEvents'
import { ConnectedEventResponse } from '../../services/RouletteEvents'
import { sessionIdUnknownStatus } from '../../stores/Roulette/SessionId.store'
import QuickGameRoulette from '../../components/QuickGameRoulette'
import storage, { STORAGE_KEYS } from '../../services/storage'

const Roulette: FC = observer(() => {
  const isMounted = useMountedState()
  const isIdle = useIdle(process.env.REACT_APP_IDLE_OF_GAME as unknown as number)
  const { t } = useTranslation()
  const { rouletteGameId } = useParams<{
    rouletteGameId: string
  }>()
  const navigate = useNavigate()
  const { rouletteStore, loginStore, notificationStore, listGamesStore, webrtcStreamStore } = useStore()
  const { uiStore, statusGameStore, userStore, standStore, sessionIdStore, depositStore } = rouletteStore

  const redirectToLobby = (): void => {
    if (isMounted()) navigate(routes.lobby, { replace: true })
  }

  const stopGameEvent = (): void => {
    notificationStore.showInfo(t('gameOverInfo'))

    setTimeout(() => {
      redirectToLobby()
    }, 5000)
  }

  const handleClickOpenAnotherGame = (newGameId: string): void => {
    if (rouletteGameId === newGameId) return

    rouletteStore.switchGame()
    rouletteStore.sendSwitchGame(newGameId)

    navigate(`${routes.roulette}/${newGameId}`)
  }

  const init = (accessToken: string): (() => void) => {
    const socket = io(`${process.env.REACT_APP_API_URL}/player`, {
      query: {
        role: 'player',
        accessToken,
      },
      secure: true,
      transports: ['websocket'],
      forceNew: true,
    })

    socket.on(rulSocketEvents.CONNECT, () => {
      const deposit = storage.get(STORAGE_KEYS.ROULETTE_DEPOSIT)
      socket.emit(rulSocketEvents.JOIN_GAME, {
        gameId: rouletteGameId,
        ...(deposit && { deposit }),
      })

      storage.remove(STORAGE_KEYS.ROULETTE_DEPOSIT)
    })

    socket.on(rulSocketEvents.JOIN_GAME, (data: ConnectedEventResponse) => {
      rouletteStore.events.joinGameEvent(data)

      socket.emit(
        rulSocketEvents.SUBSCRIBE_GAMES,
        listGamesStore.gameScheduleRoulette.filter((item) => item.id !== data.gameId).map((item) => item.id)
      )

      rouletteStore.initGame({
        gameId: rouletteGameId as string,
        socket,
      })
    })
    socket.on(rulSocketEvents.END_BET, rouletteStore.events.endBetEvent)
    socket.on(rulSocketEvents.NEW_SESSION_OF_ROULETTE, rouletteStore.events.newSessionOfRouletteEvent)
    socket.on(rulSocketEvents.NO_SPIN, rouletteStore.events.noSpinEvent)
    socket.on(rulSocketEvents.PAUSE, rouletteStore.events.pauseEvent)
    socket.on(rulSocketEvents.RESULT, rouletteStore.events.resultEvent)
    socket.on(rulSocketEvents.ROULETTE_BET, rouletteStore.events.rouletteBetEvent)
    socket.on(rulSocketEvents.SELECT_NUMBER_ROULETTE, rouletteStore.events.selectNumberRouletteEvent)
    socket.on(rulSocketEvents.SESSION_END, rouletteStore.events.sessionEndEvent)
    socket.on(rulSocketEvents.SESSION_STATUS, rouletteStore.events.sessionStatusEvent)
    socket.on(rulSocketEvents.SPIN, rouletteStore.events.spinEvent)
    socket.on(rulSocketEvents.TIMER, rouletteStore.events.timerEvent)
    socket.on(rulSocketEvents.CHAT, rouletteStore.events.chatEvent)
    socket.on(rulSocketEvents.DEPOSIT, rouletteStore.events.depositEvent)
    socket.on(rulSocketEvents.SUBSCRIBE_GAMES, rouletteStore.events.subscribeGames)

    // выход из игры
    socket.on(rulSocketEvents.STOP_GAME, stopGameEvent)
    socket.on(rulSocketEvents.GAME_ERROR, redirectToLobby)
    socket.on(rulSocketEvents.CONNECT_ERROR, redirectToLobby)
    socket.on(rulSocketEvents.FINISH_GAME, redirectToLobby)
    socket.on(rulSocketEvents.DISCONNECT, redirectToLobby)

    return () => {
      socket.removeAllListeners()
      socket.disconnect()
    }
  }

  // ушел со страницы с игрой
  useEffect(() => {
    webrtcStreamStore.connectSocket()

    return () => {
      rouletteStore.resetGame()
    }
  }, [])

  // поменялся токен
  useEffect(() => {
    const { accessToken } = loginStore

    const destroySocket = init(accessToken)

    return () => {
      storage.set(STORAGE_KEYS.ROULETTE_DEPOSIT, depositStore.deposit)

      destroySocket()
    }
  }, [loginStore.accessToken])

  // если без любой активности то выход
  useEffect(() => {
    if (isIdle) redirectToLobby()
  }, [isIdle])

  const beforeUnloadEvent = (): void => {
    storage.set(STORAGE_KEYS.ROULETTE_DEPOSIT, depositStore.deposit)
  }

  useEvent('beforeunload', beforeUnloadEvent)

  return (
    <>
      <Global styles={GlobalStyles} />
      {uiStore.isShowBalancePopup && <AddBalancePopup />}
      <PopupForm
        title={t('volumeSetting')}
        open={uiStore.isShowVolumePopup}
        showSubmit={false}
        showCancel={false}
        onClose={() => {
          uiStore.setIsShowVolumePopup(false)
        }}>
        <Box mb={15}>
          <VolumeRange />
        </Box>
      </PopupForm>
      <Container
        sessionId={sessionIdStore.id}
        userId={loginStore.user?.id}
        login={loginStore.user?.login}
        gameId={rouletteGameId as string}>
        <Main showMobileLayer={statusGameStore.isBetting}>
          <PreTop>
            <TopActions>
              <TopActionLeft>
                <QuickGameRoulette
                  currentGameId={rouletteGameId as string}
                  onClickOpenGame={handleClickOpenAnotherGame}
                />
              </TopActionLeft>

              <TopActionRight>
                <Box mr="5px">
                  <VolumeBtn />
                </Box>
                <ExitGameBtn />
              </TopActionRight>
            </TopActions>
          </PreTop>

          <div>
            <Timer />
            <TopInfoContainer>
              <Box display="flex">
                {standStore.name && <TopInfoOfGame>{standStore.name}</TopInfoOfGame>}
                {sessionIdStore.id !== sessionIdUnknownStatus && (
                  <TopInfoOfGame>, id {sessionIdStore.id}</TopInfoOfGame>
                )}
              </Box>
              <Informer />
              {userStore.name && <TopInfoOfGame>{userStore.name}</TopInfoOfGame>}
            </TopInfoContainer>
          </div>

          <SubContainer>
            <QuickGameRoulette currentGameId={rouletteGameId as string} onClickOpenGame={handleClickOpenAnotherGame} />
            <FieldSwitcher />
          </SubContainer>

          <Mid>
            <WebrtcStream />
            <Fields disabled={!statusGameStore.isBetting} isTranslucency={statusGameStore.isSpinStatus}>
              <MainRouletteField />
            </Fields>
          </Mid>

          <ActionBar />
        </Main>

        <LastBalls />
      </Container>
    </>
  )
})

export default Roulette
