import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { Zero } from './styles'
import { CellCoreMainField, WinningShare } from '../index'
import { WinBetContainer } from '../CellMainField/styles'

const nameField = 'field:0'

const ZeroCell = observer((): JSX.Element => {
  const { rouletteStore } = useStore()
  const { statusGameStore, uiStore, betsService, lastBallsStore } = rouletteStore
  const isShowWinShare: boolean = statusGameStore.isShowWinShare && `field:${lastBallsStore.lastBall}` === nameField

  return (
    <Zero
      disabled={!statusGameStore.isBetting}
      lightning={uiStore.mouseEnterTrackBets.includes(nameField) && statusGameStore.isBetting}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()

        betsService.addBet(nameField)
      }}
      onMouseEnter={(e) => {
        e.preventDefault()

        uiStore.setMouseEnterTrackBets([nameField])
      }}
      onMouseLeave={(e) => {
        e.preventDefault()
        uiStore.setMouseEnterTrackBets([])
      }}>
      {isShowWinShare && (
        <WinBetContainer>
          <WinningShare />
        </WinBetContainer>
      )}
      <CellCoreMainField nameField={nameField} />
    </Zero>
  )
})

export default ZeroCell
