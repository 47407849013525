import styled from '@emotion/styled'
import { mediaQueries } from '../../core/theme'

const numCellWidth = 53
const numCellHeight = 54.3
// const dozenCellWidth = 218
const dozenCellHeight = 26
// const comboCellWidth = 109
const comboCellHeight = 26
const zeroWidth = 55
export const borderMainField = '1px solid #FFDC90'
const dozenCellsSize = {
  small: [214, 216, 214],
  medium: [214, 216, 214],
  large: [286, 288, 286],
  big: [338, 340, 338],
}

export const CommonContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 10px;

  ${mediaQueries.medium} {
    display: block;
    padding: 0;
  }
`

export const CommonContainerInside = styled.div<{
  scale: number
}>`
  display: inline-grid;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(${(props) => props.scale || 1});
  transform-origin: center center;

  ${mediaQueries.medium} {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0) scale(${(props) => props.scale || 1});
    transform-origin: center 0;
  }
`

export const WrapperFields = styled.div<{
  visible: boolean
}>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;

  ${mediaQueries.medium} {
    display: flex;
    margin-bottom: 50px;
  }
`

export const Container = styled.div<{
  scale: number
}>`
  display: inline-grid;
  margin-left: 1px;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(${(props) => props.scale || 1});
  transform-origin: center center;

  ${mediaQueries.medium} {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0) scale(${(props) => props.scale || 1});
    transform-origin: center 0;
  }
`

export const MainGrid = styled.div`
  display: inline-grid;
  grid-template-columns: 55px auto 31px;

  ${mediaQueries.large} {
    grid-template-columns: 72px auto 43px;
  }

  ${mediaQueries.big} {
    grid-template-columns: 84px auto 50px;
  }
`

export const Left = styled.div`
  display: inline-grid;
`

export const Middle = styled.div`
  display: inline-grid;
`

export const DozensWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 3px;
`

export const DozenWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(4, ${numCellWidth}px);
  grid-template-rows: repeat(3, ${numCellHeight}px);
  border-top: ${borderMainField};
  border-left: ${borderMainField};

  &:first-of-type {
    border-left: none;
  }

  ${mediaQueries.large} {
    grid-template-columns: repeat(4, 71px);
    grid-template-rows: repeat(3, 71px);
  }

  ${mediaQueries.big} {
    grid-template-columns: repeat(4, 84px);
    grid-template-rows: repeat(3, 84px);
  }
`

export const Right = styled.div`
  display: inline-grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(3, ${numCellHeight}px);
  border-top: ${borderMainField};

  ${mediaQueries.large} {
    grid-template-rows: repeat(3, 71px);
  }

  ${mediaQueries.big} {
    grid-template-rows: repeat(3, 84px);
  }
`

export const DozenCells = styled.div`
  display: inline-grid; // 214px 216px 214px
  grid-template-columns: ${() => {
    const { small } = dozenCellsSize
    return `${small[0]}px ${small[1]}px ${small[2]}px`
  }};
  grid-template-rows: ${dozenCellHeight}px;
  border-top: ${borderMainField};
  border-left: ${borderMainField};
  margin-top: 5px;

  ${mediaQueries.large} {
    grid-template-columns: ${() => {
      const { large } = dozenCellsSize
      return `${large[0]}px ${large[1]}px ${large[2]}px`
    }};
    grid-template-rows: 35px;
  }

  ${mediaQueries.big} {
    grid-template-columns: ${() => {
      const { big } = dozenCellsSize
      return `${big[0]}px ${big[1]}px ${big[2]}px`
    }};
    grid-template-rows: 47px;
  }
`

export const CombinationCells = styled.div`
  display: inline-grid; // 107px 107px 108px 108px 107px 107px;
  grid-template-columns: ${() => {
    const { small } = dozenCellsSize
    return `${small[0] / 2}px ${small[0] / 2}px ${small[1] / 2}px ${small[1] / 2}px ${small[2] / 2}px ${small[2] / 2}px`
  }};
  grid-template-rows: ${comboCellHeight}px;
  border-left: ${borderMainField};

  ${mediaQueries.large} {
    grid-template-columns: ${() => {
      const { large } = dozenCellsSize
      return `${large[0] / 2}px ${large[0] / 2}px ${large[1] / 2}px ${large[1] / 2}px ${large[2] / 2}px ${
        large[2] / 2
      }px`
    }};
    grid-template-rows: 35px;
  }

  ${mediaQueries.big} {
    grid-template-columns: ${() => {
      const { big } = dozenCellsSize
      return `${big[0] / 2}px ${big[0] / 2}px ${big[1] / 2}px ${big[1] / 2}px ${big[2] / 2}px ${big[2] / 2}px`
    }};
    grid-template-rows: 47px;
  }
`

export const SubGrid = styled.div`
  display: inline-grid;
  width: fit-content;
  margin-left: ${zeroWidth}px;

  ${mediaQueries.large} {
    margin-left: 72px;
  }

  ${mediaQueries.big} {
    margin-left: 84px;
  }
`
