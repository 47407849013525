import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import { CompleteIcon, RoundBtn } from '../index'
import { useStore } from '../../stores'
import { mediaQueries } from '../../core/theme'

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;
    height: 16px;

    ${mediaQueries.medium} {
      width: 20.57px;
      height: 27.43px;
    }

    ${mediaQueries.large} {
      width: 25.71px;
      height: 34.29px;
    }
  }
`

const CompleteBtn = observer((): JSX.Element => {
  const { rouletteStore } = useStore()
  const { statusGameStore, uiStore } = rouletteStore
  const disabled = !statusGameStore.isBetting

  return (
    <RoundBtn onClick={uiStore.toggleModeBets} active={uiStore.isModeBetComplete} disabled={disabled}>
      <StyledIcon>
        <CompleteIcon active={uiStore.isModeBetComplete} />
      </StyledIcon>
    </RoundBtn>
  )
})

export default CompleteBtn
