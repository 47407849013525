import { makeAutoObservable } from 'mobx'
import instance from '../services/http'
import { LoginStore } from './Login.store'

export class HttpStore {
  http: typeof instance = instance

  loginStore: LoginStore

  constructor(loginStore: LoginStore) {
    makeAutoObservable(this, {
      http: false,
    })

    this.loginStore = loginStore
  }
}
