import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import routes from '../../core/routes'
import { useStore } from '../../stores'
import { AuthForm, TextField, Box, Button, AuthErrorText } from '../index'

const LoginSms = observer((): JSX.Element => {
  const { t } = useTranslation()
  const { loginStore, applicationStore } = useStore()
  const navigate = useNavigate()
  const [authorizationCode, setAuthorizationCode] = useState<string>('')
  const [isShowError, setIsShowError] = useState<boolean>(false)

  return (
    <Box>
      {isShowError && <AuthErrorText>{t('smsNotValid')}</AuthErrorText>}
      <AuthForm
        onSubmit={async (e) => {
          e.preventDefault()
          setIsShowError(false)
          try {
            const dataUser = await loginStore.getToken(authorizationCode)

            navigate(routes.lobby)

            await applicationStore.fpJsClient?.getVisitorData({
              linkedId: dataUser.login,
              tag: {
                userAction: `login`,
              },
            })
          } catch {
            setIsShowError(true)
          }
        }}>
        <Box mb={40}>
          <TextField
            id="smsCodeLogin"
            label={t('enterCodeFromSms')}
            value={authorizationCode}
            onChange={(e) => {
              setAuthorizationCode(e.target.value)
            }}
          />
        </Box>
        <Button fullWidth type="submit" label={t('continue')} />
      </AuthForm>
    </Box>
  )
})

export default LoginSms
