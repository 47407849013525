import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { FC } from 'react'
import { StarFilled } from '@ant-design/icons'
import { RoundBtn } from '../index'
import { mediaQueries } from '../../core/theme'

interface IconProps {
  active?: boolean
}

const StyledRoundBtn = styled(RoundBtn)`
  ${mediaQueries.medium} {
    margin: 0;
  }
`

const StyledIcon = styled(StarFilled)<IconProps>`
  svg {
    width: 12px;
    height: 12px;

    ${mediaQueries.medium} {
      width: 17px;
      height: 17px;
    }

    ${mediaQueries.large} {
      width: 21px;
      height: 21px;
    }

    fill: ${(props) =>
      props.active ? props.theme.colors.var3 : 'white'}; /* Цвет иконки в зависимости от активности */
  }
`

type FavoriteGameBtnProps = {
  onClick: () => void
  active?: boolean
}

const FavoriteGameBtn: FC<FavoriteGameBtnProps> = observer((props: FavoriteGameBtnProps) => {
  const { onClick, active } = props

  return (
    <StyledRoundBtn
      onClick={(e) => {
        e.preventDefault()

        onClick()
      }}>
      <StyledIcon active={active} />
    </StyledRoundBtn>
  )
})

export default FavoriteGameBtn
