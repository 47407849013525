import styled from '@emotion/styled'

export const Zero = styled.div<{
  lightning?: boolean
  disabled: boolean
}>`
  width: 100%;
  height: 100%;
  clip-path: polygon(41% 0, 100% 0, 100% 100%, 41% 100%, 0 50%);
  position: relative;
  background: ${(props) => (props.lightning ? 'rgba(255, 255, 255, 0.4)' : 'none')};
  cursor: pointer;

  div[data-image] {
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`
