import { observer } from 'mobx-react-lite'
import { get } from 'lodash'
import { NameFieldBets } from '../../services/nameFieldBets'
import { useStore } from '../../stores'
import { StyledArea } from './styles'
import { ChipCell } from '../index'
import { ChipContainer } from '../CellCoreMainField/styles'

type SideCellsMainFieldProps = {
  left?: NameFieldBets
  bottomLeft?: NameFieldBets
  bottom?: NameFieldBets
}

export const shift = [
  'split:12:15',
  'corner:11:12:14:15',
  'split:10:13',
  'split:11:14',
  'sixline:10:11:12:13:14:15',
  'split:10:13',
  'corner:10:11:13:14',
  'split:24:27',
  'corner:23:24:26:27',
  'split:23:26',
  'corner:22:23:25:26',
  'split:22:25',
  'sixline:22:23:24:25:26:27',
]

export type AreaProps = {
  nameField: NameFieldBets
  position: 'left' | 'bottomLeft' | 'bottom'
}

const Area = observer((props: AreaProps): JSX.Element => {
  const { nameField, position } = props
  const { rouletteStore } = useStore()
  const { uiStore, betsStore, statusGameStore, betsService } = rouletteStore

  return (
    <StyledArea
      disabled={!statusGameStore.isBetting}
      shift={shift.includes(nameField)}
      position={position}
      onMouseEnter={(e) => {
        e.preventDefault()
        e.stopPropagation()

        const hoverCells = nameField
          .split(':')
          .slice(1)
          .map((item) => `field:${item}`) as Array<NameFieldBets>

        uiStore.setMouseEnterTrackBets([...hoverCells])
      }}
      onMouseLeave={(e) => {
        e.preventDefault()
        uiStore.setMouseEnterTrackBets([])
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()

        betsService.addBet(nameField)
      }}>
      <ChipContainer>
        <ChipCell chipValue={get(betsStore.bets, nameField, 0)} transparent={betsStore.loseBets.includes(nameField)} />
      </ChipContainer>
    </StyledArea>
  )
})

const SideCellsMainField = observer((props: SideCellsMainFieldProps): JSX.Element => {
  const { bottom, bottomLeft, left } = props

  return (
    <>
      {left && <Area position="left" nameField={left} />}
      {bottomLeft && <Area position="bottomLeft" nameField={bottomLeft} />}
      {bottom && <Area position="bottom" nameField={bottom} />}
    </>
  )
})

export default SideCellsMainField
