import { makeAutoObservable } from 'mobx'
import { findIndex } from 'lodash'
import { AvailabilityChipsets, ChipsetsKey, ChipsRange } from '../../services/chipsets'

export default class ChipsStore {
  chips: ChipsRange = AvailabilityChipsets[process.env.REACT_APP_CHIPSETS as ChipsetsKey]

  selectedChip: number = this.chips[0]

  constructor() {
    makeAutoObservable(this)
  }

  get selectedChipIndex(): number {
    return findIndex(this.chips, (item) => item === this.selectedChip)
  }

  setSelectedChip = (data: number): void => {
    this.selectedChip = data
  }
}
