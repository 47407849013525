import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { gameSchedule, GameScheduleResponseRow } from '../api/gameSchedule'
import { gameStatuses } from '../constants/gameStatuses'

// статус игры когда в нее можно зайти
export const allowedStatusGame = [250]

export class ListGamesStore {
  gameScheduleRoulette: Array<GameScheduleResponseRow> = []

  constructor() {
    makeAutoObservable(this)
  }

  fetchGameScheduleRoulette = async (): Promise<void> => {
    try {
      const games = await gameSchedule({
        gameName: 'roulette',
        gameType: 'public',
        status: gameStatuses.started.code,
      })

      runInAction(() => {
        this.gameScheduleRoulette = games.rows
      })
    } catch (e) {
      const err = e as AxiosError
      console.error(err.message)
      throw e
    }
  }
}
