import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useStore } from '../../stores'
import { chipUrl } from '../../services/chipsets'
import { mediaQueries } from '../../core/theme'

type ChipsPanelProps = {
  onShowChips: (data: boolean) => void
}

const Container = styled.div<{
  visible: boolean
  disabled: boolean
}>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.2' : '1')};

  ${mediaQueries.medium} {
    display: flex;
  }
`

const ChipItem = styled.div`
  cursor: pointer;
  margin: 0 3px;
`

const ChipItemMobile = styled(ChipItem)<{
  disabled: boolean
}>`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.2' : '1')};
  ${mediaQueries.medium} {
    display: none;
  }
`

const ChipImg = styled.img<{
  active?: boolean
}>`
  border-radius: 50%;
  border: 1px solid ${(props) => (props.active ? '#ffffff' : 'transparent')};
  padding: 1px;
  display: block;
  width: 36px;
  height: 36px;

  ${mediaQueries.medium} {
    width: 48px;
    height: 48px;
  }

  ${mediaQueries.large} {
    width: 60px;
    height: 60px;
  }
`

const ChipsPanel = observer((props: ChipsPanelProps): JSX.Element => {
  const { onShowChips } = props
  const { rouletteStore } = useStore()
  const { chipsStore, statusGameStore } = rouletteStore
  const [isShowChips, setIsShowChips] = useState<boolean>(false)

  return (
    <div>
      {!isShowChips && (
        <ChipItemMobile
          disabled={!statusGameStore.isBetting}
          onClick={(e) => {
            e.preventDefault()

            setIsShowChips(true)
            onShowChips(true)
          }}>
          <ChipImg src={chipUrl[chipsStore.selectedChipIndex]} alt={String(chipsStore.selectedChipIndex)} />
        </ChipItemMobile>
      )}

      <Container disabled={!statusGameStore.isBetting} visible={isShowChips}>
        {chipsStore.chips.map((item, index) => (
          <ChipItem
            key={item}
            onClick={(e) => {
              e.preventDefault()

              chipsStore.setSelectedChip(item)

              setIsShowChips(false)
              onShowChips(false)
            }}>
            <ChipImg
              active={chipsStore.selectedChip === item}
              src={chipUrl[index]}
              alt={String(chipsStore.selectedChip)}
            />
          </ChipItem>
        ))}
      </Container>
    </div>
  )
})

export default ChipsPanel
