import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import x2Icon from './img/x2.svg'
import { RoundBtn } from '../index'
import { useStore } from '../../stores'
import { mediaQueries } from '../../core/theme'

const StyledImg = styled.img`
  width: 11px;
  height: 12px;

  ${mediaQueries.medium} {
    width: 17.71px;
    height: 12.36px;
  }

  ${mediaQueries.large} {
    width: 22.14px;
    height: 15.45px;
  }
`

const DoublingBtn = observer((): JSX.Element => {
  const { rouletteStore } = useStore()
  const { statusGameStore, betsService } = rouletteStore
  const disabled = !statusGameStore.isBetting

  return (
    <RoundBtn onClick={betsService.addDoubleBets} disabled={disabled}>
      <StyledImg src={x2Icon} alt="doubling" />
    </RoundBtn>
  )
})

export default DoublingBtn
