import { useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import logoSmall from './img/logo-small.svg'
import exitIcon from './img/exit.svg'
import { Box, Currency } from '../index'
import { useStore } from '../../stores'
import { numberWithSpaces } from '../../utils/number'
import { lang } from '../../core/i18n'
import {
  CurrentLanguage,
  HeaderBalance,
  HeaderBalanceName,
  HeaderLeft,
  HeaderLogo,
  HeaderPanel,
  HeaderPanelInside,
  HeaderRight,
  LocaleDropDownMenu,
  LocaleDropDownMenuItem,
  LocaleWrapper,
  LogoutIcon,
} from './styles'

const Header = observer((): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { applicationStore, loginStore } = useStore()
  const ref = useRef(null)
  const [open, setOpen] = useState<boolean>(false)

  useClickAway(ref, () => {
    setOpen(false)
  })

  return (
    <HeaderPanel>
      <HeaderPanelInside>
        <HeaderLeft>
          <HeaderLogo src={logoSmall} />
          <HeaderBalance>
            <HeaderBalanceName>{t('balance')}: &nbsp;</HeaderBalanceName>
            <div>
              {numberWithSpaces(applicationStore.balance)} <Currency />
            </div>
          </HeaderBalance>
        </HeaderLeft>
        <HeaderRight>
          <Box fontWeight="bold" fontSize="15px" lineHeight="15px">
            {loginStore.user?.login}
          </Box>
          <LocaleWrapper
            ref={ref}
            onClick={(e) => {
              e.preventDefault()
              setOpen(!open)
            }}>
            <CurrentLanguage>{i18n.language}</CurrentLanguage>
            <LocaleDropDownMenu open={open}>
              {lang.map((item) => (
                <LocaleDropDownMenuItem
                  key={item.key}
                  onClick={async (e) => {
                    e.preventDefault()
                    await i18n.changeLanguage(item.key)
                    setOpen(false)
                  }}>
                  {item.key}
                </LocaleDropDownMenuItem>
              ))}
            </LocaleDropDownMenu>
          </LocaleWrapper>

          <Box>
            <LogoutIcon
              onClick={async (e) => {
                e.preventDefault()
                await loginStore.logout()
              }}
              src={exitIcon}
              alt="logout"
            />
          </Box>
        </HeaderRight>
      </HeaderPanelInside>
    </HeaderPanel>
  )
})

export default Header
