import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ToastContainer } from 'react-toastify'
import { debounce } from 'lodash'
import { useEvent, useMount } from 'react-use'
import { useStore } from '../../stores'

const AppLayout = observer(() => {
  const { applicationStore, notificationStore } = useStore()
  const setOffline = (): void => applicationStore.setIsOffline(true)
  const setOnline = (): void => applicationStore.setIsOffline(false)
  const appSize = (): void => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    doc.style.setProperty('--app-width', `${window.innerWidth}px`)
  }

  useEvent('offline', setOffline)
  useEvent('online', setOnline)
  useEvent('resize', debounce(appSize, 100))
  useEvent('orientationchange', debounce(appSize, 100))

  useMount(() => {
    appSize()
  })

  return (
    <>
      <Outlet />
      <ToastContainer
        position={notificationStore.position}
        closeButton={false}
        limit={3}
        icon={false}
        hideProgressBar
        theme="dark"
      />
    </>
  )
})

export default AppLayout
