import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ru from '../translations/ru.json'
import en from '../translations/en.json'

export enum AvailableLanguages {
  EN = 'en',
  RU = 'ru',
}

const resources = {
  ru,
  en,
}
const fallbackLng = [AvailableLanguages.EN]
export const availableLanguages = [AvailableLanguages.RU, AvailableLanguages.EN]
export const lang = [
  {
    name: 'Русский',
    key: AvailableLanguages.RU,
  },
  {
    name: 'English',
    key: AvailableLanguages.EN,
  },
]
export const langKeys: { [key: string]: string } = {
  [AvailableLanguages.RU]: 'Русский',
  [AvailableLanguages.EN]: 'English',
}

const options: InitOptions = {
  resources,
  fallbackLng,
  supportedLngs: availableLanguages,
  ns: ['common'],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
}

i18n.use(LanguageDetector).use(initReactI18next).init(options)

export default i18n
