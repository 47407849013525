import { useTranslation } from 'react-i18next'
import { ChangeEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'
import routes from '../../core/routes'
import {
  Box,
  RouteLink,
  Button,
  TextField,
  AuthForm,
  AuthErrorText,
} from '../index'
import { useStore } from '../../stores'

const validPassword = (str: string): boolean =>
  /^(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(str)

const RegistrationForm = observer((): JSX.Element => {
  const { t } = useTranslation()
  const { registrationStore } = useStore()
  const [login, setLogin] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [repeatPassword, setRepeatPassword] = useState<string>('')
  const [isShowError, setIsShowError] = useState<boolean>(false)

  const handleChangeLogin = (e: ChangeEvent<HTMLInputElement>): void => {
    setLogin(e.target.value)
  }
  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value)
  }
  const handleChangeRepeatPassword = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    setRepeatPassword(e.target.value)
  }

  return (
    <>
      <Box textAlign="center" fontSize={14} color="var1" mb={20}>
        {t('passwordRequiredDescription')}
      </Box>
      {isShowError && (
        <AuthErrorText>{t('loginOrPasswordNotValidMessage')}</AuthErrorText>
      )}
      <AuthForm
        onSubmit={async (e): Promise<void> => {
          e.preventDefault()
          setIsShowError(false)

          if (
            !login ||
            login.length < 6 ||
            !password ||
            password !== repeatPassword ||
            !validPassword(password)
          ) {
            setIsShowError(true)
            return
          }

          try {
            await registrationStore.registrationNewUser({
              login,
              password,
            })
          } catch {
            setIsShowError(true)
          }
        }}>
        <Box mb={20}>
          <TextField
            value={login}
            onChange={handleChangeLogin}
            label={t('login')}
            id="login"
          />
        </Box>
        <Box mb={20}>
          <TextField
            value={password}
            onChange={handleChangePassword}
            label={t('password')}
            id="password"
            type="password"
          />
        </Box>
        <Box mb={40}>
          <TextField
            value={repeatPassword}
            onChange={handleChangeRepeatPassword}
            label={t('repeatPassword')}
            id="repeatPassword"
            type="password"
          />
        </Box>
        <Box mb={40}>
          <Button label={t('register')} fullWidth type="submit" />
        </Box>
        <Box mb={20} display="flex" alignItems="center" justifyContent="center">
          <RouteLink to={routes.login}>{t('toComeIn')}</RouteLink>
        </Box>
      </AuthForm>
    </>
  )
})

export default RegistrationForm
