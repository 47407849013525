import { FC } from 'react'
import { Listbox } from '@headlessui/react'
import { CaretDownOutlined } from '@ant-design/icons'
import {
  IconSelect,
  StyledButtonDropDown,
  StyledListBox,
  StyledOption,
  StyledOptionItem,
  StyledOptions,
} from './styles'

export type SelectBoxOptionValue = number | string

export type SelectBoxOption<Type extends SelectBoxOptionValue> = {
  value: Type
  label: string
}

export type SelectBoxPros<Type extends SelectBoxOptionValue> = {
  id: string
  label?: string
  value: SelectBoxOption<Type>
  options: SelectBoxOption<Type>[]
  onChange: (value: SelectBoxOption<Type>) => void
}

const SelectBox: FC<SelectBoxPros<SelectBoxOptionValue>> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, label, value, options, onChange } = props

  return (
    <Listbox as={StyledListBox} value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Button as={StyledButtonDropDown}>
            {value.label}
            <IconSelect open={open}>
              <CaretDownOutlined />
            </IconSelect>
          </Listbox.Button>
          <Listbox.Options as={StyledOptions}>
            {options.map((option) => (
              <Listbox.Option as={StyledOption} key={option.value} value={option}>
                {(propsOption) => <StyledOptionItem {...propsOption}>{option.label}</StyledOptionItem>}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  )
}

export default SelectBox
