import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import routes from '../../core/routes'
import { AuthErrorText, AuthForm, Box, Button, RouteLink, Select, TextField } from '../index'
import { useStore } from '../../stores'
import { lang, langKeys } from '../../core/i18n'

const LoginForm = observer((): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { loginStore } = useStore()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isShowError, setIsShowError] = useState<boolean>(false)

  return (
    <Box>
      {isShowError && <AuthErrorText>{t('loginOrPasswordErrorMessage')}</AuthErrorText>}
      <AuthForm
        onSubmit={async (e) => {
          e.preventDefault()
          setIsShowError(false)

          try {
            await loginStore.login({
              username,
              password,
            })
          } catch {
            setIsShowError(true)
          }
        }}>
        <Box mb={20}>
          <TextField
            value={username}
            label={t('login')}
            id="login"
            onChange={(e) => {
              setUsername(e.target.value)
            }}
          />
        </Box>
        <Box mb={20}>
          <TextField
            value={password}
            label={t('password')}
            type="password"
            id="password"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </Box>
        <Box mb={40}>
          <Select
            id="lang"
            label={t('lang')}
            value={langKeys[i18n.language]}
            options={lang}
            onSelect={async (data) => {
              await i18n.changeLanguage(data.key)
            }}
          />
        </Box>
        <Box mb={40}>
          <Button fullWidth type="submit" label={t('toComeIn')} />
        </Box>
        <Box mb={20} display="flex" alignItems="center" justifyContent="center">
          <RouteLink to={routes.registration}>{t('registration')}</RouteLink>
        </Box>
        {/* <Box mb={20} display="flex" alignItems="center" justifyContent="center"> */}
        {/*   <RouteLink to={routes.restorePassword}> */}
        {/*     {t('restorePassword')} */}
        {/*   </RouteLink> */}
        {/* </Box> */}
      </AuthForm>
    </Box>
  )
})

export default LoginForm
