import chips from '@lbs/chips'

export type ChipsetsKey = 'doa_1_1k' | 'doa_5_1k' | 'doa_50_10k' | 'doa_50_25k'

export type ChipsRange = [number, number, number, number, number, number, number]

const currentChips = process.env.REACT_APP_CHIPSETS as ChipsetsKey

export const chipUrl = [
  chips[currentChips].panel.first,
  chips[currentChips].panel.second,
  chips[currentChips].panel.third,
  chips[currentChips].panel.fourth,
  chips[currentChips].panel.fifth,
  chips[currentChips].panel.sixth,
  chips[currentChips].panel.seventh,
]

export const chipUrlWithoutNum = [
  chips[currentChips].panelWithoutNum.first,
  chips[currentChips].panelWithoutNum.second,
  chips[currentChips].panelWithoutNum.third,
  chips[currentChips].panelWithoutNum.fourth,
  chips[currentChips].panelWithoutNum.fifth,
  chips[currentChips].panelWithoutNum.sixth,
  chips[currentChips].panelWithoutNum.seventh,
]

export const AvailabilityChipsets: {
  [key in ChipsetsKey]: ChipsRange
} = {
  doa_1_1k: [1, 5, 10, 25, 100, 500, 1000],
  doa_5_1k: [5, 10, 25, 50, 100, 500, 1000],
  doa_50_10k: [50, 100, 500, 1000, 2000, 5000, 10000],
  doa_50_25k: [50, 100, 500, 1000, 5000, 10000, 25000],
}
