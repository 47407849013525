import http from '../../services/http'
import { ILimitRow } from '../limits'
import { IStand } from '../stand'

export interface GameSchedulePayload {
  gameType: 'public' | 'private'
  gameName: 'roulette'
  status: number
}

export type GameScheduleResponseRow = {
  id: string
  status: number
  comments: string | null
  gameSettings: {
    timer: { toSpin: number; techPause: number; toEndBets: number }
    places: number
    limitId: string
    standId: string
    game_name: string
    game_type: string
    ipCyclops: string
    listBonus: Array<any>
    betMaximum: number
    typeMaximum: string
  }
  startsAt: string
  endsAt: null
  acceptedDealer: string
  createdBy: null
  createdFor: []
  createdAt: string
  updatedAt: string
  operator: {
    id: string
    login: string
    password: string
    firstName: string
    lastName: string
    role: string
    status: number
    data: { techCode: string }
    countLoginError: number
    createdAt: string
    updatedAt: string
  }
  limits: ILimitRow
  stand: IStand
}

export interface GameScheduleResponse {
  count: number
  countAll: number
  rows: Array<GameScheduleResponseRow>
}

export const gameSchedule = async (params: GameSchedulePayload): Promise<GameScheduleResponse> => {
  const response = await http.get('/api/gameSchedule', { params })
  return response.data
}
