import { observer } from 'mobx-react-lite'
import { useMount, useMountedState } from 'react-use'
import { isNumber } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../stores'
import { Item, StyledImg, Wrapper, ItemWrap, Info, WrapPicture, Ball, StyledTotalBets } from './styles'
import placeholderImg from './img/previewQuickGamePlaceholder.png'
import { rulSocketEvents } from '../../constants/rulSocketEvents'
import { numberWithSpaces } from '../../utils/number'
import { CurrencyDiamond } from '../index'

type QuickGameRouletteProps = {
  currentGameId: string
  onClickOpenGame: (gameId: string) => void
}

function Status(props: { status: string }): JSX.Element {
  const { status } = props
  const { t } = useTranslation()

  const getStatus = (st: string): JSX.Element => {
    switch (st) {
      case rulSocketEvents.NEW_SESSION_OF_ROULETTE:
        return <div>{t('placeYourBets')}</div>
      case rulSocketEvents.SELECT_NUMBER_ROULETTE:
        return <div>{t('result')}</div>
      case rulSocketEvents.RESULT:
        return <div>{t('result')}</div>
      default:
        return <div>{t('noMoreBets')}</div>
    }
  }

  return getStatus(status)
}

const TotalBets = (props: { totalBets: number }): JSX.Element => {
  const { totalBets } = props

  return (
    <StyledTotalBets>
      {numberWithSpaces(totalBets)} <CurrencyDiamond />
    </StyledTotalBets>
  )
}

const PayWin = (props: { pay: number }): JSX.Element => {
  const { pay } = props

  return (
    <StyledTotalBets>
      <span>+ {numberWithSpaces(pay)}</span> <CurrencyDiamond />
    </StyledTotalBets>
  )
}

const Infos = observer((props: { id: string; name: string; src: string }): JSX.Element | null => {
  const { id, name, src } = props
  const { rouletteStore } = useStore()
  const { subscribeGamesStore } = rouletteStore

  const game = subscribeGamesStore.games.get(id)
  const pay = game?.result?.amountRelativeNumber || 0

  const isBall = isNumber(game?.ball)

  return (
    <>
      <WrapPicture isBall={isBall}>
        <StyledImg src={src} alt={name} />
        <Ball>{game?.ball}</Ball>
      </WrapPicture>

      {game && (
        <Info>
          <Status status={game.event} />
          {[rulSocketEvents.SELECT_NUMBER_ROULETTE, rulSocketEvents.RESULT].includes(game.event) &&
            (pay > 0 ? <PayWin pay={pay} /> : name)}

          {![rulSocketEvents.SELECT_NUMBER_ROULETTE, rulSocketEvents.RESULT].includes(game.event) &&
            (game.totalBets > 0 ? <TotalBets totalBets={game.totalBets} /> : name)}
        </Info>
      )}
    </>
  )
})

const QuickGameRoulette = observer((props: QuickGameRouletteProps): JSX.Element => {
  const { currentGameId, onClickOpenGame } = props
  const { listGamesStore } = useStore()
  const isMounted = useMountedState()

  useMount(async () => {
    if (isMounted()) await listGamesStore.fetchGameScheduleRoulette()
  })

  return (
    <Wrapper>
      {listGamesStore.gameScheduleRoulette
        .filter((item) => item.id !== currentGameId)
        .map((item) => {
          const { stand } = item

          return (
            <Item
              key={stand.name}
              onClick={(e) => {
                e.preventDefault()

                onClickOpenGame(item.id)
              }}>
              <ItemWrap>
                <Infos id={item.id} name={stand.name} src={stand.data.fileQuickGamePreview || placeholderImg} />
              </ItemWrap>
            </Item>
          )
        })}
    </Wrapper>
  )
})

export default QuickGameRoulette
