import { makeAutoObservable } from 'mobx'
import { TrackNameFieldBets } from '../../services/trackBets'

export type MobileFieldView = 'main' | 'track'

export enum MODE_BETS {
  BET,
  COMPLETE,
}

class RouletteUIStore {
  isShowBalancePopup: boolean = false

  isShowVolumePopup: boolean = false

  // TODO фуллскрин не работает ios в мобильных устройствах
  isFullscreen: boolean = false

  activeTouchTrackBets: Array<TrackNameFieldBets> = []

  mouseEnterTrackBets: Array<TrackNameFieldBets> = []

  timerTouchTrackBets: NodeJS.Timeout | undefined

  mobileFieldView: MobileFieldView = 'main'

  modeBets: MODE_BETS = MODE_BETS.BET

  constructor() {
    makeAutoObservable(this, {
      timerTouchTrackBets: false,
    })
  }

  get isModeBetComplete(): boolean {
    return this.modeBets === MODE_BETS.COMPLETE
  }

  setIsShowVolumePopup = (data: boolean): void => {
    this.isShowVolumePopup = data
  }

  setShowBalancePopup = (data: boolean): void => {
    this.isShowBalancePopup = data
  }

  setIsFullscreen = (data: boolean): void => {
    this.isFullscreen = data
  }

  setActiveTouchTrackBets = (data: Array<TrackNameFieldBets>): void => {
    this.activeTouchTrackBets = data

    if (data.length > 0) {
      if (this.timerTouchTrackBets !== undefined) clearTimeout(this.timerTouchTrackBets)
      this.timerTouchTrackBets = setTimeout(() => {
        this.clearActiveTouchTrackBets()
      }, 500)
    }
  }

  clearActiveTouchTrackBets = (): void => {
    this.activeTouchTrackBets = []
  }

  setMouseEnterTrackBets = (data: Array<TrackNameFieldBets>): void => {
    this.mouseEnterTrackBets = data
  }

  setMobileFieldView = (data: MobileFieldView): void => {
    this.mobileFieldView = data
  }

  toggleModeBets = (): MODE_BETS => {
    this.modeBets = this.modeBets === MODE_BETS.BET ? MODE_BETS.COMPLETE : MODE_BETS.BET

    return this.modeBets
  }
}

export default RouletteUIStore
