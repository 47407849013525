import { makeAutoObservable } from 'mobx'

class VolumeStore {
  isMuted: boolean = true

  volume: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  setIsMuted = (data: boolean): void => {
    this.isMuted = data
  }

  setVolume = (data: number): void => {
    this.volume = data

    this.setIsMuted(data === 0)
  }

  setToggleMuted = (): void => {
    this.isMuted = !this.isMuted

    this.volume = this.isMuted ? 0 : 1
  }
}

export default VolumeStore
