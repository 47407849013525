import styled from '@emotion/styled'
import { useMount, useMountedState } from 'react-use'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../stores'
import placeholder1 from './img/placeholder1.png'
import placeholder2 from './img/placeholder2.png'
import placeholder3 from './img/placeholder3.png'
import placeholder4 from './img/placeholder4.png'
import routes from '../../core/routes'
import LobbyGamesSection from '../LobbyGamesSection'

const placeholdersPath = [placeholder1, placeholder2, placeholder3, placeholder4]

const ItemGame = styled.div`
  cursor: pointer;
  margin: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const ItemGameCard = styled.div`
  border-radius: 8px;
  background: #070f1d;
  border: 1px solid #1a3362;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

const ItemGameCardLimits = styled.div`
  background: #070f1d;
  height: 22px;
  width: 100%;
  z-index: 1;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #98abd0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ItemGameName = styled.div`
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`

const ItemGameCardImg = styled.img`
  width: 100%;
  height: 145px;
`

const ListGamesRoulette = observer((): JSX.Element => {
  const { listGamesStore } = useStore()
  const { t } = useTranslation()
  const isMounted = useMountedState()
  const navigate = useNavigate()

  useMount(() => {
    ;(async () => {
      if (isMounted()) await listGamesStore.fetchGameScheduleRoulette()
    })()
  })

  return (
    <LobbyGamesSection title={`${t('roulette')} Live`}>
      {listGamesStore.gameScheduleRoulette.map((item, key) => (
        <ItemGame
          key={item.id}
          onClick={(e) => {
            e.preventDefault()

            navigate(`${routes.roulette}/${item.id}`)
          }}>
          <ItemGameCard>
            <ItemGameCardImg src={item.stand.data?.fileLobbyPreview || placeholdersPath[key] || placeholdersPath[0]} />
            <ItemGameCardLimits>
              {item.limits.minimum} - {item.limits.maximum}
            </ItemGameCardLimits>
          </ItemGameCard>
          <ItemGameName>{item.stand.name}</ItemGameName>
        </ItemGame>
      ))}
    </LobbyGamesSection>
  )
})

export default ListGamesRoulette
