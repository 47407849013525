import { makeAutoObservable } from 'mobx'

type TLastBall = number | 'no spin'

export type LastBalls = Array<TLastBall>

class LastBallsStore {
  lastBalls: LastBalls = []

  lastBall: TLastBall = -1

  constructor() {
    makeAutoObservable(this)

    // For tests
    // function randomInteger(min: number, max: number): number {
    //   return Math.floor(Math.random() * (max - min + 1)) + min
    // }
    //
    // setInterval(() => {
    //   this.addLastBalls(randomInteger(0, 32))
    // }, 500)
  }

  setLastBalls = (data: LastBalls): LastBalls => {
    this.lastBalls = [...data]

    return this.lastBalls
  }

  addLastBall = (data: TLastBall): LastBalls => {
    this.lastBalls.unshift(data)
    this.lastBall = data

    if (this.lastBalls.length > 50) this.lastBalls.splice(50, 1)

    return this.lastBalls
  }
}

export default LastBallsStore
