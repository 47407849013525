import store from 'store2'

export enum STORAGE_KEYS {
  TOKEN = 'token',
  USER = 'user',
  TIME_AUTH = 'timeAuth',
  TIME_EXPIRED_TOKEN = 'timeExpiredToken',
  FAVORITE_SLOTS_GAMES = 'favoriteSlotsGames',
  ROULETTE_DEPOSIT = 'rouletteDeposit',
}

const storage = store.namespace('dl')

export default storage
