import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useUnmount } from 'react-use'
import {
  RegistrationForm,
  RegistrationSmsForm,
  Box,
  RegistrationSuccess,
  AuthContainer,
} from '../../components'
import { useStore } from '../../stores'
import { RegistrationProcessStatus } from '../../stores/Registration.store'

const Registration = observer(() => {
  const { t } = useTranslation()
  const { registrationStore } = useStore()

  useUnmount(() => {
    registrationStore.reset()
  })

  const step: { [key: number]: JSX.Element } = {
    [RegistrationProcessStatus.SMS]: <RegistrationSmsForm />,
    [RegistrationProcessStatus.ADD_USER]: <RegistrationForm />,
    [RegistrationProcessStatus.SUCCESS]: <RegistrationSuccess />,
  }

  return (
    <AuthContainer>
      <Box>
        <Box
          mb={20}
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="var1">
          {t('registration')}
        </Box>
        {step[registrationStore.processStatus]}
      </Box>
    </AuthContainer>
  )
})

export default Registration
