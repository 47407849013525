import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Footer, Header, ListGamesRoulette, ListGamesSlot, LobbyTitle } from '../../components'
import { ApiSlot } from '../../api'
import { useStore } from '../../stores'
import storage, { STORAGE_KEYS } from '../../services/storage'

const LobbyContainer = styled.div`
  display: grid;
  min-height: 100%;
  grid-template-rows: auto auto 1fr auto;
  grid-template-columns: 100%;
`

const Lobby: FC = observer(() => {
  const { t } = useTranslation()
  const { favoriteSlotListGamesStore, applicationStore } = useStore()

  const { data: slotListGames } = useQuery(
    'slot list games',
    async () => {
      return ApiSlot.getGameList(applicationStore.currency)
    },
    {
      enabled: !!ApiSlot.hall[applicationStore.currency].hall && !!ApiSlot.hall[applicationStore.currency].key,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    storage.remove(STORAGE_KEYS.ROULETTE_DEPOSIT)
  }, [])

  return (
    <LobbyContainer>
      <Header />
      <LobbyTitle>{t('lobby')}</LobbyTitle>

      <div>
        <ListGamesRoulette />
        {slotListGames?.content && favoriteSlotListGamesStore.games.length > 0 && (
          <ListGamesSlot key="favoriteSlotList" title={t('favorites')} games={{ ...slotListGames }} onlyFavorite />
        )}
        {slotListGames?.content && <ListGamesSlot key="slotList" title={t('slots')} games={{ ...slotListGames }} />}
      </div>
      <Footer />
    </LobbyContainer>
  )
})

export default Lobby
