export enum SentryTags {
  GameId = 'gameId',
  SessionId = 'sessionId',
  StandId = 'standId',
  StreamData = 'streamData',
  Chipsets = 'chipsets', // текущий набор фишек
  AntMediaUrl = 'antMediaUrl', // адрес медиасервера
  Client = 'client',
  User = 'user',
}
