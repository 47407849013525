import styled from '@emotion/styled'

export const ImgWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #1a3362;
  margin-bottom: 10px;
  position: relative;
`

export const StyledFigure = styled.figure`
  cursor: pointer;
  margin: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    border-radius: 8px;
    display: block;
  }

  figcaption {
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
`

export const RightActionBar = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  grid-template-columns: auto auto;
  gap: 16px;
`

export const StyledFavoriteButton = styled.a<{ active: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 4px;
  color: ${(props) => (props.active ? '#ff9a3d' : 'inherit')};
`

export const StyledAddFavoriteButton = styled(StyledFavoriteButton)`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
`
