import { makeAutoObservable } from 'mobx'
import { cloneDeep, findKey, forEach, get, has, intersection, isEmpty, isEqual, last, map, reduce } from 'lodash'
import { NameFieldBets } from '../../services/nameFieldBets'
import { getTrackBets, sortedTrackBets, trackBets, TrackNameFieldBets } from '../../services/trackBets'
import { getCompleteBets } from '../../services/completeBets'
import { getLimitsForBet, LimitsWarning, neededDeposit, processingBets } from '../../services/rouletteBets'
import { Limits } from './Limits.store'
import { MODE_BETS } from './UI.store'

export type HistoryBets = {
  [key in number]: Bets
}

export type Bets = Partial<{
  [key in NameFieldBets]: number
}>

export type BetsForValidation = {
  [key in string]: {
    limits: {
      minimum: number
      maximum: number
    }
    amount: number
    currentAmount: number
    modeBets: MODE_BETS
  }
}

export enum DepositErrorCode {
  // хватает денег на постановку ставок
  Enough,
  // не хватает денег на постановку ставок
  NoEnough,
}

export type ReturnedBets = {
  sumBets: number
  bets: Bets
  idBets: number
  limitWarningCode: LimitsWarning
  depositErrorCode: DepositErrorCode
}

export type ProcessedBets = {
  sumBets: number
  bets: Bets
  limitWarningCode: LimitsWarning
  depositErrorCode: DepositErrorCode
}

export type PreAddBet = {
  betName: NameFieldBets
  limits: Limits
  amount: number
  deposit: number
  modeBets: MODE_BETS
}

export type PreAddTrackBet = Omit<PreAddBet, 'betName'> & {
  trackBetName: TrackNameFieldBets
}

export enum TYPE_BET_ACTION {
  ADD = 'add',
  REDUCE = 'reduce',
  REDUCE_ALL = 'reduceAll',
  REPEAT = 'repeat',
}

class BetsStore {
  bets: Bets = {}

  historyBets: HistoryBets = {}

  winBets: Bets = {}

  loseBets: Array<NameFieldBets> = []

  existsBetsPrevSession: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  get isEmptyHistoryBets(): boolean {
    return isEmpty(this.historyBets)
  }

  get totalBets(): number {
    return Object.values(this.bets).reduce((acc, item) => acc + item, 0)
  }

  get trackBets(): Partial<{
    [key in TrackNameFieldBets]: number
  }> {
    const arrayHistoryBets = Object.entries(this.historyBets).sort((a, b) => Number(a[0]) - Number(b[0]))

    // TODO: track должен вернуть массив полей в треке
    const track = reduce(
      Object.fromEntries(arrayHistoryBets),
      (accTrackBets, itemHistoryBets) => {
        const keyFieldNames = map(itemHistoryBets, (item, key) => key)

        const findExactMatch =
          findKey(sortedTrackBets, (itemFind) => isEqual(itemFind, Object.keys(itemHistoryBets).sort())) || ''

        const betsExactMatch = reduce(
          // @ts-ignore
          sortedTrackBets[findExactMatch],
          (acc) => {
            // @ts-ignore
            acc[findExactMatch] = reduce(
              itemHistoryBets,
              (accHistory, itemHistory) => accHistory + (itemHistory || 0),
              0
            )

            return acc
          },
          {}
        )

        const findPossibleTrackKeys = reduce(
          sortedTrackBets,
          (acc, item) => {
            const intersectionFields = intersection(item, keyFieldNames)

            const exactMatchSearch = findKey(sortedTrackBets, (itemFind) => isEqual(itemFind, intersectionFields))

            if (exactMatchSearch && !has(betsExactMatch, exactMatchSearch)) {
              // @ts-ignore
              acc.push(exactMatchSearch)
            }

            return acc
          },
          []
        )

        const intersectionWithPrevRates = intersection(findPossibleTrackKeys, Object.keys(accTrackBets))

        forEach(intersectionWithPrevRates, (item) => {
          // @ts-ignore
          const fieldNames = sortedTrackBets[item]

          // @ts-ignore
          const amount = itemHistoryBets[item] * fieldNames.length

          // @ts-ignore
          betsExactMatch[item] = amount
        })

        forEach(betsExactMatch, (item, key) => {
          const newValue = get(accTrackBets, key, 0) + item
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          accTrackBets[key] = newValue
        })

        return accTrackBets
      },
      {}
    )

    const copyBets = { ...this.bets }

    return reduce(
      Object.keys(track),
      (acc, item) => {
        // @ts-ignore
        const fields = trackBets[item]

        // @ts-ignore
        acc[item] = reduce(fields, (accFields, itemField) => accFields + get(copyBets, itemField, 0), 0)

        return acc
      },
      {}
    )
  }

  setBets = (data: Bets): void => {
    this.bets = data
  }

  setLoseBets = (data: Array<NameFieldBets>): void => {
    this.loseBets = [...data]
  }

  setWinBets = (data: Bets): void => {
    this.winBets = data
  }

  setHistoryBets = (data: HistoryBets): void => {
    this.historyBets = cloneDeep(data)
  }

  setNewValidBets = (data: ProcessedBets): ReturnedBets => {
    this.mergeNewBets(data.bets)
    const historyBet = this.addHistoryBets(data.bets)

    return {
      sumBets: data.sumBets,
      bets: historyBet.bets,
      idBets: historyBet.id,
      limitWarningCode: data.limitWarningCode,
      depositErrorCode: data.depositErrorCode,
    }
  }

  setExistsBetsPrevSession = (data: boolean): void => {
    this.existsBetsPrevSession = data
  }

  recoveryBets = (data: HistoryBets): void => {
    const bets: Bets = {}

    this.historyBets = cloneDeep(data)

    forEach(data, (fieldsOfBet) => {
      forEach(fieldsOfBet, (amount, field) => {
        if (!amount) return

        const name = field as NameFieldBets

        bets[name] = get(bets, name, 0) + amount
      })
    })

    this.bets = { ...bets }
  }

  isSyncBets = (serverHistoryBets: HistoryBets, localHistoryBets: HistoryBets): boolean =>
    isEqual(serverHistoryBets, localHistoryBets)

  addHistoryBets = (
    bets: Bets,
    id = Date.now()
  ): {
    id: number
    bets: Bets
  } => {
    if (isEmpty(bets)) {
      return {
        id: 0,
        bets: {},
      }
    }
    this.historyBets[id] = bets

    return {
      id,
      bets,
    }
  }

  mergeNewBets = (data: Bets): void => {
    Object.entries(data).forEach(([fieldName, amount]) => {
      const currentAmountBet = get(this.bets, fieldName, 0)
      const newAmountBet = currentAmountBet + amount
      const name = fieldName as NameFieldBets

      if (newAmountBet <= 0) {
        delete this.bets[name]
      } else {
        this.bets[name] = newAmountBet
      }
    })
  }

  addWinBets = (data: { limits: Limits; deposit: number }): ReturnedBets => {
    const { limits, deposit } = data

    const betsForValidation = <BetsForValidation>reduce(
      this.winBets,
      (acc, item, key) => {
        const name = key as NameFieldBets

        const newField: BetsForValidation = {
          [name]: {
            limits: getLimitsForBet(name as NameFieldBets, limits),
            amount: get(this.winBets, name, 0),
            currentAmount: get(this.bets, name, 0),
            modeBets: MODE_BETS.BET,
          },
        }
        return {
          ...acc,
          ...newField,
        }
      },
      {}
    )

    const processedBets = processingBets(betsForValidation, deposit)

    return this.setNewValidBets({ ...processedBets })
  }

  addBet = (data: PreAddBet): ReturnedBets => {
    const { betName, limits, amount, deposit, modeBets } = data

    const arrBets = modeBets === MODE_BETS.COMPLETE ? getCompleteBets(betName) : [betName]

    const betsForValidation = <BetsForValidation>reduce(
      arrBets,
      (acc, item) => {
        const newField: BetsForValidation = {
          [item]: {
            limits: getLimitsForBet(item as NameFieldBets, limits),
            amount,
            currentAmount: get(this.bets, item, 0),
            modeBets,
          },
        }
        return {
          ...acc,
          ...newField,
        }
      },
      {}
    )

    const processedBets = processingBets(betsForValidation, deposit)

    return this.setNewValidBets({ ...processedBets })
  }

  addTrackBets = (data: PreAddTrackBet): ReturnedBets => {
    const { trackBetName, amount, deposit, limits, modeBets } = data

    const trackBet = getTrackBets(trackBetName)

    const excludeNameFieldBet: TrackNameFieldBets = 'serie:0:2:3'
    const excludeBets: Array<NameFieldBets> = ['street:0:2:3', 'corner:25:26:28:29']
    const isExcludeNameFieldBet: boolean = excludeNameFieldBet === trackBetName
    const specialMultiply = amount * 2

    const betsForValidation = <BetsForValidation>reduce(
      trackBet,
      (acc, item) => {
        const newField: BetsForValidation = {
          [item]: {
            limits: getLimitsForBet(item as NameFieldBets, limits),
            amount: isExcludeNameFieldBet && excludeBets.includes(item) ? specialMultiply : amount,
            currentAmount: get(this.bets, item, 0),
            modeBets,
          },
        }
        return {
          ...acc,
          ...newField,
        }
      },
      {}
    )

    const processedBets = processingBets(betsForValidation, deposit)

    return this.setNewValidBets({ ...processedBets })
  }

  addDoubleBets = (data: { limits: Limits; deposit: number }): ReturnedBets => {
    const { limits, deposit } = data

    const arrBets = Object.keys(this.bets)

    const betsForValidation = <BetsForValidation>reduce(
      arrBets,
      (acc, item) => {
        const newField: BetsForValidation = {
          [item]: {
            limits: getLimitsForBet(item as NameFieldBets, limits),
            amount: get(this.bets, item, 0),
            currentAmount: get(this.bets, item, 0),
            modeBets: MODE_BETS.BET,
          },
        }
        return {
          ...acc,
          ...newField,
        }
      },
      {}
    )

    const processedBets = processingBets(betsForValidation, deposit)

    return this.setNewValidBets({ ...processedBets })
  }

  removeLastBets = (): {
    lastBets: {
      id: number
      bets: Bets
    }
    returnedDeposit: number
  } | null => {
    if (isEmpty(this.historyBets)) return null

    const arrHistoryBets = Object.keys(this.historyBets).sort((a, b) => Number(a) - Number(b))
    const lastBetId = Number(last(arrHistoryBets))
    const lastBets: Bets = {
      ...this.historyBets[lastBetId],
    }
    const negativeBets = reduce(
      lastBets,
      (acc, item, key) => ({
        ...acc,
        [key]: item ? -item : 0,
      }),
      {}
    ) as Bets

    this.mergeNewBets(negativeBets)

    const returnedDeposit = neededDeposit(lastBets)

    delete this.historyBets[lastBetId]

    return {
      lastBets: {
        id: lastBetId,
        bets: lastBets,
      },
      returnedDeposit,
    }
  }

  removeAllBets = (): {
    returnedDeposit: number
  } | null => {
    if (isEmpty(this.bets)) return null

    const returnedDeposit = neededDeposit(cloneDeep(this.bets))

    this.bets = {}
    this.historyBets = {}

    return {
      returnedDeposit,
    }
  }
}

export default BetsStore
