import styled from '@emotion/styled'
import { mediaQueries } from '../../core/theme'

export const StyledImg = styled.img`
  width: 40px;
  height: 30px;
  display: block;

  ${mediaQueries.medium} {
    width: 80px;
    height: 60px;
  }
`

export const Ball = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`

export const WrapPicture = styled.div<{
  isBall?: boolean
}>`
  display: block;
  position: relative;
  margin-right: 5px;

  ${mediaQueries.medium} {
    margin-right: 10px;
  }

  ${Ball} {
    display: ${(props) => (props.isBall ? 'flex' : 'none')};
  }
`

export const Item = styled.div`
  cursor: pointer;
`

export const ItemWrap = styled.div`
  display: flex;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 10px;

  ${mediaQueries.medium} {
    font-size: 18px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  grid-column-gap: 10px;

  ${mediaQueries.medium} {
    grid-column-gap: 20px;
  }
`

export const StyledTotalBets = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #69c564;
  }

  img {
    padding-left: 5px;
  }

  ${mediaQueries.medium} {
    img {
      width: 18px;
    }
  }
`
