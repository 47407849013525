import React from 'react'
import Box from '../Box'

type AuthErrorTextProps = {
  children: React.ReactNode
}

const AuthErrorText = (props: AuthErrorTextProps): JSX.Element => {
  const { children } = props
  return (
    <Box color="error" textAlign="center" fontSize={12} mb={20}>
      {children}
    </Box>
  )
}

export default AuthErrorText
