import { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { debounce, isNumber } from 'lodash'
import { useEvent, useMount } from 'react-use'
import { useStore } from '../../stores'
import { NameFieldBets } from '../../services/nameFieldBets'
import {
  CombinationCells,
  CommonContainer,
  CommonContainerInside,
  DozenCells,
  DozensWrapper,
  DozenWrapper,
  Left,
  MainGrid,
  Middle,
  Right,
  SubGrid,
  WrapperFields,
} from './styles'
import { Box, CellMainField, TrackRouletteField } from '../index'
import ZeroCell from '../ZeroCell'
import { useIsMounted } from '../../hooks'

const firstDozen: Array<NameFieldBets> = [
  'field:3',
  'field:6',
  'field:9',
  'field:12',
  'field:2',
  'field:5',
  'field:8',
  'field:11',
  'field:1',
  'field:4',
  'field:7',
  'field:10',
]

const secondDozen: Array<NameFieldBets> = [
  'field:15',
  'field:18',
  'field:21',
  'field:24',
  'field:14',
  'field:17',
  'field:20',
  'field:23',
  'field:13',
  'field:16',
  'field:19',
  'field:22',
]

const thirdDozen: Array<NameFieldBets> = [
  'field:27',
  'field:30',
  'field:33',
  'field:36',
  'field:26',
  'field:29',
  'field:32',
  'field:35',
  'field:25',
  'field:28',
  'field:31',
  'field:34',
]

const MainRouletteField = observer((): JSX.Element => {
  const isMounted = useIsMounted()
  const { rouletteStore } = useStore()
  const { uiStore } = rouletteStore
  const isVisibleMain = uiStore.mobileFieldView === 'main'
  const isVisibleTrack = uiStore.mobileFieldView === 'track'
  const containerRef = useRef<HTMLDivElement | null>(null)
  const containerInsideRef = useRef<HTMLDivElement | null>(null)
  const [scale, setScale] = useState<number>(1)

  const recalculateContainerSize = (): void => {
    if (!isMounted()) return

    const wrapperWidth = containerRef.current?.clientWidth || 0
    const wrapperHeight = containerRef.current?.clientHeight || 0
    const elWidth = containerInsideRef.current?.clientWidth || 0
    const elHeight = containerInsideRef.current?.clientHeight || 0
    const newScale = Math.min(wrapperWidth / elWidth, wrapperHeight / elHeight)

    if (isNumber(newScale) && newScale !== scale) setScale(newScale)
  }

  useEffect(() => {
    // неявная проблема с перерисовкой поля поэтому принято решение проверять так
    setInterval(() => {
      recalculateContainerSize()
    }, 1000)
  }, [])

  useEffect(() => {
    recalculateContainerSize()
  }, [uiStore.mobileFieldView])

  useMount(() => {
    recalculateContainerSize()
  })

  useEvent('resize', debounce(recalculateContainerSize, 300))
  useEvent('orientationchange', debounce(recalculateContainerSize, 100))

  return (
    <CommonContainer ref={containerRef}>
      <CommonContainerInside ref={containerInsideRef} scale={scale}>
        <WrapperFields visible={isVisibleMain}>
          <Box display="inline-grid">
            <MainGrid>
              <Left>
                <ZeroCell />
              </Left>

              <Middle>
                <DozensWrapper>
                  <DozenWrapper>
                    {firstDozen.map((item) => (
                      <CellMainField key={item} nameField={item} />
                    ))}
                  </DozenWrapper>
                  <DozenWrapper>
                    {secondDozen.map((item) => (
                      <CellMainField key={item} nameField={item} />
                    ))}
                  </DozenWrapper>
                  <DozenWrapper>
                    {thirdDozen.map((item) => (
                      <CellMainField key={item} nameField={item} />
                    ))}
                  </DozenWrapper>
                </DozensWrapper>
              </Middle>

              <Right>
                <CellMainField nameField="row:3:6:9:12:15:18:21:24:27:30:33:36" />
                <CellMainField nameField="row:2:5:8:11:14:17:20:23:26:29:32:35" />
                <CellMainField nameField="row:1:4:7:10:13:16:19:22:25:28:31:34" />
              </Right>
            </MainGrid>
            <SubGrid>
              <DozenCells>
                <CellMainField nameField="dozen:1:2:3:4:5:6:7:8:9:10:11:12" />
                <CellMainField nameField="dozen:13:14:15:16:17:18:19:20:21:22:23:24" />
                <CellMainField nameField="dozen:25:26:27:28:29:30:31:32:33:34:35:36" />
              </DozenCells>

              <CombinationCells>
                <CellMainField nameField="half:1:2:3:4:5:6:7:8:9:10:11:12:13:14:15:16:17:18" />
                <CellMainField nameField="parity:2:4:6:8:10:12:14:16:18:20:22:24:26:28:30:32:34:36" />
                <CellMainField nameField="color:1:3:5:7:9:12:14:16:18:19:21:23:25:27:30:32:34:36" />
                <CellMainField nameField="color:2:4:6:8:10:11:13:15:17:20:22:24:26:28:29:31:33:35" />
                <CellMainField nameField="parity:1:3:5:7:9:11:13:15:17:19:21:23:25:27:29:31:33:35" />
                <CellMainField nameField="half:19:20:21:22:23:24:25:26:27:28:29:30:31:32:33:34:35:36" />
              </CombinationCells>
            </SubGrid>
          </Box>
        </WrapperFields>
        <TrackRouletteField visible={isVisibleTrack} />
      </CommonContainerInside>
    </CommonContainer>
  )
})
export default MainRouletteField
