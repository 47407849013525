import { get, reduce, uniq } from 'lodash'
import { NameFieldBets } from '../nameFieldBets'

export type MiddleTrackNameFieldBets = '0:spiel' | 'serie:0:2:3' | 'orphelins' | 'serie:5:8'

export type TrackNameFieldBets = NameFieldBets | MiddleTrackNameFieldBets

type TrackBets = Partial<{
  [key in TrackNameFieldBets]: Array<NameFieldBets>
}>

// на эти поля ставка удваивается
export const excludeTrackBets = ['street:0:2:3', 'corner:25:26:28:29']

export const trackBets: TrackBets = {
  'field:0': ['field:15', 'field:32', 'field:0', 'field:26', 'field:3'],
  'field:1': ['field:14', 'field:20', 'field:1', 'field:33', 'field:16'],
  'field:2': ['field:4', 'field:21', 'field:2', 'field:25', 'field:17'],
  'field:3': ['field:0', 'field:26', 'field:3', 'field:35', 'field:12'],
  'field:4': ['field:15', 'field:19', 'field:4', 'field:21', 'field:2'],
  'field:5': ['field:16', 'field:24', 'field:5', 'field:10', 'field:23'],
  'field:6': ['field:17', 'field:34', 'field:6', 'field:27', 'field:13'],
  'field:7': ['field:12', 'field:28', 'field:7', 'field:29', 'field:18'],
  'field:8': ['field:11', 'field:30', 'field:8', 'field:23', 'field:10'],
  'field:9': ['field:18', 'field:22', 'field:9', 'field:31', 'field:14'],
  'field:10': ['field:24', 'field:5', 'field:10', 'field:23', 'field:8'],
  'field:11': ['field:13', 'field:36', 'field:11', 'field:30', 'field:8'],
  'field:12': ['field:3', 'field:35', 'field:12', 'field:28', 'field:7'],
  'field:13': ['field:6', 'field:27', 'field:13', 'field:36', 'field:11'],
  'field:14': ['field:9', 'field:31', 'field:14', 'field:20', 'field:1'],
  'field:15': ['field:0', 'field:32', 'field:15', 'field:19', 'field:4'],
  'field:16': ['field:1', 'field:33', 'field:16', 'field:24', 'field:5'],
  'field:17': ['field:2', 'field:25', 'field:17', 'field:34', 'field:6'],
  'field:18': ['field:7', 'field:29', 'field:18', 'field:22', 'field:9'],
  'field:19': ['field:32', 'field:15', 'field:19', 'field:4', 'field:21'],
  'field:20': ['field:31', 'field:14', 'field:20', 'field:1', 'field:33'],
  'field:21': ['field:19', 'field:4', 'field:21', 'field:2', 'field:25'],
  'field:22': ['field:29', 'field:18', 'field:22', 'field:9', 'field:31'],
  'field:23': ['field:30', 'field:8', 'field:23', 'field:10', 'field:5'],
  'field:24': ['field:33', 'field:16', 'field:24', 'field:5', 'field:10'],
  'field:25': ['field:21', 'field:2', 'field:25', 'field:17', 'field:34'],
  'field:26': ['field:32', 'field:0', 'field:26', 'field:3', 'field:35'],
  'field:27': ['field:34', 'field:6', 'field:27', 'field:13', 'field:36'],
  'field:28': ['field:35', 'field:12', 'field:28', 'field:7', 'field:29'],
  'field:29': ['field:28', 'field:7', 'field:29', 'field:18', 'field:22'],
  'field:30': ['field:36', 'field:11', 'field:30', 'field:8', 'field:23'],
  'field:31': ['field:22', 'field:9', 'field:31', 'field:14', 'field:20'],
  'field:32': ['field:26', 'field:0', 'field:32', 'field:15', 'field:19'],
  'field:33': ['field:20', 'field:1', 'field:33', 'field:16', 'field:24'],
  'field:34': ['field:25', 'field:17', 'field:34', 'field:6', 'field:27'],
  'field:35': ['field:26', 'field:3', 'field:35', 'field:12', 'field:28'],
  'field:36': ['field:27', 'field:13', 'field:36', 'field:11', 'field:30'],
  // track middle
  '0:spiel': ['split:0:3', 'split:12:15', 'split:32:35', 'field:26'],
  'serie:0:2:3': [
    'street:0:2:3',
    'split:4:7',
    'split:12:15',
    'split:18:21',
    'split:19:22',
    'split:32:35',
    'corner:25:26:28:29',
  ],
  orphelins: ['field:1', 'split:6:9', 'split:14:17', 'split:17:20', 'split:31:34'],
  'serie:5:8': ['split:5:8', 'split:10:11', 'split:13:16', 'split:23:24', 'split:27:30', 'split:33:36'],
}

export const sortedTrackBets: TrackBets = reduce(
  trackBets,
  (acc, item, key) => ({
    ...acc,
    [key]: item?.sort(),
  }),
  {}
)
export const getTrackBets = (nameFieldBet: TrackNameFieldBets): Array<NameFieldBets> => get(trackBets, nameFieldBet, [])
export const getTrackBetsOnlyFields = (nameFieldBet: TrackNameFieldBets): Array<NameFieldBets> => {
  const fields = get(trackBets, nameFieldBet, [])

  return uniq(
    reduce(
      fields,
      // @ts-ignore
      (acc, item) => {
        const zxc = item.split(':').slice(1)
        const pipi = zxc.map((num) => `field:${num}`)
        return [...acc, ...pipi]
      },
      []
    )
  )
}
