import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  AddBalance,
  ChipsPanel,
  CompleteBtn,
  DoublingBtn,
  ExitGameBtn,
  RemoveAllBetsBtn,
  RemoveLastBetBtn,
  RepeatBtn,
  VolumeBtn,
} from '../index'
import Box from '../Box'
import { useBreakpoint } from '../../core/theme'
import { BetsAction, Bottom, GamePlayAction, Left, StyledActionBar } from './styles'
import { useStore } from '../../stores'

const ActionBar = observer((): JSX.Element => {
  const breakpoint = useBreakpoint()
  const { rouletteStore } = useStore()
  const { statusGameStore } = rouletteStore
  const [isShowBetsAction, setIsShowBetsAction] = useState<boolean>(true)

  return (
    <StyledActionBar>
      <Left>
        <AddBalance />
      </Left>

      <GamePlayAction hidden={!statusGameStore.isBetting}>
        <div>
          <BetsAction visible={isShowBetsAction}>
            <Box mr="5px">
              <RemoveAllBetsBtn />
            </Box>
            <Box mr="5px">
              <RemoveLastBetBtn />
            </Box>
            <Box mr="5px">
              <CompleteBtn />
            </Box>
          </BetsAction>

          <ChipsPanel
            onShowChips={(isShow) => {
              setIsShowBetsAction(!isShow)
            }}
          />

          <BetsAction visible={isShowBetsAction}>
            <Box mr="5px">
              <RepeatBtn />
            </Box>
            <Box>
              <DoublingBtn />
            </Box>
          </BetsAction>
        </div>
      </GamePlayAction>

      {breakpoint === 'small' && (
        <Bottom>
          <Box ml="5px" mr="5px">
            <VolumeBtn />
          </Box>
          <ExitGameBtn />
        </Bottom>
      )}
    </StyledActionBar>
  )
})

export default ActionBar
