export enum TypeBets {
  FIELD = 'field',
  SPLIT = 'split',
  STREET = 'street',
  CORNER = 'corner',
  SIXLINE = 'sixline',
  ROW = 'row',
  DOZEN = 'dozen',
  HALF = 'half',
  PARITY = 'parity',
  COLOR = 'color',
}
