import { observer } from 'mobx-react-lite'
import { Range, getTrackBackground } from 'react-range'
import { useState } from 'react'
import { Img, Line, RangeContainer, Thumb, VolumePercentage } from './styles'
import { useStore } from '../../stores'
import volumeMuteIcon from '../VolumeBtn/img/volume-mute.svg'
import volumeIcon from '../VolumeBtn/img/volume.svg'

const STEP = 0.01
const MIN = 0
const MAX = 1

const VolumeRange = observer((): JSX.Element => {
  const { rouletteStore } = useStore()
  const { volumeStore } = rouletteStore
  const [localVolume, setLocalVolume] = useState([volumeStore.volume])
  const volumePercentage = (localVolume[0] * 100).toFixed(0)

  return (
    <div>
      <VolumePercentage>{volumePercentage} %</VolumePercentage>

      <RangeContainer>
        <Img src={volumeMuteIcon} alt="mute" />

        <Range
          step={STEP}
          min={MIN}
          max={MAX}
          values={localVolume}
          onChange={(values) => {
            setLocalVolume(values)
            volumeStore.setVolume(values[0])
          }}
          renderTrack={({ props, children }) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '45px',
                display: 'flex',
                width: '100%',
              }}>
              <div
                ref={props.ref}
                style={{
                  height: '4px',
                  width: '100%',
                  borderRadius: '4px',
                  border: '1px solid #162C57',
                  background: getTrackBackground({
                    values: localVolume,
                    colors: ['#FF9A3D', '#040E23'],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: 'center',
                }}>
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <Thumb
              {...props}
              style={{
                ...props.style,
              }}>
              <Line />
              <Line />
            </Thumb>
          )}
        />

        <Img src={volumeIcon} alt="unmute" />
      </RangeContainer>
    </div>
  )
})

export default VolumeRange
