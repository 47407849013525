import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import plusIcon from './img/plus.svg'
import { Box, CurrencyDiamond } from '../index'
import { useStore } from '../../stores'
import { numberWithSpaces } from '../../utils/number'
import { BalanceNumber, BalanceTxt, Btn, Container, Paid, TextContainer, TxtContainers } from './styles'

const AddBalance = observer((): JSX.Element => {
  const { t } = useTranslation()
  const { rouletteStore } = useStore()
  const { uiStore, payStore, statusGameStore, depositStore, betsStore } = rouletteStore

  return (
    <Container
      onClick={(e) => {
        e.preventDefault()
        uiStore.setShowBalancePopup(true)
      }}>
      <Btn>
        <img src={plusIcon} alt="add balance" />
      </Btn>

      <TxtContainers>
        <TextContainer>
          <BalanceTxt>{t('balance')}:</BalanceTxt>
          <Box display="flex" alignItems="center">
            <BalanceNumber>{numberWithSpaces(depositStore.deposit || 0)}</BalanceNumber>
            <CurrencyDiamond />
          </Box>

          {statusGameStore.isShowPay && payStore.pay > 0 && (
            <Paid>
              + {t('pay')}: {numberWithSpaces(payStore.pay)} <CurrencyDiamond />
            </Paid>
          )}
        </TextContainer>

        <TextContainer>
          <BalanceTxt>{t('totalBets')}:</BalanceTxt>
          <Box display="flex" alignItems="center">
            <BalanceNumber>{numberWithSpaces(betsStore.totalBets)}</BalanceNumber>
            <CurrencyDiamond />
          </Box>
        </TextContainer>
      </TxtContainers>
    </Container>
  )
})

export default AddBalance
