import { makeAutoObservable } from 'mobx'

export enum RUL_GAME_STATUS {
  PREPARING = 'preparing',
  START = 'start',
  SPIN = 'spin',
  BAll = 'ball',
  NO_SPIN = 'noSpin',
  RESULT = 'result',
  END = 'end',
  PAUSE = 'pause',
  END_BET = 'endBet',
}

class StatusGameStore {
  statusGame: RUL_GAME_STATUS = RUL_GAME_STATUS.START

  constructor() {
    makeAutoObservable(this)
  }

  get isShowWinShare(): boolean {
    // статусы когда отображать долю
    return [RUL_GAME_STATUS.BAll, RUL_GAME_STATUS.RESULT, RUL_GAME_STATUS.END].includes(this.statusGame)
  }

  get isBetting(): boolean {
    // когда можно ставить ставки
    return this.statusGame === RUL_GAME_STATUS.PREPARING
  }

  get isSpinStatus(): boolean {
    return this.statusGame === RUL_GAME_STATUS.SPIN
  }

  get isResultStatus(): boolean {
    return this.statusGame === RUL_GAME_STATUS.RESULT
  }

  get isNewNumberStatus(): boolean {
    return this.statusGame === RUL_GAME_STATUS.BAll
  }

  setStatusGame = (data: RUL_GAME_STATUS): void => {
    this.statusGame = data
  }

  get isShowPay(): boolean {
    return [RUL_GAME_STATUS.RESULT, RUL_GAME_STATUS.END].includes(this.statusGame)
  }
}

export default StatusGameStore
