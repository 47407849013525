import { TypeBets } from './typeBets'

export const scaleLimits = {
  [TypeBets.FIELD]: 1,
  [TypeBets.SPLIT]: 2,
  [TypeBets.STREET]: 3,
  [TypeBets.CORNER]: 4,
  [TypeBets.SIXLINE]: 6,
  // default
  [TypeBets.ROW]: 1,
  [TypeBets.DOZEN]: 1,
  [TypeBets.HALF]: 1,
  [TypeBets.PARITY]: 1,
  [TypeBets.COLOR]: 1,
}
