import { ChangeEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import routes from '../../core/routes'
import { AuthForm, Box, Button, RouteLink, TextField } from '../index'
import { useStore } from '../../stores'
import AuthErrorText from '../AuthErrorText'

const RegistrationSmsForm = observer((): JSX.Element => {
  const { t } = useTranslation()
  const { registrationStore } = useStore()
  const [isShowError, setIsShowError] = useState<boolean>(false)
  const [smsValue, setSmsValue] = useState<string>('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSmsValue(e.target.value)
  }

  return (
    <Box>
      {isShowError && <AuthErrorText>{t('smsNotValid')}</AuthErrorText>}
      <AuthForm
        onSubmit={async (e) => {
          e.preventDefault()
          setIsShowError(false)
          try {
            await registrationStore.getRegistrationCode({
              registration_code: smsValue,
            })
          } catch (error) {
            setIsShowError(true)
            console.error(error)
          }
        }}>
        <Box mb={40}>
          <TextField
            value={smsValue}
            onChange={handleChange}
            label={t('enterCodeFromSms')}
            id="registrationSms"
          />
        </Box>
        <Box mb={40}>
          <Button fullWidth type="submit" label={t('continue')} />
        </Box>
        <Box mb={20} display="flex" alignItems="center" justifyContent="center">
          <RouteLink to={routes.login}>{t('back')}</RouteLink>
        </Box>
      </AuthForm>
    </Box>
  )
})

export default RegistrationSmsForm
