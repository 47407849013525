import { useTranslation } from 'react-i18next'
import { Box, RouteLink } from '../index'
import routes from '../../core/routes'

const RegistrationSuccess = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box textAlign="center" fontSize={14} color="var1" mb={20}>
        {t('registrationSuccessMessage')}
      </Box>
      <Box display="flex" justifyContent="center">
        <RouteLink to={routes.login}>{t('toComeIn')}</RouteLink>
      </Box>
    </Box>
  )
}

export default RegistrationSuccess
