import { Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

const Common = observer(() => {
  const { applicationStore } = useStore()

  useEffect(() => {
    try {
      applicationStore.fpJsClient?.init()
    } catch (e) {
      console.error(e)
    }
  }, [])

  return <Outlet />
})

export default Common
