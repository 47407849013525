import { numberWithDot } from './number'
import { ChipsRange } from '../services/chipsets'

export const roundedChipNumber = (num: number): string => {
  if (num < 1000) return String(num)

  return numberWithDot(num).substring(0, 4)
}

export const lastIndexChip = (
  data: ChipsRange,
  chipValue: number
): {
  lastIndex: number
  alt: string
} => {
  const minSets = data.filter((item) => item <= chipValue)
  const lastIndex = minSets.length - 1
  const alt = String(data[lastIndex])

  return {
    lastIndex,
    alt,
  }
}
