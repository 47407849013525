import { FingerprintJSPro, FpjsClientOptions } from '@fingerprintjs/fingerprintjs-pro-spa'

type Region =
  /** N. Virginia, USA */
  | 'us'
  /** Frankfurt, Germany */
  | 'eu'
  /** Mumbai, India */
  | 'ap'

const apiKey = process.env.REACT_APP_FINGERPRINT_KEY || ''
const endpoint = process.env.REACT_APP_FINGERPRINT_ENDPOINT || ''
const scriptUrlPattern = process.env.REACT_APP_FINGERPRINT_SCRIPT_URL_PATTERN || ''
const region: Region = process.env.REACT_APP_FINGERPRINT_REGION as unknown as Region

const fingerPrintLoadOptions: FpjsClientOptions['loadOptions'] = {
  apiKey,
  endpoint: [endpoint, FingerprintJSPro.defaultEndpoint],
  scriptUrlPattern: [scriptUrlPattern, FingerprintJSPro.defaultScriptUrlPattern],
  region,
}

export const isHasFingerPrint = (): boolean => !!apiKey

export default fingerPrintLoadOptions
