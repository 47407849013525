import { ButtonHTMLAttributes } from 'react'
import { StyledButton } from './styles'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string
  onClick?: () => void
  type?: 'button' | 'reset' | 'submit'
  fullWidth?: boolean
  disabled?: boolean
}

const Button = (props: ButtonProps): JSX.Element => {
  const { label, onClick, type = 'button', fullWidth, ...otherProps } = props
  return (
    <StyledButton type={type} onClick={onClick} fullWidth={fullWidth} {...otherProps}>
      {label}
    </StyledButton>
  )
}

export default Button
