import { observer } from 'mobx-react-lite'
import { isNull } from 'lodash'
import { nanoid } from 'nanoid'
import { useStore } from '../../stores'
import { Ball, Container, WaitBall } from './styles'
import { getColorField } from '../../constants/colorsFieldRoulette'

const LastBalls = observer((): JSX.Element => {
  const { rouletteStore } = useStore()
  const { lastBallsStore } = rouletteStore
  const lastBalls = lastBallsStore.lastBalls
    .filter((item) => !isNull(item))
    .map((item) => {
      const isNoSpin = item === 'no spin'
      return {
        value: isNoSpin ? '_' : item,
        color: isNoSpin ? 'black' : getColorField(item as number),
        id: nanoid(3),
      }
    }) as Array<{
    value: '_' | number
    color: 'black' | 'red' | 'green'
    id: string
  }>

  return (
    <Container>
      <WaitBall>...</WaitBall>
      {lastBalls.map((item) => (
        <Ball key={item.id} color={item.color}>
          {item.value}
        </Ball>
      ))}
    </Container>
  )
})

export default LastBalls
