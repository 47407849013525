import { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { isNull } from 'lodash'
import { useStore } from '../../stores'
import { Video } from '../index'
import { VideoContainer } from './styles'

export type LocationVideoType = 'top' | 'bottom'

const WebrtcStream = observer(() => {
  const { rouletteStore } = useStore()
  const { streamDataStore } = rouletteStore
  const containerMainRef = useRef<HTMLDivElement>(null)
  const [locationVideo, setLocationVideo] = useState<LocationVideoType>('top')

  return (
    <VideoContainer ref={containerMainRef} locationVideo={locationVideo}>
      {!isNull(streamDataStore.streamData) && (
        <Video
          data={streamDataStore.streamData}
          offsetHeight={(videoHeight) => {
            if (!containerMainRef.current) return

            const containerHeight = containerMainRef.current.offsetHeight

            setLocationVideo(videoHeight > containerHeight ? 'bottom' : 'top')
          }}
        />
      )}
    </VideoContainer>
  )
})

export default WebrtcStream
