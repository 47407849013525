import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mediaQueries } from '../../core/theme'

const left = css`
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
`
const leftShift = css`
  top: 50%;
  left: -2px;
  transform: translate(-50%, -50%);
`

const bottomLeft = css`
  top: 100%;
  left: 0;
  transform: translate(-50%, -50%);
`

const bottomLeftShift = css`
  top: 100%;
  left: -2px;
  transform: translate(-50%, -50%);
`

const bottom = css`
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledArea = styled.div<{
  position: 'left' | 'bottomLeft' | 'bottom'
  shift?: boolean
  disabled: boolean
}>`
  width: 22px;
  height: 22px;
  position: absolute;
  z-index: 2;
  cursor: pointer;

  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  ${(props) => {
    const { position, shift } = props

    switch (position) {
      case 'left':
        return shift ? leftShift : left
      case 'bottom':
        return bottom
      case 'bottomLeft':
        return shift ? bottomLeftShift : bottomLeft
      default:
        return left
    }
  }}

  ${mediaQueries.large} {
    width: 30px;
    height: 30px;
  }

  ${mediaQueries.big} {
    width: 35px;
    height: 35px;
  }
`
