import { makeAutoObservable } from 'mobx'
import { Socket } from 'socket.io-client'
import UIStore from './UI.store'
import VolumeStore from './Volume.store'
import BetsStore, { Bets, TYPE_BET_ACTION } from './Bets.store'
import StatusGameStore from './StatusGame.store'
import ChipsStore from './Chips.store'
import DepositStore from './Deposit.store'
import LimitsStore from './Limits.store'
import LastBallsStore from './LastBalls.store'
import StreamDataStore from './StreamData.store'
import TimerStore from './Timer.store'
import PayStore from './Pay.store'
import LastVerifyDataStore from './LastVerifyData.store'
import RouletteEvents from '../../services/RouletteEvents'
import RouletteGameBets from '../../services/RouletteGameBets'
import { rulSocketEvents } from '../../constants/rulSocketEvents'
import NotificationStore from './Notification.store'
import UserStore from './User.store'
import StandStore from './Stand.store'
import SessionIdStore from './SessionId.store'
import SubscribeGamesStore from './SubscribeGames.store'

export class RouletteStore {
  gameId: string = ''

  socket: Socket | null = null

  uiStore: UIStore

  volumeStore: VolumeStore

  userStore: UserStore

  standStore: StandStore

  sessionIdStore: SessionIdStore

  betsStore: BetsStore

  statusGameStore: StatusGameStore

  chipsStore: ChipsStore

  depositStore: DepositStore

  limitsStore: LimitsStore

  lastBallsStore: LastBallsStore

  streamDataStore: StreamDataStore

  timerStore: TimerStore

  payStore: PayStore

  lastVerifyDataStore: LastVerifyDataStore

  notificationStore: NotificationStore

  subscribeGamesStore: SubscribeGamesStore

  events: RouletteEvents

  betsService: RouletteGameBets

  constructor() {
    makeAutoObservable(this)

    this.uiStore = new UIStore()

    this.volumeStore = new VolumeStore()

    this.userStore = new UserStore()

    this.standStore = new StandStore()

    this.sessionIdStore = new SessionIdStore()

    this.betsStore = new BetsStore()

    this.statusGameStore = new StatusGameStore()

    this.chipsStore = new ChipsStore()

    this.depositStore = new DepositStore()

    this.limitsStore = new LimitsStore()

    this.lastBallsStore = new LastBallsStore()

    this.streamDataStore = new StreamDataStore()

    this.timerStore = new TimerStore()

    this.payStore = new PayStore()

    this.lastVerifyDataStore = new LastVerifyDataStore()

    this.notificationStore = new NotificationStore()

    this.subscribeGamesStore = new SubscribeGamesStore()

    this.events = new RouletteEvents(this)

    this.betsService = new RouletteGameBets(this)
  }

  setGameId = (gameId: string): void => {
    this.gameId = gameId
  }

  setSocket = (socket: Socket): void => {
    this.socket = socket
  }

  initGame = (data: { gameId: string; socket: Socket }): void => {
    this.setGameId(data.gameId)
    this.setSocket(data.socket)
  }

  sendDeposit = (data: { deposit: number }): void => {
    this.socket?.emit(rulSocketEvents.DEPOSIT, data)
  }

  sendSwitchGame = (gameId: string): void => {
    this.socket?.emit(rulSocketEvents.SWITCH_GAME, { gameId })
  }

  sendBets = (data: {
    type: TYPE_BET_ACTION
    id?: number
    validHistory?: {
      id: number
      bets?: Bets
    }
  }): void => {
    this.socket?.emit(rulSocketEvents.ROULETTE_BET, data)
  }

  switchGame = (): void => {
    this.uiStore = new UIStore()

    this.standStore = new StandStore()

    this.sessionIdStore = new SessionIdStore()

    this.betsStore = new BetsStore()

    this.statusGameStore = new StatusGameStore()

    this.limitsStore = new LimitsStore()

    this.lastBallsStore = new LastBallsStore()

    this.streamDataStore = new StreamDataStore()

    this.timerStore = new TimerStore()

    this.payStore = new PayStore()

    this.lastVerifyDataStore = new LastVerifyDataStore()

    this.notificationStore = new NotificationStore()

    this.subscribeGamesStore = new SubscribeGamesStore()

    this.events = new RouletteEvents(this)

    this.betsService = new RouletteGameBets(this)
  }

  resetGame = (): void => {
    this.uiStore = new UIStore()

    this.volumeStore = new VolumeStore()

    this.userStore = new UserStore()

    this.standStore = new StandStore()

    this.sessionIdStore = new SessionIdStore()

    this.betsStore = new BetsStore()

    this.statusGameStore = new StatusGameStore()

    this.chipsStore = new ChipsStore()

    this.depositStore = new DepositStore()

    this.limitsStore = new LimitsStore()

    this.lastBallsStore = new LastBallsStore()

    this.streamDataStore = new StreamDataStore()

    this.timerStore = new TimerStore()

    this.payStore = new PayStore()

    this.lastVerifyDataStore = new LastVerifyDataStore()

    this.notificationStore = new NotificationStore()

    this.subscribeGamesStore = new SubscribeGamesStore()

    this.events = new RouletteEvents(this)

    this.betsService = new RouletteGameBets(this)
  }
}
