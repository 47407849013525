import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { chipUrlWithoutNum } from '../../services/chipsets'
import { Container, StyledImg, Value } from './styles'
import { lastIndexChip, roundedChipNumber } from '../../utils/chips'

export type ChipCellProps = {
  chipValue: number
  transparent?: boolean
}

const ChipCell = observer((props: ChipCellProps) => {
  const { chipValue, transparent } = props
  const { chipsetsStore } = useStore()

  if (!chipValue) return null

  const value = roundedChipNumber(chipValue)
  const chip = lastIndexChip(chipsetsStore.chipsets, chipValue)

  return (
    <Container>
      <StyledImg
        src={chipUrlWithoutNum[chip.lastIndex]}
        alt={chip.alt}
        width={23}
        height={23}
        transparent={transparent}
      />
      <Value>{value}</Value>
    </Container>
  )
})

export default ChipCell
