import { makeAutoObservable } from 'mobx'
import { toast, ToastPosition } from 'react-toastify'

export class NotificationStore {
  position: ToastPosition = 'top-right'

  constructor() {
    makeAutoObservable(this)
  }

  showWarning = (text: string): void => {
    toast.warning(text)
  }

  showError = (text: string): void => {
    toast.error(text)
  }

  showInfo = (text: string): void => {
    toast.info(text)
  }

  dismiss = (): void => {
    toast.dismiss()
  }
}
