import { action, autorun, makeObservable, observable } from 'mobx'
import { isEmpty } from 'lodash'
import { FpjsClient } from '@fingerprintjs/fingerprintjs-pro-spa'
import { ECurrency } from '@lbs/currency'
import { LoginStore } from './Login.store'
import { ListGamesStore } from './ListGames.store'
import { BalanceEventResponse, SOCKET_COVER_EVENTS, SocketCover } from './SocketCover.store'
import { WebrtcStreamStore } from './WebrtcStream.store'
import fingerPrintLoadOptions from '../constants/fingerPrint'

export class ApplicationStore {
  private socket: SocketCover | null = null

  loginStore: LoginStore

  listGamesStore: ListGamesStore

  webrtcStreamStore: WebrtcStreamStore

  version: string = ''

  balance: BalanceEventResponse['balance'] = 0

  availableBalance: BalanceEventResponse['availableBalance'] = 0

  currency: ECurrency = ECurrency.RUB

  rouletteRatio: number = 0

  isOffline: boolean = false

  fpJsClient: FpjsClient | null = null

  constructor(loginStore: LoginStore, listGamesStore: ListGamesStore, webrtcStreamStore: WebrtcStreamStore) {
    this.loginStore = loginStore

    this.listGamesStore = listGamesStore

    this.webrtcStreamStore = webrtcStreamStore

    this.fpJsClient = fingerPrintLoadOptions.apiKey
      ? new FpjsClient({
          loadOptions: {
            ...fingerPrintLoadOptions,
          },
        })
      : null

    makeObservable(this, {
      loginStore: observable,
      listGamesStore: observable,
      webrtcStreamStore: observable,
      balance: observable,
      availableBalance: observable,
      currency: observable,
      rouletteRatio: observable,
      version: observable,
      isOffline: observable,
      setBalance: action,
      reset: action,
    })

    autorun(() => {
      if (this.loginStore.accessToken) {
        this.initSocketCover()
      } else {
        this.destroySocketCover()
      }
    })
  }

  get busyBalance(): number {
    return this.balance - this.availableBalance
  }

  initSocketCover = (): void => {
    if (!isEmpty(this.socket)) this.destroySocketCover()

    if (this.socket?.accessToken === this.loginStore.accessToken) return

    this.socket = new SocketCover(this.loginStore.accessToken)

    this.socket.io?.on(SOCKET_COVER_EVENTS.BALANCE, (data: BalanceEventResponse) => {
      this.setBalance(data)
    })

    this.socket.io?.on(SOCKET_COVER_EVENTS.DISC_AND_LOGOUT, async () => {
      await this.loginStore.logout()
    })

    this.socket.io?.on(SOCKET_COVER_EVENTS.ANOTHER_AUTH, async () => {
      await this.loginStore.logout()
    })

    this.socket.io?.on(SOCKET_COVER_EVENTS.CHANGE_STATUS_GAME, async () => {
      await this.listGamesStore.fetchGameScheduleRoulette()
    })
  }

  destroySocketCover = (): void => {
    this.socket?.destroy()

    this.socket = null
  }

  reset = (): void => {
    this.balance = 0

    this.destroySocketCover()
  }

  setBalance = (data: BalanceEventResponse): void => {
    Object.assign(this, data)
  }

  setIsOffline = (data: boolean): void => {
    this.isOffline = data
  }
}
