import { makeAutoObservable } from 'mobx'
import storage, { STORAGE_KEYS } from '../services/storage'

export class FavoriteSlotListGamesStore {
  games: string[] = []

  constructor() {
    makeAutoObservable(this)

    this.games = (storage.get(STORAGE_KEYS.FAVORITE_SLOTS_GAMES) as string[]) || []
  }

  toggleSlotGameId = (slotGameId: string): void => {
    const hasSlotGameId = this.games.includes(slotGameId)

    let newFavoriteList: string[]

    if (hasSlotGameId) {
      newFavoriteList = [...this.games].filter((item) => item !== slotGameId)
    } else {
      newFavoriteList = [...this.games, slotGameId]
    }

    storage.set(STORAGE_KEYS.FAVORITE_SLOTS_GAMES, newFavoriteList)
    this.games = newFavoriteList
  }

  isFavoriteSlotGame = (slotGameId: string): boolean => {
    return this.games.includes(slotGameId)
  }
}
