import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledInput = styled.input`
  border-radius: ${(props) => props.theme.borderRadius}px;
  outline: none;
  padding: 10px;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  width: 100%;

  &,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background: #040e23;
    border: 1px solid #162c57;
  }
`

export const StyledLabel = styled.label`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #98abd0;
  margin-bottom: 10px;
`
