import styled from '@emotion/styled'
import { mediaQueries } from '../../core/theme'

export const StyledActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  ${mediaQueries.medium} {
    display: inline-grid;
    grid-template-columns: 265px 1fr;
    align-items: center;
  }

  ${mediaQueries.large} {
    grid-template-columns: 360px 1fr;
  }

  ${mediaQueries.big} {
    grid-template-columns: 545px 1fr;
  }
`

export const GamePlayAction = styled.div<{
  hidden: boolean
}>`
  width: 100%;
  display: ${(props) => (props.hidden ? 'none' : 'inline-grid')};
  align-content: flex-end;
  justify-content: center;
  grid-template-columns: auto auto auto;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${mediaQueries.medium} {
    display: inline-grid;
  }
`

export const BetsAction = styled.div<{
  visible: boolean
}>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};

  ${mediaQueries.medium} {
    display: flex;
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
