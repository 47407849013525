import { get } from 'lodash'
import { NameFieldBets } from '../nameFieldBets'

export const completeBets = {
  'field:0': ['field:0', 'split:0:3', 'split:0:2', 'split:0:1', 'street:0:2:3', 'street:0:1:2', 'corner:0:1:2:3'],
  'field:3': [
    'field:3',
    'split:0:3',
    'split:3:6',
    'split:2:3',
    'street:0:2:3',
    'street:1:2:3',
    'corner:0:1:2:3',
    'corner:2:3:5:6',
    'sixline:1:2:3:4:5:6',
  ],
  'field:6': [
    'field:6',
    'split:3:6',
    'split:6:9',
    'split:5:6',
    'street:4:5:6',
    'corner:2:3:5:6',
    'corner:5:6:8:9',
    'sixline:1:2:3:4:5:6',
    'sixline:4:5:6:7:8:9',
  ],
  'field:9': [
    'field:9',
    'split:6:9',
    'split:9:12',
    'split:8:9',
    'street:7:8:9',
    'corner:5:6:8:9',
    'corner:8:9:11:12',
    'sixline:4:5:6:7:8:9',
    'sixline:7:8:9:10:11:12',
  ],
  'field:12': [
    'field:12',
    'split:9:12',
    'split:12:15',
    'split:11:12',
    'street:10:11:12',
    'corner:8:9:11:12',
    'corner:11:12:14:15',
    'sixline:7:8:9:10:11:12',
    'sixline:10:11:12:13:14:15',
  ],
  'field:15': [
    'field:15',
    'split:12:15',
    'split:15:18',
    'split:14:15',
    'street:13:14:15',
    'corner:11:12:14:15',
    'corner:14:15:17:18',
    'sixline:10:11:12:13:14:15',
    'sixline:13:14:15:16:17:18',
  ],
  'field:18': [
    'field:18',
    'split:15:18',
    'split:18:21',
    'split:17:18',
    'street:16:17:18',
    'corner:14:15:17:18',
    'corner:17:18:20:21',
    'sixline:13:14:15:16:17:18',
    'sixline:16:17:18:19:20:21',
  ],
  'field:21': [
    'field:21',
    'split:18:21',
    'split:21:24',
    'split:20:21',
    'street:19:20:21',
    'corner:17:18:20:21',
    'corner:20:21:23:24',
    'sixline:16:17:18:19:20:21',
    'sixline:19:20:21:22:23:24',
  ],
  'field:24': [
    'field:24',
    'split:21:24',
    'split:24:27',
    'split:23:24',
    'street:22:23:24',
    'corner:20:21:23:24',
    'corner:23:24:26:27',
    'sixline:19:20:21:22:23:24',
    'sixline:22:23:24:25:26:27',
  ],
  'field:27': [
    'field:27',
    'split:24:27',
    'split:27:30',
    'split:26:27',
    'street:25:26:27',
    'corner:23:24:26:27',
    'corner:26:27:29:30',
    'sixline:22:23:24:25:26:27',
    'sixline:25:26:27:28:29:30',
  ],
  'field:30': [
    'field:30',
    'split:27:30',
    'split:30:33',
    'split:29:30',
    'street:28:29:30',
    'corner:26:27:29:30',
    'corner:29:30:32:33',
    'sixline:25:26:27:28:29:30',
    'sixline:28:29:30:31:32:33',
  ],
  'field:33': [
    'field:33',
    'split:30:33',
    'split:33:36',
    'split:32:33',
    'street:31:32:33',
    'corner:29:30:32:33',
    'corner:32:33:35:36',
    'sixline:28:29:30:31:32:33',
    'sixline:31:32:33:34:35:36',
  ],
  'field:36': [
    'field:36',
    'split:33:36',
    'split:35:36',
    'street:34:35:36',
    'corner:32:33:35:36',
    'sixline:31:32:33:34:35:36',
  ],
  'row:3:6:9:12:15:18:21:24:27:30:33:36': [
    'field:3',
    'split:0:3',
    'split:3:6',
    'split:2:3',
    'corner:2:3:5:6',
    'field:6',
    'split:6:9',
    'split:5:6',
    'corner:5:6:8:9',
    'field:9',
    'split:9:12',
    'split:8:9',
    'corner:8:9:11:12',
    'field:12',
    'split:12:15',
    'split:11:12',
    'corner:11:12:14:15',
    'field:15',
    'split:15:18',
    'split:14:15',
    'corner:14:15:17:18',
    'field:18',
    'split:18:21',
    'split:17:18',
    'corner:17:18:20:21',
    'field:21',
    'split:21:24',
    'split:20:21',
    'corner:20:21:23:24',
    'field:24',
    'split:24:27',
    'split:23:24',
    'corner:23:24:26:27',
    'field:27',
    'split:27:30',
    'split:26:27',
    'corner:26:27:29:30',
    'field:30',
    'split:30:33',
    'split:29:30',
    'corner:29:30:32:33',
    'field:33',
    'split:33:36',
    'split:32:33',
    'corner:32:33:35:36',
    'field:36',
    'split:35:36',
    'street:0:2:3',
  ],
  'field:2': [
    'field:2',
    'split:0:2',
    'split:2:5',
    'split:2:3',
    'split:1:2',
    'street:0:2:3',
    'street:0:1:2',
    'street:1:2:3',
    'corner:0:1:2:3',
    'corner:2:3:5:6',
    'corner:1:2:4:5',
    'sixline:1:2:3:4:5:6',
  ],
  'field:5': [
    'field:5',
    'split:2:5',
    'split:5:8',
    'split:5:6',
    'split:4:5',
    'street:4:5:6',
    'corner:2:3:5:6',
    'corner:5:6:8:9',
    'corner:1:2:4:5',
    'corner:4:5:7:8',
    'sixline:1:2:3:4:5:6',
    'sixline:4:5:6:7:8:9',
  ],
  'field:8': [
    'field:8',
    'split:5:8',
    'split:8:11',
    'split:8:9',
    'split:7:8',
    'street:7:8:9',
    'corner:5:6:8:9',
    'corner:8:9:11:12',
    'corner:4:5:7:8',
    'corner:7:8:10:11',
    'sixline:4:5:6:7:8:9',
    'sixline:7:8:9:10:11:12',
  ],
  'field:11': [
    'field:11',
    'split:8:11',
    'split:11:14',
    'split:11:12',
    'split:10:11',
    'street:10:11:12',
    'corner:8:9:11:12',
    'corner:11:12:14:15',
    'corner:7:8:10:11',
    'corner:10:11:13:14',
    'sixline:7:8:9:10:11:12',
    'sixline:10:11:12:13:14:15',
  ],
  'field:14': [
    'field:14',
    'split:11:14',
    'split:14:17',
    'split:14:15',
    'split:13:14',
    'street:13:14:15',
    'corner:11:12:14:15',
    'corner:14:15:17:18',
    'corner:10:11:13:14',
    'corner:13:14:16:17',
    'sixline:10:11:12:13:14:15',
    'sixline:13:14:15:16:17:18',
  ],
  'field:17': [
    'field:17',
    'split:14:17',
    'split:17:20',
    'split:17:18',
    'split:16:17',
    'street:16:17:18',
    'corner:14:15:17:18',
    'corner:17:18:20:21',
    'corner:13:14:16:17',
    'corner:16:17:19:20',
    'sixline:13:14:15:16:17:18',
    'sixline:16:17:18:19:20:21',
  ],
  'field:20': [
    'field:20',
    'split:17:20',
    'split:20:23',
    'split:20:21',
    'split:19:20',
    'street:19:20:21',
    'corner:17:18:20:21',
    'corner:20:21:23:24',
    'corner:16:17:19:20',
    'corner:19:20:22:23',
    'sixline:16:17:18:19:20:21',
    'sixline:19:20:21:22:23:24',
  ],
  'field:23': [
    'field:23',
    'split:20:23',
    'split:23:26',
    'split:23:24',
    'split:22:23',
    'street:22:23:24',
    'corner:20:21:23:24',
    'corner:23:24:26:27',
    'corner:19:20:22:23',
    'corner:22:23:25:26',
    'sixline:19:20:21:22:23:24',
    'sixline:22:23:24:25:26:27',
  ],
  'field:26': [
    'field:26',
    'split:23:26',
    'split:26:29',
    'split:26:27',
    'split:25:26',
    'street:25:26:27',
    'corner:23:24:26:27',
    'corner:26:27:29:30',
    'corner:22:23:25:26',
    'corner:25:26:28:29',
    'sixline:22:23:24:25:26:27',
    'sixline:25:26:27:28:29:30',
  ],
  'field:29': [
    'field:29',
    'split:26:29',
    'split:29:32',
    'split:29:30',
    'split:28:29',
    'street:28:29:30',
    'corner:26:27:29:30',
    'corner:29:30:32:33',
    'corner:25:26:28:29',
    'corner:28:29:31:32',
    'sixline:25:26:27:28:29:30',
    'sixline:28:29:30:31:32:33',
  ],
  'field:32': [
    'field:32',
    'split:29:32',
    'split:32:35',
    'split:32:33',
    'split:31:32',
    'street:31:32:33',
    'corner:29:30:32:33',
    'corner:32:33:35:36',
    'corner:28:29:31:32',
    'corner:31:32:34:35',
    'sixline:28:29:30:31:32:33',
    'sixline:31:32:33:34:35:36',
  ],
  'field:35': [
    'field:35',
    'split:32:35',
    'split:35:36',
    'split:34:35',
    'street:34:35:36',
    'corner:32:33:35:36',
    'corner:31:32:34:35',
    'sixline:31:32:33:34:35:36',
  ],
  'street:0:2:3': [
    'field:0',
    'split:0:3',
    'split:0:2',
    'split:0:1',
    'street:0:2:3',
    'street:0:1:2',
    'corner:0:1:2:3',
    'field:2',
    'split:2:5',
    'split:2:3',
    'split:1:2',
    'street:1:2:3',
    'corner:2:3:5:6',
    'corner:1:2:4:5',
    'sixline:1:2:3:4:5:6',
    'field:3',
    'split:3:6',
  ],
  'row:2:5:8:11:14:17:20:23:26:29:32:35': [
    'field:2',
    'split:0:2',
    'split:2:5',
    'split:2:3',
    'split:1:2',
    'corner:2:3:5:6',
    'corner:1:2:4:5',
    'field:5',
    'split:5:8',
    'split:5:6',
    'split:4:5',
    'corner:5:6:8:9',
    'corner:4:5:7:8',
    'field:8',
    'split:8:11',
    'split:8:9',
    'split:7:8',
    'corner:8:9:11:12',
    'corner:7:8:10:11',
    'field:11',
    'split:11:14',
    'split:11:12',
    'split:10:11',
    'corner:11:12:14:15',
    'corner:10:11:13:14',
    'field:14',
    'split:14:17',
    'split:14:15',
    'split:13:14',
    'corner:14:15:17:18',
    'corner:13:14:16:17',
    'field:17',
    'split:17:20',
    'split:17:18',
    'split:16:17',
    'corner:17:18:20:21',
    'corner:16:17:19:20',
    'field:20',
    'split:20:23',
    'split:20:21',
    'split:19:20',
    'corner:20:21:23:24',
    'corner:19:20:22:23',
    'field:23',
    'split:23:26',
    'split:23:24',
    'split:22:23',
    'corner:23:24:26:27',
    'corner:22:23:25:26',
    'field:26',
    'split:26:29',
    'split:26:27',
    'split:25:26',
    'corner:26:27:29:30',
    'corner:25:26:28:29',
    'field:29',
    'split:29:32',
    'split:29:30',
    'split:28:29',
    'corner:29:30:32:33',
    'corner:28:29:31:32',
    'field:32',
    'split:32:35',
    'split:32:33',
    'split:31:32',
    'corner:32:33:35:36',
    'corner:31:32:34:35',
    'field:35',
    'split:35:36',
    'split:34:35',
    'street:0:2:3',
    'street:0:1:2',
  ],
  'field:1': [
    'field:1',
    'split:1:2',
    'split:0:1',
    'split:1:4',
    'street:0:1:2',
    'street:1:2:3',
    'corner:0:1:2:3',
    'corner:1:2:4:5',
    'sixline:1:2:3:4:5:6',
  ],
  'field:4': [
    'field:4',
    'split:4:5',
    'split:1:4',
    'split:4:7',
    'street:4:5:6',
    'corner:1:2:4:5',
    'corner:4:5:7:8',
    'sixline:1:2:3:4:5:6',
    'sixline:4:5:6:7:8:9',
  ],
  'field:7': [
    'field:7',
    'split:7:8',
    'split:4:7',
    'split:7:10',
    'street:7:8:9',
    'corner:4:5:7:8',
    'corner:7:8:10:11',
    'sixline:4:5:6:7:8:9',
    'sixline:7:8:9:10:11:12',
  ],
  'field:10': [
    'field:10',
    'split:10:11',
    'split:7:10',
    'split:10:13',
    'street:10:11:12',
    'corner:7:8:10:11',
    'corner:10:11:13:14',
    'sixline:7:8:9:10:11:12',
    'sixline:10:11:12:13:14:15',
  ],
  'field:13': [
    'field:13',
    'split:13:14',
    'split:10:13',
    'split:13:16',
    'street:13:14:15',
    'corner:10:11:13:14',
    'corner:13:14:16:17',
    'sixline:10:11:12:13:14:15',
    'sixline:13:14:15:16:17:18',
  ],
  'field:16': [
    'field:16',
    'split:16:17',
    'split:13:16',
    'split:16:19',
    'street:16:17:18',
    'corner:13:14:16:17',
    'corner:16:17:19:20',
    'sixline:13:14:15:16:17:18',
    'sixline:16:17:18:19:20:21',
  ],
  'field:19': [
    'field:19',
    'split:19:20',
    'split:16:19',
    'split:19:22',
    'street:19:20:21',
    'corner:16:17:19:20',
    'corner:19:20:22:23',
    'sixline:16:17:18:19:20:21',
    'sixline:19:20:21:22:23:24',
  ],
  'field:22': [
    'field:22',
    'split:22:23',
    'split:19:22',
    'split:22:25',
    'street:22:23:24',
    'corner:19:20:22:23',
    'corner:22:23:25:26',
    'sixline:19:20:21:22:23:24',
    'sixline:22:23:24:25:26:27',
  ],
  'field:25': [
    'field:25',
    'split:25:26',
    'split:22:25',
    'split:25:28',
    'street:25:26:27',
    'corner:22:23:25:26',
    'corner:25:26:28:29',
    'sixline:22:23:24:25:26:27',
    'sixline:25:26:27:28:29:30',
  ],
  'field:28': [
    'field:28',
    'split:28:29',
    'split:25:28',
    'split:28:31',
    'street:28:29:30',
    'corner:25:26:28:29',
    'corner:28:29:31:32',
    'sixline:25:26:27:28:29:30',
    'sixline:28:29:30:31:32:33',
  ],
  'field:31': [
    'field:31',
    'split:31:32',
    'split:28:31',
    'split:31:34',
    'street:31:32:33',
    'corner:28:29:31:32',
    'corner:31:32:34:35',
    'sixline:28:29:30:31:32:33',
    'sixline:31:32:33:34:35:36',
  ],
  'field:34': [
    'field:34',
    'split:34:35',
    'split:31:34',
    'street:34:35:36',
    'corner:31:32:34:35',
    'sixline:31:32:33:34:35:36',
  ],
  'street:0:1:2': [
    'field:0',
    'split:0:3',
    'split:0:2',
    'split:0:1',
    'street:0:2:3',
    'street:0:1:2',
    'corner:0:1:2:3',
    'field:1',
    'split:1:2',
    'split:1:4',
    'street:1:2:3',
    'corner:1:2:4:5',
    'sixline:1:2:3:4:5:6',
    'field:2',
    'split:2:5',
    'split:2:3',
    'corner:2:3:5:6',
  ],
  'street:1:2:3': [
    'field:1',
    'split:1:2',
    'split:0:1',
    'split:1:4',
    'street:0:1:2',
    'street:1:2:3',
    'corner:0:1:2:3',
    'corner:1:2:4:5',
    'sixline:1:2:3:4:5:6',
    'field:2',
    'split:0:2',
    'split:2:5',
    'split:2:3',
    'street:0:2:3',
    'corner:2:3:5:6',
    'field:3',
    'split:0:3',
    'split:3:6',
  ],
  'street:4:5:6': [
    'field:4',
    'split:4:5',
    'split:1:4',
    'split:4:7',
    'street:4:5:6',
    'corner:1:2:4:5',
    'corner:4:5:7:8',
    'sixline:1:2:3:4:5:6',
    'sixline:4:5:6:7:8:9',
    'field:5',
    'split:2:5',
    'split:5:8',
    'split:5:6',
    'corner:2:3:5:6',
    'corner:5:6:8:9',
    'field:6',
    'split:3:6',
    'split:6:9',
  ],
  'street:7:8:9': [
    'field:7',
    'split:7:8',
    'split:4:7',
    'split:7:10',
    'street:7:8:9',
    'corner:4:5:7:8',
    'corner:7:8:10:11',
    'sixline:4:5:6:7:8:9',
    'sixline:7:8:9:10:11:12',
    'field:8',
    'split:5:8',
    'split:8:11',
    'split:8:9',
    'corner:5:6:8:9',
    'corner:8:9:11:12',
    'field:9',
    'split:6:9',
    'split:9:12',
  ],
  'street:10:11:12': [
    'field:10',
    'split:10:11',
    'split:7:10',
    'split:10:13',
    'street:10:11:12',
    'corner:7:8:10:11',
    'corner:10:11:13:14',
    'sixline:7:8:9:10:11:12',
    'sixline:10:11:12:13:14:15',
    'field:11',
    'split:8:11',
    'split:11:14',
    'split:11:12',
    'corner:8:9:11:12',
    'corner:11:12:14:15',
    'field:12',
    'split:9:12',
    'split:12:15',
  ],
  'street:13:14:15': [
    'field:13',
    'split:13:14',
    'split:10:13',
    'split:13:16',
    'street:13:14:15',
    'corner:10:11:13:14',
    'corner:13:14:16:17',
    'sixline:10:11:12:13:14:15',
    'sixline:13:14:15:16:17:18',
    'field:14',
    'split:11:14',
    'split:14:17',
    'split:14:15',
    'corner:11:12:14:15',
    'corner:14:15:17:18',
    'field:15',
    'split:12:15',
    'split:15:18',
  ],
  'street:16:17:18': [
    'field:16',
    'split:16:17',
    'split:13:16',
    'split:16:19',
    'street:16:17:18',
    'corner:13:14:16:17',
    'corner:16:17:19:20',
    'sixline:13:14:15:16:17:18',
    'sixline:16:17:18:19:20:21',
    'field:17',
    'split:14:17',
    'split:17:20',
    'split:17:18',
    'corner:14:15:17:18',
    'corner:17:18:20:21',
    'field:18',
    'split:15:18',
    'split:18:21',
  ],
  'street:19:20:21': [
    'field:19',
    'split:19:20',
    'split:16:19',
    'split:19:22',
    'street:19:20:21',
    'corner:16:17:19:20',
    'corner:19:20:22:23',
    'sixline:16:17:18:19:20:21',
    'sixline:19:20:21:22:23:24',
    'field:20',
    'split:17:20',
    'split:20:23',
    'split:20:21',
    'corner:17:18:20:21',
    'corner:20:21:23:24',
    'field:21',
    'split:18:21',
    'split:21:24',
  ],
  'street:22:23:24': [
    'field:22',
    'split:22:23',
    'split:19:22',
    'split:22:25',
    'street:22:23:24',
    'corner:19:20:22:23',
    'corner:22:23:25:26',
    'sixline:19:20:21:22:23:24',
    'sixline:22:23:24:25:26:27',
    'field:23',
    'split:20:23',
    'split:23:26',
    'split:23:24',
    'corner:20:21:23:24',
    'corner:23:24:26:27',
    'field:24',
    'split:21:24',
    'split:24:27',
  ],
  'street:25:26:27': [
    'field:25',
    'split:25:26',
    'split:22:25',
    'split:25:28',
    'street:25:26:27',
    'corner:22:23:25:26',
    'corner:25:26:28:29',
    'sixline:22:23:24:25:26:27',
    'sixline:25:26:27:28:29:30',
    'field:26',
    'split:23:26',
    'split:26:29',
    'split:26:27',
    'corner:23:24:26:27',
    'corner:26:27:29:30',
    'field:27',
    'split:24:27',
    'split:27:30',
  ],
  'street:28:29:30': [
    'field:28',
    'split:28:29',
    'split:25:28',
    'split:28:31',
    'street:28:29:30',
    'corner:25:26:28:29',
    'corner:28:29:31:32',
    'sixline:25:26:27:28:29:30',
    'sixline:28:29:30:31:32:33',
    'field:29',
    'split:26:29',
    'split:29:32',
    'split:29:30',
    'corner:26:27:29:30',
    'corner:29:30:32:33',
    'field:30',
    'split:27:30',
    'split:30:33',
  ],
  'street:31:32:33': [
    'field:31',
    'split:31:32',
    'split:28:31',
    'split:31:34',
    'street:31:32:33',
    'corner:28:29:31:32',
    'corner:31:32:34:35',
    'sixline:28:29:30:31:32:33',
    'sixline:31:32:33:34:35:36',
    'field:32',
    'split:29:32',
    'split:32:35',
    'split:32:33',
    'corner:29:30:32:33',
    'corner:32:33:35:36',
    'field:33',
    'split:30:33',
    'split:33:36',
  ],
  'street:34:35:36': [
    'field:34',
    'split:34:35',
    'split:31:34',
    'street:34:35:36',
    'corner:31:32:34:35',
    'sixline:31:32:33:34:35:36',
    'field:35',
    'split:32:35',
    'split:35:36',
    'corner:32:33:35:36',
    'field:36',
    'split:33:36',
  ],
  'sixline:1:2:3:4:5:6': [
    'field:1',
    'split:1:2',
    'split:0:1',
    'split:1:4',
    'street:0:1:2',
    'street:1:2:3',
    'corner:0:1:2:3',
    'corner:1:2:4:5',
    'sixline:1:2:3:4:5:6',
    'field:2',
    'split:0:2',
    'split:2:5',
    'split:2:3',
    'street:0:2:3',
    'corner:2:3:5:6',
    'field:3',
    'split:0:3',
    'split:3:6',
    'field:4',
    'split:4:5',
    'split:4:7',
    'street:4:5:6',
    'corner:4:5:7:8',
    'sixline:4:5:6:7:8:9',
    'field:5',
    'split:5:8',
    'split:5:6',
    'corner:5:6:8:9',
    'field:6',
    'split:6:9',
  ],
  'sixline:4:5:6:7:8:9': [
    'field:4',
    'split:4:5',
    'split:1:4',
    'split:4:7',
    'street:4:5:6',
    'corner:1:2:4:5',
    'corner:4:5:7:8',
    'sixline:1:2:3:4:5:6',
    'sixline:4:5:6:7:8:9',
    'field:5',
    'split:2:5',
    'split:5:8',
    'split:5:6',
    'corner:2:3:5:6',
    'corner:5:6:8:9',
    'field:6',
    'split:3:6',
    'split:6:9',
    'field:7',
    'split:7:8',
    'split:7:10',
    'street:7:8:9',
    'corner:7:8:10:11',
    'sixline:7:8:9:10:11:12',
    'field:8',
    'split:8:11',
    'split:8:9',
    'corner:8:9:11:12',
    'field:9',
    'split:9:12',
  ],
  'sixline:7:8:9:10:11:12': [
    'field:7',
    'split:7:8',
    'split:4:7',
    'split:7:10',
    'street:7:8:9',
    'corner:4:5:7:8',
    'corner:7:8:10:11',
    'sixline:4:5:6:7:8:9',
    'sixline:7:8:9:10:11:12',
    'field:8',
    'split:5:8',
    'split:8:11',
    'split:8:9',
    'corner:5:6:8:9',
    'corner:8:9:11:12',
    'field:9',
    'split:6:9',
    'split:9:12',
    'field:10',
    'split:10:11',
    'split:10:13',
    'street:10:11:12',
    'corner:10:11:13:14',
    'sixline:10:11:12:13:14:15',
    'field:11',
    'split:11:14',
    'split:11:12',
    'corner:11:12:14:15',
    'field:12',
    'split:12:15',
  ],
  'sixline:10:11:12:13:14:15': [
    'field:10',
    'split:10:11',
    'split:7:10',
    'split:10:13',
    'street:10:11:12',
    'corner:7:8:10:11',
    'corner:10:11:13:14',
    'sixline:7:8:9:10:11:12',
    'sixline:10:11:12:13:14:15',
    'field:11',
    'split:8:11',
    'split:11:14',
    'split:11:12',
    'corner:8:9:11:12',
    'corner:11:12:14:15',
    'field:12',
    'split:9:12',
    'split:12:15',
    'field:13',
    'split:13:14',
    'split:13:16',
    'street:13:14:15',
    'corner:13:14:16:17',
    'sixline:13:14:15:16:17:18',
    'field:14',
    'split:14:17',
    'split:14:15',
    'corner:14:15:17:18',
    'field:15',
    'split:15:18',
  ],
  'sixline:13:14:15:16:17:18': [
    'field:13',
    'split:13:14',
    'split:10:13',
    'split:13:16',
    'street:13:14:15',
    'corner:10:11:13:14',
    'corner:13:14:16:17',
    'sixline:10:11:12:13:14:15',
    'sixline:13:14:15:16:17:18',
    'field:14',
    'split:11:14',
    'split:14:17',
    'split:14:15',
    'corner:11:12:14:15',
    'corner:14:15:17:18',
    'field:15',
    'split:12:15',
    'split:15:18',
    'field:16',
    'split:16:17',
    'split:16:19',
    'street:16:17:18',
    'corner:16:17:19:20',
    'sixline:16:17:18:19:20:21',
    'field:17',
    'split:17:20',
    'split:17:18',
    'corner:17:18:20:21',
    'field:18',
    'split:18:21',
  ],
  'sixline:16:17:18:19:20:21': [
    'field:16',
    'split:16:17',
    'split:13:16',
    'split:16:19',
    'street:16:17:18',
    'corner:13:14:16:17',
    'corner:16:17:19:20',
    'sixline:13:14:15:16:17:18',
    'sixline:16:17:18:19:20:21',
    'field:17',
    'split:14:17',
    'split:17:20',
    'split:17:18',
    'corner:14:15:17:18',
    'corner:17:18:20:21',
    'field:18',
    'split:15:18',
    'split:18:21',
    'field:19',
    'split:19:20',
    'split:19:22',
    'street:19:20:21',
    'corner:19:20:22:23',
    'sixline:19:20:21:22:23:24',
    'field:20',
    'split:20:23',
    'split:20:21',
    'corner:20:21:23:24',
    'field:21',
    'split:21:24',
  ],
  'sixline:19:20:21:22:23:24': [
    'field:19',
    'split:19:20',
    'split:16:19',
    'split:19:22',
    'street:19:20:21',
    'corner:16:17:19:20',
    'corner:19:20:22:23',
    'sixline:16:17:18:19:20:21',
    'sixline:19:20:21:22:23:24',
    'field:20',
    'split:17:20',
    'split:20:23',
    'split:20:21',
    'corner:17:18:20:21',
    'corner:20:21:23:24',
    'field:21',
    'split:18:21',
    'split:21:24',
    'field:22',
    'split:22:23',
    'split:22:25',
    'street:22:23:24',
    'corner:22:23:25:26',
    'sixline:22:23:24:25:26:27',
    'field:23',
    'split:23:26',
    'split:23:24',
    'corner:23:24:26:27',
    'field:24',
    'split:24:27',
  ],
  'sixline:22:23:24:25:26:27': [
    'field:22',
    'split:22:23',
    'split:19:22',
    'split:22:25',
    'street:22:23:24',
    'corner:19:20:22:23',
    'corner:22:23:25:26',
    'sixline:19:20:21:22:23:24',
    'sixline:22:23:24:25:26:27',
    'field:23',
    'split:20:23',
    'split:23:26',
    'split:23:24',
    'corner:20:21:23:24',
    'corner:23:24:26:27',
    'field:24',
    'split:21:24',
    'split:24:27',
    'field:25',
    'split:25:26',
    'split:25:28',
    'street:25:26:27',
    'corner:25:26:28:29',
    'sixline:25:26:27:28:29:30',
    'field:26',
    'split:26:29',
    'split:26:27',
    'corner:26:27:29:30',
    'field:27',
    'split:27:30',
  ],
  'sixline:25:26:27:28:29:30': [
    'field:25',
    'split:25:26',
    'split:22:25',
    'split:25:28',
    'street:25:26:27',
    'corner:22:23:25:26',
    'corner:25:26:28:29',
    'sixline:22:23:24:25:26:27',
    'sixline:25:26:27:28:29:30',
    'field:26',
    'split:23:26',
    'split:26:29',
    'split:26:27',
    'corner:23:24:26:27',
    'corner:26:27:29:30',
    'field:27',
    'split:24:27',
    'split:27:30',
    'field:28',
    'split:28:29',
    'split:28:31',
    'street:28:29:30',
    'corner:28:29:31:32',
    'sixline:28:29:30:31:32:33',
    'field:29',
    'split:29:32',
    'split:29:30',
    'corner:29:30:32:33',
    'field:30',
    'split:30:33',
  ],
  'sixline:28:29:30:31:32:33': [
    'field:28',
    'split:28:29',
    'split:25:28',
    'split:28:31',
    'street:28:29:30',
    'corner:25:26:28:29',
    'corner:28:29:31:32',
    'sixline:25:26:27:28:29:30',
    'sixline:28:29:30:31:32:33',
    'field:29',
    'split:26:29',
    'split:29:32',
    'split:29:30',
    'corner:26:27:29:30',
    'corner:29:30:32:33',
    'field:30',
    'split:27:30',
    'split:30:33',
    'field:31',
    'split:31:32',
    'split:31:34',
    'street:31:32:33',
    'corner:31:32:34:35',
    'sixline:31:32:33:34:35:36',
    'field:32',
    'split:32:35',
    'split:32:33',
    'corner:32:33:35:36',
    'field:33',
    'split:33:36',
  ],
  'sixline:31:32:33:34:35:36': [
    'field:31',
    'split:31:32',
    'split:28:31',
    'split:31:34',
    'street:31:32:33',
    'corner:28:29:31:32',
    'corner:31:32:34:35',
    'sixline:28:29:30:31:32:33',
    'sixline:31:32:33:34:35:36',
    'field:32',
    'split:29:32',
    'split:32:35',
    'split:32:33',
    'corner:29:30:32:33',
    'corner:32:33:35:36',
    'field:33',
    'split:30:33',
    'split:33:36',
    'field:34',
    'split:34:35',
    'street:34:35:36',
    'field:35',
    'split:35:36',
    'field:36',
  ],
  'row:1:4:7:10:13:16:19:22:25:28:31:34': [
    'field:1',
    'split:1:2',
    'split:0:1',
    'split:1:4',
    'corner:0:1:2:3',
    'corner:1:2:4:5',
    'field:4',
    'split:4:5',
    'split:4:7',
    'corner:4:5:7:8',
    'field:7',
    'split:7:8',
    'split:7:10',
    'corner:7:8:10:11',
    'field:10',
    'split:10:11',
    'split:10:13',
    'corner:10:11:13:14',
    'field:13',
    'split:13:14',
    'split:13:16',
    'corner:13:14:16:17',
    'field:16',
    'split:16:17',
    'split:16:19',
    'corner:16:17:19:20',
    'field:19',
    'split:19:20',
    'split:19:22',
    'corner:19:20:22:23',
    'field:22',
    'split:22:23',
    'split:22:25',
    'corner:22:23:25:26',
    'field:25',
    'split:25:26',
    'split:25:28',
    'corner:25:26:28:29',
    'field:28',
    'split:28:29',
    'split:28:31',
    'corner:28:29:31:32',
    'field:31',
    'split:31:32',
    'split:31:34',
    'corner:31:32:34:35',
    'field:34',
    'split:34:35',
    'street:0:1:2',
    'street:1:2:3',
    'street:4:5:6',
    'street:7:8:9',
    'street:10:11:12',
    'street:13:14:15',
    'street:16:17:18',
    'street:19:20:21',
    'street:22:23:24',
    'street:25:26:27',
    'street:28:29:30',
    'street:31:32:33',
    'street:34:35:36',
    'sixline:1:2:3:4:5:6',
    'sixline:4:5:6:7:8:9',
    'sixline:7:8:9:10:11:12',
    'sixline:10:11:12:13:14:15',
    'sixline:13:14:15:16:17:18',
    'sixline:16:17:18:19:20:21',
    'sixline:19:20:21:22:23:24',
    'sixline:22:23:24:25:26:27',
    'sixline:25:26:27:28:29:30',
    'sixline:28:29:30:31:32:33',
    'sixline:31:32:33:34:35:36',
  ],
  'dozen:1:2:3:4:5:6:7:8:9:10:11:12': [
    'field:1',
    'split:1:2',
    'split:0:1',
    'split:1:4',
    'street:0:1:2',
    'street:1:2:3',
    'corner:0:1:2:3',
    'corner:1:2:4:5',
    'sixline:1:2:3:4:5:6',
    'field:2',
    'split:0:2',
    'split:2:5',
    'split:2:3',
    'street:0:2:3',
    'corner:2:3:5:6',
    'field:3',
    'split:0:3',
    'split:3:6',
    'field:4',
    'split:4:5',
    'split:4:7',
    'street:4:5:6',
    'corner:4:5:7:8',
    'sixline:4:5:6:7:8:9',
    'field:5',
    'split:5:8',
    'split:5:6',
    'corner:5:6:8:9',
    'field:6',
    'split:6:9',
    'field:7',
    'split:7:8',
    'split:7:10',
    'street:7:8:9',
    'corner:7:8:10:11',
    'sixline:7:8:9:10:11:12',
    'field:8',
    'split:8:11',
    'split:8:9',
    'corner:8:9:11:12',
    'field:9',
    'split:9:12',
    'field:10',
    'split:10:11',
    'split:10:13',
    'street:10:11:12',
    'corner:10:11:13:14',
    'sixline:10:11:12:13:14:15',
    'field:11',
    'split:11:14',
    'split:11:12',
    'corner:11:12:14:15',
    'field:12',
    'split:12:15',
  ],
  'dozen:13:14:15:16:17:18:19:20:21:22:23:24': [
    'field:13',
    'split:13:14',
    'split:10:13',
    'split:13:16',
    'street:13:14:15',
    'corner:10:11:13:14',
    'corner:13:14:16:17',
    'sixline:10:11:12:13:14:15',
    'sixline:13:14:15:16:17:18',
    'field:14',
    'split:11:14',
    'split:14:17',
    'split:14:15',
    'corner:11:12:14:15',
    'corner:14:15:17:18',
    'field:15',
    'split:12:15',
    'split:15:18',
    'field:16',
    'split:16:17',
    'split:16:19',
    'street:16:17:18',
    'corner:16:17:19:20',
    'sixline:16:17:18:19:20:21',
    'field:17',
    'split:17:20',
    'split:17:18',
    'corner:17:18:20:21',
    'field:18',
    'split:18:21',
    'field:19',
    'split:19:20',
    'split:19:22',
    'street:19:20:21',
    'corner:19:20:22:23',
    'sixline:19:20:21:22:23:24',
    'field:20',
    'split:20:23',
    'split:20:21',
    'corner:20:21:23:24',
    'field:21',
    'split:21:24',
    'field:22',
    'split:22:23',
    'split:22:25',
    'street:22:23:24',
    'corner:22:23:25:26',
    'sixline:22:23:24:25:26:27',
    'field:23',
    'split:23:26',
    'split:23:24',
    'corner:23:24:26:27',
    'field:24',
    'split:24:27',
  ],
  'dozen:25:26:27:28:29:30:31:32:33:34:35:36': [
    'field:25',
    'split:25:26',
    'split:22:25',
    'split:25:28',
    'street:25:26:27',
    'corner:22:23:25:26',
    'corner:25:26:28:29',
    'sixline:22:23:24:25:26:27',
    'sixline:25:26:27:28:29:30',
    'field:26',
    'split:23:26',
    'split:26:29',
    'split:26:27',
    'corner:23:24:26:27',
    'corner:26:27:29:30',
    'field:27',
    'split:24:27',
    'split:27:30',
    'field:28',
    'split:28:29',
    'split:28:31',
    'street:28:29:30',
    'corner:28:29:31:32',
    'sixline:28:29:30:31:32:33',
    'field:29',
    'split:29:32',
    'split:29:30',
    'corner:29:30:32:33',
    'field:30',
    'split:30:33',
    'field:31',
    'split:31:32',
    'split:31:34',
    'street:31:32:33',
    'corner:31:32:34:35',
    'sixline:31:32:33:34:35:36',
    'field:32',
    'split:32:35',
    'split:32:33',
    'corner:32:33:35:36',
    'field:33',
    'split:33:36',
    'field:34',
    'split:34:35',
    'street:34:35:36',
    'field:35',
    'split:35:36',
    'field:36',
  ],
}

export const getCompleteBets = (betName: NameFieldBets): string[] | undefined => get(completeBets, betName)
