import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { autorun, makeAutoObservable, toJS } from 'mobx'
import { isEmpty } from 'lodash'
import { SentryTags } from '../constants/sentry'
import { loginStore, rouletteStore, webrtcStreamStore } from './index'
import { sessionIdUnknownStatus } from './Roulette/SessionId.store'

export class SentryStore {
  gameScope: {
    [key in string]: Sentry.Scope
  } = {}

  constructor() {
    makeAutoObservable(this)

    if (process.env.NODE_ENV !== 'production') return

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      environment: process.env.NODE_ENV,
      integrations: [new BrowserTracing({ tracingOrigins: ['*'] })],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      release: process.env.REACT_APP_VERSION,
    })

    Sentry.configureScope((scope) => {
      scope.setTag(SentryTags.Client, process.env.REACT_APP_TITLE)
      scope.setTag(SentryTags.Chipsets, process.env.REACT_APP_CHIPSETS)
      scope.setTag(SentryTags.AntMediaUrl, process.env.REACT_APP_ANT_URL)
    })

    autorun(() => {
      const { user } = loginStore

      Sentry.setUser(toJS(user))
    })

    autorun(() => {
      const { gameId, sessionIdStore } = rouletteStore

      if (gameId) Sentry.setTag(SentryTags.GameId, gameId)
      if (sessionIdStore.id !== sessionIdUnknownStatus) Sentry.setTag(SentryTags.SessionId, sessionIdStore.id)
    })

    autorun(() => {
      const { lastVerifyDataStore } = rouletteStore

      if (!isEmpty(lastVerifyDataStore.rouletteBet)) {
        if (lastVerifyDataStore.rouletteBet?.status === 200) return

        Sentry.withScope((scope) => {
          scope.setLevel('warning')
          Sentry.captureException(lastVerifyDataStore.rouletteBet)
        })
      }
    })

    autorun(() => {
      const { error } = webrtcStreamStore

      if (error) Sentry.captureException(error)
    })
  }

  createGameScope = (id: string): Sentry.Scope => {
    const scope = new Sentry.Scope()

    this.gameScope[id] = scope

    return scope
  }
}
