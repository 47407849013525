import styled from '@emotion/styled'
import sideBigLeft from './img/side-big-left.png'

const AuthForm = styled.form`
  padding: 20px;
  background: #0b1b3a;
  box-shadow: 0 40px 20px -10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;
  border: 1px solid #162c57;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 240px;
    background: url(${sideBigLeft}) top center no-repeat;
    background-size: 100% 100%;
  }

  &:before {
    left: -240px;
  }

  &:after {
    right: -240px;
    transform: scale(-1, 1);
  }
`

export default AuthForm
