import { makeAutoObservable } from 'mobx'
import { cloneDeep } from 'lodash'
import { HistoryBets } from './Bets.store'
import { RouletteBetEventResponse } from '../../services/RouletteEvents'

export type LastVerifyData = {
  listBets: HistoryBets
  deposit: number
  totalBets: number
}

class LastVerifyDataStore {
  lastVerifyData: LastVerifyData | null = null

  rouletteBet: RouletteBetEventResponse | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setRouletteBet = (data: RouletteBetEventResponse): void => {
    this.rouletteBet = cloneDeep(data)
  }

  setLastVerifyData = (data: LastVerifyData | null): LastVerifyData | null => {
    this.lastVerifyData = cloneDeep(data)

    return this.lastVerifyData
  }
}

export default LastVerifyDataStore
