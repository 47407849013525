import styled from '@emotion/styled'

const LobbyTitle = styled.div`
  padding: 20px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ff9a3d;
`

export default LobbyTitle
