import { format, addSeconds } from 'date-fns'

const dateFormat = 'yyy-MM-dd HH:mm:ss'

export const getDateFormat = (date: number): string => format(date, dateFormat)
export const getDateAddSeconds = (num: number): string => format(addSeconds(new Date(), num), dateFormat)
export const getTimestampTokenExpired = (tokenLife: number): number => {
  const timestampToken = Date.now()
  return timestampToken + (tokenLife - 10) * 1000
}
