import { ReactNode, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import { record, pack } from 'rrweb'
import { observer } from 'mobx-react-lite'
import { Container as StyledContainer } from './styles'
import { sessionIdUnknownStatus } from '../../stores/Roulette/SessionId.store'
import { saveRecordings } from '../../api/recordings'
import { useStore } from '../../stores'

type ContainerProps = {
  children: ReactNode
  sessionId: number
  userId?: string
  login?: string
  gameId?: string
}

const events: any[] = []

const Container = observer((props: ContainerProps): JSX.Element => {
  const { children, sessionId, userId, login, gameId } = props
  const { applicationStore } = useStore()

  useEffect(() => {
    ;(async () =>
      applicationStore.fpJsClient?.getVisitorData({
        linkedId: login,
        tag: {
          userAction: `open roulette ${gameId}`,
        },
      }))()
  }, [])

  useEffect(() => {
    if (sessionId === sessionIdUnknownStatus) return undefined

    const startRecordTimestamp = Date.now()

    const stopFn = record({
      emit: (event) => {
        events.push(event)
      },
      packFn: pack,
    })

    return () => {
      if (stopFn) stopFn()
      const copyEvents = cloneDeep(events)
      const endRecordTimestamp = Date.now()
      events.length = 0

      saveRecordings({
        startRecordTimestamp,
        endRecordTimestamp,
        user: {
          id: userId,
          login,
        },
        sessionId,
        events: copyEvents,
      })
    }
  }, [sessionId])

  return <StyledContainer>{children}</StyledContainer>
})

export default Container
