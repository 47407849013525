import { makeAutoObservable } from 'mobx'

export type StreamData = {
  name: string // 'UBIS'
  streamId: string // '484282862950950045466824'
}

class StreamDataStore {
  streamData: StreamData | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setStreamData = (data: StreamData): StreamData => {
    this.streamData = { ...data }

    return this.streamData
  }
}

export default StreamDataStore
