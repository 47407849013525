import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import icon from './img/cancel.svg'
import { RoundBtn } from '../index'
import { useStore } from '../../stores'
import { mediaQueries } from '../../core/theme'

const StyledImg = styled.img`
  width: 10px;
  height: 10px;

  ${mediaQueries.medium} {
    width: 18px;
    height: 18px;
  }

  ${mediaQueries.large} {
    width: 22.5px;
    height: 22.5px;
  }
`

const RemoveAllBetsBtn = observer((): JSX.Element => {
  const { rouletteStore } = useStore()
  const { betsStore, statusGameStore, betsService } = rouletteStore
  const disabled = betsStore.isEmptyHistoryBets || !statusGameStore.isBetting

  return (
    <RoundBtn onClick={betsService.removeAllBets} disabled={disabled}>
      <StyledImg src={icon} alt="cancel" />
    </RoundBtn>
  )
})

export default RemoveAllBetsBtn
