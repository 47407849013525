import { observer } from 'mobx-react-lite'
import { useUnmount } from 'react-use'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../stores'
import { LoginProcessStatus } from '../../stores/Login.store'
import { LoginForm, LoginSms, Box, AuthContainer } from '../../components'

const Login = observer((): JSX.Element => {
  const { t } = useTranslation()
  const { loginStore } = useStore()

  useUnmount(() => {
    if (!loginStore.isAuthenticated) loginStore.reset()
  })

  const step: { [key: number]: JSX.Element } = {
    [LoginProcessStatus.LOGIN]: <LoginForm />,
    [LoginProcessStatus.SMS]: <LoginSms />,
  }

  return (
    <AuthContainer>
      <Box>
        <Box
          mb={20}
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="var1">
          {t('authorization')}
        </Box>
        {step[loginStore.processStatus]}
      </Box>
    </AuthContainer>
  )
})

export default Login
