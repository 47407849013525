import { isNumber, pickBy, reduce } from 'lodash'
import { NameFieldBets } from '../nameFieldBets'
import { TypeBets } from '../../constants/typeBets'
import { scaleLimits } from '../../constants/scaleLimits'
import { Limits } from '../../stores/Roulette/Limits.store'
import { Bets, BetsForValidation, DepositErrorCode, ProcessedBets } from '../../stores/Roulette/Bets.store'
import { MODE_BETS } from '../../stores/Roulette/UI.store'

export enum LimitsWarning {
  //
  NoChanges,
  // 'Ставка не может быть больше максимального лимита на выбранное поле!'
  OverMaxLimit,
  // 'Ставка не может быть меньше минимального лимита на выбранное поле!'
  LessMinLimit,
}

export type PreAlignedByLimitsBets = {
  [key in NameFieldBets]: {
    limits: {
      minimum: number
      maximum: number
    }
    amount: number
    currentAmountBet: number
    modeBets: MODE_BETS
  }
}

export type AlignedByLimitsBets = {
  [key in NameFieldBets]: {
    amount: number
    limitsWarningCode: number
  }
}

export function getTypeBetsFromKey(keyBet: NameFieldBets): TypeBets {
  return keyBet.split(':')[0] as TypeBets
}

export function getLimitsForBet(
  fieldName: NameFieldBets,
  limits: Limits
): {
  minimum: number
  maximum: number
} {
  const typeBet = getTypeBetsFromKey(fieldName)
  const limit = {
    minimum: limits.minimum,
    maximum: limits.maximum,
  }

  switch (typeBet) {
    case TypeBets.DOZEN:
      limit.minimum = limits.minBetColDozen
      limit.maximum = limits.maxBetColDozen
      break
    case TypeBets.ROW:
      limit.minimum = limits.minBetColDozen
      limit.maximum = limits.maxBetColDozen
      break
    case TypeBets.COLOR:
      limit.minimum = limits.minBetEvenChance
      limit.maximum = limits.maxBetEvenChance
      break
    case TypeBets.PARITY:
      limit.minimum = limits.minBetEvenChance
      limit.maximum = limits.maxBetEvenChance
      break
    case TypeBets.HALF:
      limit.minimum = limits.minBetEvenChance
      limit.maximum = limits.maxBetEvenChance
      break
    case TypeBets.SPLIT:
      limit.maximum = limits.maximum * scaleLimits[TypeBets.SPLIT]
      break
    case TypeBets.STREET:
      limit.maximum = limits.maximum * scaleLimits[TypeBets.STREET]
      break
    case TypeBets.CORNER:
      limit.maximum = limits.maximum * scaleLimits[TypeBets.CORNER]
      break
    case TypeBets.SIXLINE:
      limit.maximum = limits.maximum * scaleLimits[TypeBets.SIXLINE]
      break
    default:
      // limit.maximum = limits.maximum * SCALE_LIMITS.straightUp
      limit.maximum = limits.maximum
      break
  }

  return limit
}

export function equalizationOfBetsByLimits(data: BetsForValidation): AlignedByLimitsBets {
  return reduce(
    data,
    (acc, item, key) => {
      const maxAmountBet = item.limits.maximum
      const minAmountBet = item.limits.minimum
      const nameBet = key as NameFieldBets
      const typeBet = item.modeBets
      const futureAmountBet = item.currentAmount + item.amount

      let { amount } = item
      let limitsWarningCode = LimitsWarning.NoChanges

      if (futureAmountBet > maxAmountBet) {
        // fut 12, max 10, amount 7, currentBet 5 = 5
        // fut 17, max 10, amount 7, currentBet 10 = 0
        amount = maxAmountBet - item.currentAmount
        limitsWarningCode = LimitsWarning.OverMaxLimit
      }
      if (futureAmountBet < minAmountBet) {
        amount = minAmountBet
        limitsWarningCode = LimitsWarning.LessMinLimit
      }

      if (typeBet === MODE_BETS.COMPLETE) {
        const shortNameTypeBet = getTypeBetsFromKey(nameBet)
        amount *= scaleLimits[shortNameTypeBet]
        const newFutureAmountBet = item.currentAmount + amount

        if (newFutureAmountBet > maxAmountBet) {
          amount = maxAmountBet - item.currentAmount
          limitsWarningCode = LimitsWarning.OverMaxLimit
        }
      }

      return {
        ...acc,
        [nameBet]: {
          limitsWarningCode,
          amount,
        },
      }
    },
    {}
  ) as AlignedByLimitsBets
}

export function transformBets(data: AlignedByLimitsBets): Bets {
  return reduce(
    data,
    (acc, item, key) => ({
      ...acc,
      [key]: item.amount,
    }),
    {}
  ) as Bets
}

export function isNotEnoughMoney(data: AlignedByLimitsBets, deposit: number): boolean {
  const requiredSumBets = reduce(data, (acc, item) => acc + item.amount, 0)
  return requiredSumBets > deposit
}

export const sumBets = (alignedByLimitsBets: AlignedByLimitsBets): number =>
  reduce(alignedByLimitsBets, (acc, item) => acc + item.amount, 0)

// хватает ли депозита
export const isThereEnoughMoney = (sumBetsAmount: number, deposit: number): boolean => sumBetsAmount <= deposit

//
export function neededDeposit(data: Bets): number {
  return reduce(
    data,
    (acc, item) => {
      const num = isNumber(item) ? item : 0
      return acc + num
    },
    0
  )
}
export function processingBets(data: BetsForValidation, deposit: number): ProcessedBets {
  // выравнивание по лимитам
  const alignedByLimitsBets = equalizationOfBetsByLimits(data)
  // выбираем ставки с amount больше нуля
  const cleanZeroAmount = pickBy(alignedByLimitsBets, (item) => item.amount > 0) as AlignedByLimitsBets
  // проверим хватит ли денег на все ставки
  const sumBetsAmount = sumBets(cleanZeroAmount)

  if (!isThereEnoughMoney(sumBetsAmount, deposit)) {
    return {
      sumBets: 0,
      bets: {},
      limitWarningCode: LimitsWarning.NoChanges,
      depositErrorCode: DepositErrorCode.NoEnough,
    }
  }

  // предупреждения о превышении/меньше лимита
  const limitWarningCode = reduce(cleanZeroAmount, (acc, item) => item.limitsWarningCode, LimitsWarning.NoChanges)
  // приведение ставок к нормальному виду
  const cleanBets = transformBets(cleanZeroAmount)

  return {
    sumBets: sumBetsAmount,
    bets: cleanBets,
    limitWarningCode,
    depositErrorCode: DepositErrorCode.Enough,
  }
}
