import { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useEvent, useMount, useUnmount } from 'react-use'
import { debounce } from 'lodash'
import styled from '@emotion/styled'
import { useStore } from '../../stores'
import { mediaQueries } from '../../core/theme'
import { StreamData } from '../../stores/Roulette/StreamData.store'

type VideoProps = {
  data: StreamData
  offsetHeight: (data: number) => void
}

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;

  ${mediaQueries.medium} {
    object-fit: contain;
    height: auto;
  }
`

const Video = observer((props: VideoProps): JSX.Element => {
  const streamId = 'stream'
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, offsetHeight } = props
  const { webrtcStreamStore, rouletteStore } = useStore()
  const { volumeStore } = rouletteStore
  const ref = useRef<HTMLVideoElement | null>(null)
  const getOffsetHeight = (): void => {
    if (!ref.current) return

    offsetHeight(ref.current.offsetHeight)
  }

  useMount(() => {
    webrtcStreamStore.setStreamElementId(streamId)

    webrtcStreamStore.play(data.streamId)
  })

  useUnmount(() => {
    webrtcStreamStore.stop(data.streamId)
  })

  useEffect(() => {
    if (ref.current) {
      ref.current.volume = volumeStore.volume
    }
  }, [ref, volumeStore.volume])

  useEvent('resize', debounce(getOffsetHeight, 300))
  useEvent('orientationchange', debounce(getOffsetHeight, 100))

  return (
    <StyledVideo
      onPlay={() => {
        if (ref.current) offsetHeight(ref.current.offsetHeight)
      }}
      ref={ref}
      id={streamId}
      autoPlay
      playsInline
      muted={volumeStore.isMuted}
    />
  )
})

export default Video
