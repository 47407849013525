import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { symbolCurrency } from '@lbs/currency'
import { useStore } from '../../stores'

const Currency: FC = observer(() => {
  const { applicationStore } = useStore()

  return <span>{symbolCurrency[applicationStore.currency]}</span>
})

export default Currency
