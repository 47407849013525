import { makeAutoObservable } from 'mobx'
import { AvailabilityChipsets, ChipsetsKey, ChipsRange } from '../services/chipsets'

export class ChipsetsStore {
  chipsets: ChipsRange = AvailabilityChipsets[process.env.REACT_APP_CHIPSETS as ChipsetsKey]

  constructor() {
    makeAutoObservable(this)
  }
}
