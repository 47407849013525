import styled from '@emotion/styled'
import { mediaQueries } from '../../core/theme'

export const StyledGroupChip = styled.g`
  ${mediaQueries.medium} {
    display: none;
  }
`

export const Container = styled.div<{
  visible?: boolean
}>`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: ${(props) => (props.visible ? 'flex' : 'none')};

  ${mediaQueries.medium} {
    display: flex;
  }
`

export const StyledSvg = styled.svg`
  width: 100%;
  height: 100%;

  ${mediaQueries.medium} {
    width: 90%;
  }
`

export const StyledText = styled.text`
  font-size: 7px;
  font-weight: bold;
  fill: #000;
  dominant-baseline: central;
  text-anchor: middle;
`

export const StyledRect = styled.rect`
  fill: white;
  fill-opacity: 0;
`

export const StyledPath = styled.path`
  fill: white;
  fill-opacity: 0;
`

export const StyledGroup = styled.g<{
  disabled?: boolean
}>`
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:hover ${StyledRect} {
    fill-opacity: ${(props) => (props.disabled ? 0 : 0.3)};
  }

  &:hover ${StyledPath} {
    fill-opacity: ${(props) => (props.disabled ? 0 : 0.3)};
  }
`
