import styled from '@emotion/styled'
import { maxContainerWidth, mediaQueries } from '../../core/theme'

export const HeaderPanel = styled.div`
  background: #0b1b3a;
  height: 60px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${mediaQueries.medium} {
    padding-left: 70px;
    padding-right: 70px;
  }
`

export const HeaderPanelInside = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;

  ${mediaQueries.small} {
    flex-direction: row;
    height: 60px;
  }

  ${mediaQueries.large} {
    max-width: ${maxContainerWidth};
  }
`

export const HeaderBalance = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;

  img {
    width: 14px;
  }
`

export const HeaderBalanceName = styled.div`
  color: #98abd0;
`

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderLogo = styled.img`
  display: none;

  ${mediaQueries.medium} {
    display: block;
    margin-left: -44px;
  }

  ${mediaQueries.large} {
    margin-left: 0;
    position: absolute;
    left: 0;
  }
`

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: space-between;

  ${mediaQueries.small} {
    width: auto;
    justify-content: initial;
  }
`

export const LogoutIcon = styled.img`
  display: block;
  cursor: pointer;
`

export const LocaleWrapper = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
`

export const LocaleDropDownMenu = styled.div<{
  open: boolean
}>`
  position: absolute;
  top: 100%;
  left: -12px;
  padding: 8px 12px;
  background: #0b1b3a;
  z-index: 99;
  display: ${(props) => (props.open ? 'block' : 'none')};
`

export const LocaleDropDownMenuItem = styled.div`
  margin-bottom: 4px;
  cursor: pointer;
`

export const CurrentLanguage = styled.span`
  cursor: pointer;
`
