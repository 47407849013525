import React, { useRef } from 'react'
import { useClickAway, useLockBodyScroll, useToggle } from 'react-use'
import { useTranslation } from 'react-i18next'
import closeIcon from './img/close.svg'
import { Close, Container, ContainerInside, Title, Wrapper } from './styles'
import { Box, Button, PseudoLink } from '../index'

type PopupFormProps = {
  title: string
  open?: boolean
  children?: React.ReactNode
  onClose?: () => void
  onSubmit?: () => void
  onCancel?: () => void
  showSubmit?: boolean
  showCancel?: boolean
}

const PopupForm = (props: PopupFormProps): JSX.Element | null => {
  const { title, open, children, onSubmit, onClose, onCancel, showSubmit = true, showCancel = true } = props
  const ref = useRef(null)
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [on, toggle] = useToggle(Boolean(open))

  const handleClose = (): void => {
    toggle(false)

    if (onCancel) onCancel()
    if (onClose) onClose()
  }

  useClickAway(ref, () => {
    handleClose()
  })

  useLockBodyScroll(on)

  if (!open) return null

  return (
    <Wrapper>
      <Container
        ref={ref}
        onSubmit={(e) => {
          e.preventDefault()

          toggle(false)

          if (onSubmit) onSubmit()
          if (onClose) onClose()
        }}>
        <ContainerInside>
          <Title>{title}</Title>
          <Close
            onClick={(e) => {
              e.preventDefault()

              handleClose()
            }}>
            <img src={closeIcon} alt="close" />
          </Close>
          {children}
          {showSubmit && (
            <Box mb={20}>
              <Button label={t('continue')} type="submit" fullWidth />
            </Box>
          )}
          {showCancel && (
            <PseudoLink
              onClick={(e) => {
                e.preventDefault()

                handleClose()
              }}>
              {t('cancel')}
            </PseudoLink>
          )}
        </ContainerInside>
      </Container>
    </Wrapper>
  )
}

export default PopupForm
