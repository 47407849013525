import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Button } from '../index'
import { useStore } from '../../stores'

const Banner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GameBannerEntry = observer((): JSX.Element => {
  const { rouletteStore, webrtcStreamStore } = useStore()
  const { streamDataStore } = rouletteStore
  const { t } = useTranslation()
  return (
    <Banner>
      <Button
        type="button"
        label={t('toComeIn')}
        onClick={() => {
          webrtcStreamStore.play(streamDataStore.streamData?.streamId || '')
        }}
      />
    </Banner>
  )
})

export default GameBannerEntry
