import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mediaQueries } from '../../core/theme'

export const Txt = styled.div`
  font-size: 11px;
  text-align: center;
  padding: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;

  ${mediaQueries.medium} {
    font-size: 18px;

    img {
      width: 18px;
    }
  }
`

export const ColoredTxt = styled.span<{
  color?: 'green' | 'white' | 'red'
}>`
  ${(props) => {
    switch (props.color) {
      case 'green':
        return css({
          color: '#69C564',
        })
      case 'white':
        return css({
          color: '#FFFFFF',
        })
      case 'red':
        return css({
          color: '#FF4646',
        })
      default:
        return css({
          color: '#FFFFFF',
        })
    }
  }}
`
