import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const StyledFooter = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 40px 0;
`

const Footer = (): JSX.Element => {
  const { t } = useTranslation()

  return <StyledFooter>© {new Date().getFullYear()} {t('app')}</StyledFooter>
}

export default Footer
