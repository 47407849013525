import styled from '@emotion/styled'

const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  R = parseInt(String((R * (100 + percent)) / 100), 10)
  G = parseInt(String((G * (100 + percent)) / 100), 10)
  B = parseInt(String((B * (100 + percent)) / 100), 10)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16)
  const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16)
  const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16)

  return `#${RR}${GG}${BB}`
}

export const StyledButtonDropDown = styled.div`
  width: 200px;
  background-color: transparent;
  border: 1px solid #ff9a3d;
  border-radius: 8px;
  padding: 4px 16px 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  position: relative;
`

export const IconSelect = styled.div<{
  open?: boolean
}>`
  position: absolute;
  right: 8px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  will-change: transform, rotate;
  transition: transform 0.1s ease-out;
  transform: ${(props) => {
    let value = 0

    if (props.open) value = -180

    return `rotate(${value}deg)`
  }};
`

export const StyledListBox = styled.div`
  position: relative;
`

export const StyledOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #0b1b3a;
  border: 1px solid #ff9a3d;
  border-radius: 8px;
  padding: 4px 0;
  width: 100%;
  margin-top: 4px;
  z-index: 10;
`

export const StyledOption = styled.div`
  cursor: pointer;
  padding: 4px 8px;
`

export const StyledOptionItem = styled.div<{ active: boolean; selected: boolean }>`
  color: ${(props) => {
    let color = '#fff'

    if (props.selected) color = '#ff9a3d'

    return color
  }};
  background-color: ${(props) => {
    let color = 'transparent'

    if (props.active) color = shadeColor('#0b1b3a', -20)

    return color
  }};
  padding: 4px 8px;
  border-radius: 8px;
`
