import styled from '@emotion/styled'
import { ButtonProps } from './index'

export const StyledButton = styled.button<{
  fullWidth?: ButtonProps['fullWidth']
  disabled?: ButtonProps['disabled']
}>`
  color: ${(props) => props.theme.colors.var2};
  background: linear-gradient(180deg, #fff490 0%, #ff9a3d 100%);
  box-shadow: 0 20px 20px -10px rgba(255, 240, 140, 0.2);
  border-radius: 4px;
  border: none;
  padding: 10px 12px;
  display: inline-flex;
  line-height: 20px;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`
