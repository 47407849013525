import styled from '@emotion/styled'
import { mediaQueries } from '../../core/theme'

export const RoundBtn = styled.div<{
  active?: boolean
  disabled?: boolean
}>`
  background: ${(props) => (props.active ? '#FFFFFF' : 'rgba(0, 0, 0, 0.2)')};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  ${mediaQueries.medium} {
    width: 48px;
    height: 48px;
  }

  ${mediaQueries.large} {
    width: 60px;
    height: 60px;
  }
`

export default RoundBtn
