import React, { FC } from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { GlobalStyles } from './core/GlobalStyles'
import { theme } from './core/theme'
import stores, { AppContext, useStore } from './stores'
import routes from './core/routes'
import { Lobby, Login, Registration, NoMatch, Roulette, Slot, SlotClose } from './pages'
import { AppLayout, Common } from './layouts'

import 'react-toastify/dist/ReactToastify.css'

import '@fontsource/roboto'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/700.css'

const queryClient = new QueryClient()

const PrivateRoute = observer((): JSX.Element => {
  const { loginStore } = useStore()

  return !loginStore.isAuthenticated ? <Navigate to={routes.login} replace /> : <Outlet />
})

const AuthRoute = observer((): JSX.Element => {
  const { loginStore } = useStore()

  return loginStore.isAuthenticated ? <Navigate to="/" replace /> : <Outlet />
})

const App: FC = () => {
  return (
    <AppContext.Provider value={stores}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <BrowserRouter>
            <Routes>
              <Route element={<Common />}>
                <Route path="/" element={<AppLayout />}>
                  <Route element={<AuthRoute />}>
                    <Route path={routes.login} element={<Login />} />
                    <Route path={routes.registration} element={<Registration />} />
                  </Route>

                  <Route element={<PrivateRoute />}>
                    <Route index element={<Lobby />} />
                    <Route path={`${routes.roulette}/:rouletteGameId`} element={<Roulette />} />
                    <Route path={`${routes.slot}/:slotGameId`} element={<Slot />} />
                  </Route>
                </Route>

                <Route path={`${routes.slotClose}`} element={<SlotClose />} />
                <Route path="*" element={<NoMatch />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </AppContext.Provider>
  )
}

export default Sentry.withProfiler(App, {
  name: `${process.env.REACT_APP_TITLE}@${process.env.REACT_APP_VERSION}`,
})
