import styled from '@emotion/styled'
import { breakpoints, mediaQueries } from '../../core/theme'
import { LocationVideoType } from './index'

export const VideoContainer = styled.div<{
  locationVideo: LocationVideoType
}>`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;

  width: var(--app-height);
  height: calc(var(--app-width) - 20px);

  transform-origin: top left;
  transform: rotate(-90deg) translatex(-100%);

  align-items: center;
  justify-content: center;
  font-size: 0;
  background: black;
  overflow: hidden;

  ${mediaQueries.smallPortrait} {
    width: var(--app-width);
    height: calc(var(--app-height) - 20px);
  }

  ${mediaQueries.medium} {
    transform: none;
    transform-origin: initial;
    display: block;
    position: static;
    z-index: 1;
    font-size: 14px;
    width: 100%;
    height: 100%;
    background: none;
  }

  @media screen and (min-width: ${breakpoints[1]}px) {
    position: relative;

    video {
      position: absolute;
      top: ${(props) => (props.locationVideo === 'top' ? 0 : 'auto')};
      bottom: ${(props) => (props.locationVideo === 'top' ? 'auto' : 0)};
      left: 0;
    }
  }
`
