import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import rollbackIcon from './img/rollback.svg'
import { RoundBtn } from '../index'
import { useStore } from '../../stores'
import { mediaQueries } from '../../core/theme'

const StyledImg = styled.img`
  width: 12px;
  height: 10px;

  ${mediaQueries.medium} {
    width: 20.57px;
    height: 17.14px;
  }

  ${mediaQueries.large} {
    width: 25.71px;
    height: 21.43px;
  }
`

const RemoveLastBetBtn = observer((): JSX.Element => {
  const { rouletteStore } = useStore()
  const { betsStore, statusGameStore, betsService } = rouletteStore
  const disabled = betsStore.isEmptyHistoryBets || !statusGameStore.isBetting

  return (
    <RoundBtn onClick={betsService.removeLastBets} disabled={disabled}>
      <StyledImg src={rollbackIcon} alt="rollback" />
    </RoundBtn>
  )
})

export default RemoveLastBetBtn
