import styled from '@emotion/styled'

export const Svg = styled.svg``

export const CompleteIcon = (props: {
  active: boolean
  width?: number
  height?: number
}): JSX.Element => {
  const { active, width = 12, height = 10 } = props
  const color = active ? 'black' : 'white'

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.9 5.7253C7.9 6.07797 7.69952 6.40679 7.35619 6.65139C7.01307 6.89584 6.53391 7.05017 6 7.05017C5.4661 7.05017 4.98693 6.89584 4.64381 6.65139C4.30049 6.40679 4.1 6.07797 4.1 5.7253C4.1 5.37263 4.30049 5.04381 4.64381 4.79921C4.98693 4.55476 5.4661 4.40043 6 4.40043C6.53391 4.40043 7.01307 4.55476 7.35619 4.79921C7.69952 5.04381 7.9 5.37263 7.9 5.7253ZM3.9 8.57505C3.9 8.92772 3.69952 9.25654 3.35619 9.50114C3.01307 9.74559 2.5339 9.89992 2 9.89992C1.4661 9.89992 0.986931 9.74559 0.643811 9.50114C0.300484 9.25654 0.1 8.92772 0.1 8.57505C0.1 8.22237 0.300484 7.89355 0.643811 7.64895C0.986931 7.4045 1.4661 7.25017 2 7.25017C2.5339 7.25017 3.01307 7.4045 3.35619 7.64895C3.69952 7.89355 3.9 8.22237 3.9 8.57505ZM11.9 8.57505C11.9 8.92772 11.6995 9.25654 11.3562 9.50114C11.0131 9.74559 10.5339 9.89992 10 9.89992C9.4661 9.89992 8.98693 9.74559 8.64381 9.50114C8.30049 9.25654 8.1 8.92772 8.1 8.57505C8.1 8.22237 8.30049 7.89355 8.64381 7.64895C8.98693 7.4045 9.4661 7.25017 10 7.25017C10.5339 7.25017 11.0131 7.4045 11.3562 7.64895C11.6995 7.89355 11.9 8.22237 11.9 8.57505ZM11.9 2.87556C11.9 3.22823 11.6995 3.55705 11.3562 3.80165C11.0131 4.0461 10.5339 4.20043 10 4.20043C9.4661 4.20043 8.98693 4.0461 8.64381 3.80165C8.30049 3.55705 8.1 3.22823 8.1 2.87556C8.1 2.52288 8.30049 2.19406 8.64381 1.94946C8.98693 1.70501 9.4661 1.55068 10 1.55068C10.5339 1.55068 11.0131 1.70501 11.3562 1.94946C11.6995 2.19406 11.9 2.52288 11.9 2.87556ZM3.9 2.87556C3.9 3.22823 3.69952 3.55705 3.35619 3.80165C3.01307 4.0461 2.5339 4.20043 2 4.20043C1.4661 4.20043 0.986931 4.0461 0.643811 3.80165C0.300484 3.55705 0.1 3.22823 0.1 2.87556C0.1 2.52288 0.300484 2.19406 0.643811 1.94946C0.986931 1.70501 1.4661 1.55068 2 1.55068C2.5339 1.55068 3.01307 1.70501 3.35619 1.94946C3.69952 2.19406 3.9 2.52288 3.9 2.87556Z"
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M7.9 5.00021C7.9 5.35289 7.69952 5.68171 7.35619 5.92631C7.01307 6.17076 6.53391 6.32509 6 6.32509C5.4661 6.32509 4.98693 6.17076 4.64381 5.92631C4.30049 5.68171 4.1 5.35289 4.1 5.00021C4.1 4.64754 4.30049 4.31872 4.64381 4.07412C4.98693 3.82967 5.4661 3.67534 6 3.67534C6.53391 3.67534 7.01307 3.82967 7.35619 4.07412C7.69952 4.31872 7.9 4.64754 7.9 5.00021ZM3.9 7.84997C3.9 8.20265 3.69952 8.53147 3.35619 8.77606C3.01307 9.02051 2.5339 9.17484 2 9.17484C1.4661 9.17484 0.986931 9.02051 0.643811 8.77606C0.300484 8.53147 0.1 8.20265 0.1 7.84997C0.1 7.4973 0.300484 7.16848 0.643811 6.92388C0.986931 6.67943 1.4661 6.5251 2 6.5251C2.5339 6.5251 3.01307 6.67943 3.35619 6.92388C3.69952 7.16848 3.9 7.4973 3.9 7.84997ZM11.9 7.84997C11.9 8.20265 11.6995 8.53147 11.3562 8.77606C11.0131 9.02051 10.5339 9.17484 10 9.17484C9.4661 9.17484 8.98693 9.02051 8.64381 8.77606C8.30049 8.53147 8.1 8.20265 8.1 7.84997C8.1 7.4973 8.30049 7.16848 8.64381 6.92388C8.98693 6.67943 9.4661 6.5251 10 6.5251C10.5339 6.5251 11.0131 6.67943 11.3562 6.92388C11.6995 7.16848 11.9 7.4973 11.9 7.84997ZM11.9 2.15046C11.9 2.50313 11.6995 2.83195 11.3562 3.07655C11.0131 3.321 10.5339 3.47533 10 3.47533C9.4661 3.47533 8.98693 3.321 8.64381 3.07655C8.30049 2.83195 8.1 2.50313 8.1 2.15046C8.1 1.79778 8.30049 1.46896 8.64381 1.22437C8.98693 0.979915 9.4661 0.825586 10 0.825586C10.5339 0.825586 11.0131 0.979915 11.3562 1.22437C11.6995 1.46896 11.9 1.79778 11.9 2.15046ZM3.9 2.15046C3.9 2.50313 3.69952 2.83195 3.35619 3.07655C3.01307 3.321 2.5339 3.47533 2 3.47533C1.4661 3.47533 0.986931 3.321 0.643811 3.07655C0.300484 2.83195 0.1 2.50313 0.1 2.15046C0.1 1.79778 0.300484 1.46896 0.643811 1.22437C0.986931 0.979915 1.4661 0.825586 2 0.825586C2.5339 0.825586 3.01307 0.979915 3.35619 1.22437C3.69952 1.46896 3.9 1.79778 3.9 2.15046Z"
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M6 5.6995C7.10457 5.6995 8 5.06156 8 4.27463C8 3.48769 7.10457 2.84976 6 2.84976C4.89543 2.84976 4 3.48769 4 4.27463C4 5.06156 4.89543 5.6995 6 5.6995Z"
        fill={color}
      />
      <path
        d="M2 8.54926C3.10457 8.54926 4 7.91132 4 7.12439C4 6.33745 3.10457 5.69951 2 5.69951C0.895431 5.69951 0 6.33745 0 7.12439C0 7.91132 0.895431 8.54926 2 8.54926Z"
        fill={color}
      />
      <path
        d="M10 8.54926C11.1046 8.54926 12 7.91132 12 7.12439C12 6.33745 11.1046 5.69951 10 5.69951C8.89543 5.69951 8 6.33745 8 7.12439C8 7.91132 8.89543 8.54926 10 8.54926Z"
        fill={color}
      />
      <path
        d="M10 2.84974C11.1046 2.84974 12 2.21181 12 1.42487C12 0.637937 11.1046 0 10 0C8.89543 0 8 0.637937 8 1.42487C8 2.21181 8.89543 2.84974 10 2.84974Z"
        fill={color}
      />
      <path
        d="M2 2.84974C3.10457 2.84974 4 2.21181 4 1.42487C4 0.637937 3.10457 0 2 0C0.895431 0 0 0.637937 0 1.42487C0 2.21181 0.895431 2.84974 2 2.84974Z"
        fill={color}
      />
    </Svg>
  )
}
