import styled from '@emotion/styled'

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`

export const Container = styled.form`
  display: block;
  background: #0b1b3a;
  box-shadow: 0 40px 20px -10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 20px 124px;
  position: fixed;
`

export const Close = styled.div`
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
`

export const ContainerInside = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
`
