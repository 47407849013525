import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { differenceInMilliseconds } from 'date-fns'
import security, { ILoginPayload, ILoginResponse, ISecretCodeResponse, ITokenData } from '../api/security'
import storage, { STORAGE_KEYS } from '../services/storage'
import { getTimestampTokenExpired } from '../utils/date'

export enum LoginProcessStatus {
  'LOGIN',
  'SMS',
  'SUCCESS',
}

export class LoginStore {
  processStatus: LoginProcessStatus = LoginProcessStatus.LOGIN

  secretCode: ISecretCodeResponse | null = null

  user: ILoginResponse['player'] | null = null

  accessToken: string = ''

  refreshToken: string = ''

  tokenLife: number = 0

  timerRefreshToken: NodeJS.Timeout | undefined

  constructor() {
    makeAutoObservable(this, {
      timerRefreshToken: false,
    })

    if (storage.has(STORAGE_KEYS.TOKEN)) {
      const tokenData = storage.get(STORAGE_KEYS.TOKEN)
      this.setTokenData(tokenData)

      const timeExpiredToken = storage.get(STORAGE_KEYS.TIME_EXPIRED_TOKEN)

      ;(async () => {
        if (differenceInMilliseconds(timeExpiredToken, Date.now()) > 0) {
          this.timerRefreshToken = setTimeout(async () => {
            await this.getRefreshToken()
          }, differenceInMilliseconds(timeExpiredToken, Date.now()))
        } else {
          await this.getRefreshToken()
        }
      })()
    }

    if (storage.has(STORAGE_KEYS.USER)) {
      const userData = storage.get(STORAGE_KEYS.USER) as ILoginResponse['player']
      this.setUserData(userData)
    }
  }

  login = async (data: ILoginPayload): Promise<void> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const code = await security.login(data)

      runInAction(() => {
        this.secretCode = code
        this.processStatus = LoginProcessStatus.SMS
      })
    } catch (e) {
      throw e
    }
  }

  getToken = async (sms: string): Promise<ILoginResponse['player']> => {
    try {
      if (!this.secretCode) throw new Error('Missing secret code')

      const loginData = await security.token({
        authorization_code: sms,
        secret_code: this.secretCode.secret_code,
      })

      const tokenData: ITokenData = {
        access_token: loginData.access_token,
        refresh_token: loginData.refresh_token,
        token_life: loginData.token_life,
        token_type: loginData.token_type,
      }
      const userData: ILoginResponse['player'] = loginData.player
      const timestampTokenExpired = getTimestampTokenExpired(tokenData.token_life)

      storage.set(STORAGE_KEYS.TOKEN, tokenData)
      storage.set(STORAGE_KEYS.USER, userData)
      storage.set(STORAGE_KEYS.TIME_EXPIRED_TOKEN, timestampTokenExpired)

      runInAction(() => {
        this.setTokenData(tokenData)
        this.setUserData(userData)

        this.secretCode = null
        this.processStatus = LoginProcessStatus.SUCCESS
      })

      this.timerRefreshToken = setTimeout(async () => {
        await this.getRefreshToken()
      }, differenceInMilliseconds(timestampTokenExpired, Date.now()))

      return {
        ...loginData.player,
      }
    } catch (e) {
      const err = e as AxiosError
      console.error(err.response?.data)
      throw e
    }
  }

  getRefreshToken = async (): Promise<void> => {
    try {
      const localTokenData = storage.get(STORAGE_KEYS.TOKEN) as ITokenData

      if (!localTokenData) throw new Error()

      const tokenData = await security.refreshToken({
        refresh_token: localTokenData.refresh_token,
      })
      const timestampTokenExpired = getTimestampTokenExpired(tokenData.token_life)

      storage.set(STORAGE_KEYS.TOKEN, tokenData)
      storage.set(STORAGE_KEYS.TIME_EXPIRED_TOKEN, timestampTokenExpired)

      runInAction(() => {
        this.setTokenData(tokenData)
      })

      this.timerRefreshToken = setTimeout(async () => {
        await this.getRefreshToken()
      }, differenceInMilliseconds(timestampTokenExpired, Date.now()))
    } catch (e) {
      await this.logout()
    }
  }

  logout = async (): Promise<void> => {
    const { refreshToken } = this

    const token = refreshToken

    this.clear()

    await security.revokeToken({
      token,
    })
  }

  setProcessedStatus = (data: LoginProcessStatus): void => {
    this.processStatus = data
  }

  get isAuthenticated(): boolean {
    return Boolean(this.user)
  }

  clear = (): void => {
    storage.remove(STORAGE_KEYS.TOKEN)
    storage.remove(STORAGE_KEYS.USER)
    storage.remove(STORAGE_KEYS.TIME_EXPIRED_TOKEN)

    if (this.timerRefreshToken) clearTimeout(this.timerRefreshToken)

    this.processStatus = LoginProcessStatus.LOGIN
    this.secretCode = null
    this.accessToken = ''
    this.refreshToken = ''
    this.tokenLife = 0

    this.setUserData(null)
  }

  reset = (): void => {
    this.processStatus = LoginProcessStatus.LOGIN
    this.secretCode = null
  }

  setTokenData = (data: ITokenData): void => {
    this.accessToken = data.access_token
    this.refreshToken = data.refresh_token
    this.tokenLife = data.token_life
  }

  setAccessToken = (data: string): void => {
    this.accessToken = data
  }

  setRefreshToken = (data: string): void => {
    this.refreshToken = data
  }

  setTokenLife = (data: number): void => {
    this.tokenLife = data
  }

  setUserData = (data: ILoginResponse['player'] | null): void => {
    this.user = data
  }
}
