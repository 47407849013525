import { makeAutoObservable } from 'mobx'

class TimerStore {
  timer: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  setTimer = (data: number): void => {
    this.timer = data
  }
}

export default TimerStore
