const routes = {
  login: '/login',
  registration: '/registration',
  restorePassword: '/restore-password',
  lobby: '/',
  roulette: '/roulette',
  slot: '/slot',
  slotClose: '/slot-close',
}

export default routes
