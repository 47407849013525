import { Theme } from '@emotion/react'
import { createBreakpoint } from 'react-use'

export const breakpoints = [320, 1024, 1440, 1920]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]}px)`,
  smallPortrait: `@media screen and (max-width: ${breakpoints[1]}px) and (orientation: portrait)`,
  medium: `@media screen and (min-width: ${breakpoints[1]}px)`,
  large: `@media screen and (min-width: ${breakpoints[2]}px)`,
  big: `@media screen and (min-width: ${breakpoints[3]}px)`,
}

type Breakpoints = 'small' | 'medium' | 'large' | 'big'

export const useBreakpoint = createBreakpoint({
  small: breakpoints[0],
  medium: breakpoints[1],
  large: breakpoints[2],
  big: breakpoints[3],
}) as unknown as () => Breakpoints

export const maxContainerWidth = '1060px'

export const theme: Theme = {
  colors: {
    var1: '#fff',
    var2: '#121722',
    var3: '#ff9a3d',
    error: '#FF4646',
  },
  borderRadius: 4,
}
