import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import mainFieldIcon from './img/main-small.svg'
import trackIcon from './img/track-small.svg'
import { useStore } from '../../stores'

const Container = styled.div`
  display: flex;
  width: 140px;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
`

const FieldSwitcher = observer((): JSX.Element => {
  const { rouletteStore } = useStore()
  const { uiStore } = rouletteStore
  const isMain = uiStore.mobileFieldView === 'main'
  const isTrack = uiStore.mobileFieldView === 'track'

  return (
    <Container
      onClick={(e) => {
        e.preventDefault()
        uiStore.setMobileFieldView(isMain ? 'track' : 'main')
      }}>
      {isMain && <img src={trackIcon} alt="" />}
      {isTrack && <img src={mainFieldIcon} alt="" />}
    </Container>
  )
})

export default FieldSwitcher
