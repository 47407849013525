import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import routes from '../../core/routes'

const SlotClose: FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (window === top) {
      navigate(routes.lobby)
    } else {
      window.parent.postMessage('closeGame', '*')
    }
  }, [])
  return <div>Close game...</div>
}

export default SlotClose
