import styled from '@emotion/styled'
import { maxContainerWidth, mediaQueries } from '../../core/theme'

export const WrapperListGames = styled.div`
  padding: 0 20px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  ${mediaQueries.medium} {
    padding: 0 70px;
  }

  ${mediaQueries.large} {
    display: flex;
    justify-content: center;
  }
`

export const WrapperInsideListGames = styled.div`
  ${mediaQueries.large} {
    width: 100%;
    max-width: ${maxContainerWidth};
  }
`

export const SectionNameListGames = styled.div`
  font-size: 18px;
  color: #fff;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ListGamesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  img {
    width: 100%;
    height: auto;
  }

  ${mediaQueries.medium} {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
`
