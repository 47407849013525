import { NameFieldBets } from '../services/nameFieldBets'

export const sideCells: {
  [k in NameFieldBets]?: {
    left: NameFieldBets
    bottomLeft: NameFieldBets
    bottom: NameFieldBets
  }
} = {
  'field:1': {
    left: 'split:0:1',
    bottomLeft: 'corner:0:1:2:3',
    bottom: 'street:1:2:3',
  },
  'field:2': {
    left: 'split:0:2',
    bottomLeft: 'street:0:1:2',
    bottom: 'split:1:2',
  },
  'field:3': {
    left: 'split:0:3',
    bottomLeft: 'street:0:2:3',
    bottom: 'split:2:3',
  },
  'field:4': {
    left: 'split:1:4',
    bottomLeft: 'sixline:1:2:3:4:5:6',
    bottom: 'street:4:5:6',
  },
  'field:5': {
    left: 'split:2:5',
    bottomLeft: 'corner:1:2:4:5',
    bottom: 'split:4:5',
  },
  'field:6': {
    left: 'split:3:6',
    bottomLeft: 'corner:2:3:5:6',
    bottom: 'split:5:6',
  },
  'field:7': {
    left: 'split:4:7',
    bottomLeft: 'sixline:4:5:6:7:8:9',
    bottom: 'street:7:8:9',
  },
  'field:8': {
    left: 'split:5:8',
    bottomLeft: 'corner:4:5:7:8',
    bottom: 'split:7:8',
  },
  'field:9': {
    left: 'split:6:9',
    bottomLeft: 'corner:5:6:8:9',
    bottom: 'split:8:9',
  },
  'field:10': {
    left: 'split:7:10',
    bottomLeft: 'sixline:7:8:9:10:11:12',
    bottom: 'street:10:11:12',
  },
  'field:11': {
    left: 'split:8:11',
    bottomLeft: 'corner:7:8:10:11',
    bottom: 'split:10:11',
  },
  'field:12': {
    left: 'split:9:12',
    bottomLeft: 'corner:8:9:11:12',
    bottom: 'split:11:12',
  },
  'field:13': {
    left: 'split:10:13',
    bottomLeft: 'sixline:10:11:12:13:14:15',
    bottom: 'street:13:14:15',
  },
  'field:14': {
    left: 'split:11:14',
    bottomLeft: 'corner:10:11:13:14',
    bottom: 'split:13:14',
  },
  'field:15': {
    left: 'split:12:15',
    bottomLeft: 'corner:11:12:14:15',
    bottom: 'split:14:15',
  },
  'field:16': {
    left: 'split:13:16',
    bottomLeft: 'sixline:13:14:15:16:17:18',
    bottom: 'street:16:17:18',
  },
  'field:17': {
    left: 'split:14:17',
    bottomLeft: 'corner:13:14:16:17',
    bottom: 'split:16:17',
  },
  'field:18': {
    left: 'split:15:18',
    bottomLeft: 'corner:14:15:17:18',
    bottom: 'split:17:18',
  },
  'field:19': {
    left: 'split:16:19',
    bottomLeft: 'sixline:16:17:18:19:20:21',
    bottom: 'street:19:20:21',
  },
  'field:20': {
    left: 'split:17:20',
    bottomLeft: 'corner:16:17:19:20',
    bottom: 'split:19:20',
  },
  'field:21': {
    left: 'split:18:21',
    bottomLeft: 'corner:17:18:20:21',
    bottom: 'split:20:21',
  },
  'field:22': {
    left: 'split:19:22',
    bottomLeft: 'sixline:19:20:21:22:23:24',
    bottom: 'street:22:23:24',
  },
  'field:23': {
    left: 'split:20:23',
    bottomLeft: 'corner:19:20:22:23',
    bottom: 'split:22:23',
  },
  'field:24': {
    left: 'split:21:24',
    bottomLeft: 'corner:20:21:23:24',
    bottom: 'split:23:24',
  },
  'field:25': {
    left: 'split:22:25',
    bottomLeft: 'sixline:22:23:24:25:26:27',
    bottom: 'street:25:26:27',
  },
  'field:26': {
    left: 'split:23:26',
    bottomLeft: 'corner:22:23:25:26',
    bottom: 'split:25:26',
  },
  'field:27': {
    left: 'split:24:27',
    bottomLeft: 'corner:23:24:26:27',
    bottom: 'split:26:27',
  },
  'field:28': {
    left: 'split:25:28',
    bottomLeft: 'sixline:25:26:27:28:29:30',
    bottom: 'street:28:29:30',
  },
  'field:29': {
    left: 'split:26:29',
    bottomLeft: 'corner:25:26:28:29',
    bottom: 'split:28:29',
  },
  'field:30': {
    left: 'split:27:30',
    bottomLeft: 'corner:26:27:29:30',
    bottom: 'split:29:30',
  },
  'field:31': {
    left: 'split:28:31',
    bottomLeft: 'sixline:28:29:30:31:32:33',
    bottom: 'street:31:32:33',
  },
  'field:32': {
    left: 'split:29:32',
    bottomLeft: 'corner:28:29:31:32',
    bottom: 'split:31:32',
  },
  'field:33': {
    left: 'split:30:33',
    bottomLeft: 'corner:29:30:32:33',
    bottom: 'split:32:33',
  },
  'field:34': {
    left: 'split:31:34',
    bottomLeft: 'sixline:31:32:33:34:35:36',
    bottom: 'street:34:35:36',
  },
  'field:35': {
    left: 'split:32:35',
    bottomLeft: 'corner:31:32:34:35',
    bottom: 'split:34:35',
  },
  'field:36': {
    left: 'split:33:36',
    bottomLeft: 'corner:32:33:35:36',
    bottom: 'split:35:36',
  },
}
