import { makeAutoObservable } from 'mobx'

export type Limits = {
  status: number // 200
  name: string // 'for rul'
  minimum: number // 10
  maximum: number // 10000
  maximumPayout: null
  nameGame: string // 'roulette'
  minBetColDozen: number // 5
  maxBetColDozen: number // 50000
  minBetEvenChance: number // 5
  maxBetEvenChance: number // 50000
}

class LimitsStore {
  limits: Limits | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setLimits = (data: Limits): Limits => {
    this.limits = data

    return this.limits
  }
}

export default LimitsStore
