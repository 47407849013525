import { makeAutoObservable } from 'mobx'

export default class UserStore {
  name: string = ''

  constructor() {
    makeAutoObservable(this)
  }

  setName = (data: string): string => {
    this.name = data

    return this.name
  }
}
