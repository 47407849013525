export const colorsFieldRoulette = {
  black: [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],
  green: [0],
  red: [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36],
}

export const getColorField = (data: number): 'black' | 'red' | 'green' => {
  if (colorsFieldRoulette.red.includes(data)) return 'red'
  if (colorsFieldRoulette.black.includes(data)) return 'black'

  return 'green'
}
