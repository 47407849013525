import styled from '@emotion/styled'
import { borderMainField } from '../MainRouletteField/styles'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  border-right: ${borderMainField};
  border-bottom: ${borderMainField};
`

export const StyledNumberCell = styled.div<{
  lightning?: boolean
  disabled: boolean
}>`
  display: flex;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${(props) => (props.lightning ? 'rgba(255, 255, 255, 0.4)' : 'none')};
    z-index: -1;
  }

  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`

export const WinBetContainer = styled.div`
  display: flex;
  top: 0;
  left: -2px;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
`

export const HoverCell = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
