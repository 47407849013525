import styled from '@emotion/styled'
import { mediaQueries } from '../../core/theme'

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
`

export const Btn = styled.div`
  margin-right: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: rgba(255, 255, 255, 0.2);
`

export const TextContainer = styled.div`
  font-size: 12px;
  display: flex;

  img {
    width: 12px;
  }

  &:not(:last-of-type) {
    margin-bottom: 3px;
  }

  ${mediaQueries.medium} {
    font-size: 14px;

    img {
      width: 14px;
    }
  }

  ${mediaQueries.large} {
    font-size: 16px;

    img {
      width: 16px;
    }
  }
`

export const BalanceTxt = styled.span``

export const BalanceNumber = styled.span`
  display: inline-block;
  padding: 0 4px;
`

export const TxtContainers = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const Paid = styled.div`
  margin-left: 8px;
  text-transform: lowercase;
`
