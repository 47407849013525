import { makeAutoObservable } from 'mobx'

class PayStore {
  pay: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  setPay = (data: number): number => {
    this.pay = data

    return this.pay
  }
}

export default PayStore
