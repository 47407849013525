import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import security, {
  IRegistrationCodePayload,
  IRegistrationCodeResponse,
  IRegistrationPasswordPayload,
  IRegistrationPasswordResponse,
} from '../api/security'

export enum RegistrationProcessStatus {
  'SMS',
  'ADD_USER',
  'SUCCESS',
}

export class RegistrationStore {
  processStatus: RegistrationProcessStatus = RegistrationProcessStatus.SMS

  registrationCode: IRegistrationCodeResponse | null = null

  registrationPassword: IRegistrationPasswordResponse | null = null

  constructor() {
    makeAutoObservable(this)
  }

  getRegistrationCode = async (
    data: IRegistrationCodePayload
  ): Promise<void> => {
    try {
      const registrationCode = await security.registrationCode(data)

      runInAction(() => {
        this.registrationCode = registrationCode
        this.processStatus = RegistrationProcessStatus.ADD_USER
      })
    } catch (e) {
      const error = e as AxiosError
      console.error(error.message)
      throw error
    }
  }

  registrationNewUser = async (
    data: Omit<IRegistrationPasswordPayload, 'secret_code'>
  ): Promise<void> => {
    if (!this.registrationCode) return

    try {
      if (!this.registrationCode) throw new Error('Missing registration code')

      const registrationPassword = await security.registrationPassword({
        ...data,
        secret_code: this.registrationCode.secret_code,
      })

      runInAction(() => {
        this.registrationPassword = registrationPassword
        this.processStatus = RegistrationProcessStatus.SUCCESS
      })
    } catch (e) {
      const error = e as AxiosError
      console.error(error.message)
      throw error
    }
  }

  reset = (): void => {
    this.processStatus = RegistrationProcessStatus.SMS
    this.registrationCode = null
    this.registrationPassword = null
  }
}
