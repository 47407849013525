import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const RouteLink = styled(Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffc13a;
  text-decoration: none;
`

export default RouteLink
