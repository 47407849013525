import styled from '@emotion/styled'
import { ChipCellProps } from './index'
import { mediaQueries } from '../../core/theme'

export const StyledImg = styled.img<Pick<ChipCellProps, 'transparent'>>`
  opacity: ${(props) => (props.transparent ? 0.7 : 1)};
  display: block;
  width: 30px;
  height: 30px;

  ${mediaQueries.medium} {
    width: 36px;
    height: 36px;
  }

  ${mediaQueries.large} {
    width: 48px;
    height: 48px;
  }
`

export const Container = styled.div`
  position: relative;
`

export const Value = styled.div`
  font-size: 8px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  width: 100%;
  height: 100%;

  ${mediaQueries.medium} {
    font-size: 12px;
  }

  ${mediaQueries.large} {
    font-size: 14px;
  }
`
