import styled from '@emotion/styled'
import icon from './img/share.svg'
import { mediaQueries } from '../../core/theme'

const Icon = styled.img`
  display: block;
  width: 20px;

  ${mediaQueries.medium} {
    width: 25px;
  }

  ${mediaQueries.big} {
    width: 27px;
  }

  ${mediaQueries.large} {
    width: 30px;
  }
`

const WinningShare = (): JSX.Element => {
  return <Icon src={icon} alt="share" />
}

export default WinningShare
