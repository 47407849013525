import { makeAutoObservable } from 'mobx'
import { forEach } from 'lodash'
import { SubscribeGamesEventResponse } from '../../services/RouletteEvents'

export default class SubscribeGamesStore {
  games: Map<string, SubscribeGamesEventResponse> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  setGames = (data: Array<SubscribeGamesEventResponse>): void => {
    forEach(data, (item) => {
      this.games.set(item.id, { ...item })
    })
  }
}
