import { makeAutoObservable, when } from 'mobx'
import { WebRTCAdaptor } from '../services/antMedia'

export type PlayStateStream =
  | 'play_initial'
  | 'play_pending'
  | 'play_started' // called when a stream start playing
  | 'play_finished' // called when a stream has finished playing

export type WebrtcInfoCallbackName =
  | PlayStateStream
  | 'initialized' // called by JavaScript SDK when WebSocket is connected.
  | 'newStreamAvailable' // called when client is ready to play WebRTC stream
  | 'closed' // called when websocket connection is closed
  | 'streamInformation' // called when a stream information is received from the server
  | 'trackList' // called when a stream has finished playing
  | 'publish_started'
  | 'publish_finished'
  | 'screen_share_extension_available'
  | 'screen_share_stopped'
  | 'pong'
  | 'refreshConnection'
  | 'ice_connection_state_changed'
  | 'updated_stats'
  | 'bitrateMeasurement'

export enum StatusSocketAntMedia {
  INITIAL = 'initial', // not started
  PENDING = 'pending', // wait connection
  CONNECTED = 'connected', // connected
  CLOSED = 'closed',
}

/*
 * TODO: Была попытка реализации мультрека в одном <video />
 * Для того чтобы вопроизвести субпотоки необходимо:
 * - узнать информацию о стриме получение списка субпотоков
 *   сделать это можно либо через REST метод src/api/antMedia/broadcast/getInfoAboutStream.ts
 *   либо через ws командой методом getTracks у класса WebRTCAdaptor
 * - Если желаемого субпотока нету мы добавляем его с помощью REST метода
 *   src/api/antMedia/broadcast/addSubTrackToMainTrack.ts
 * - Далее происходит активация субпотоков с помощью метода enableTrack у класса WebRTCAdaptor
 * - Вызываем команду play и передаем массив субпотоков
 *   если стоит ! перед id sub stream значит этот субпоток не будет воспрозводиться
 *   пример ['test', '!test1', 'test2']
 *
 * Во время реализации было использван подход, что в активный стрим добавляются под потоки,
 * но при этом активный стрим всегда пропадал, было решено сделать так: активный стрим это выключенный стрим
 * как бы существующий просто формально
 *
 * В ходе реалиции не было существенного прироста скорости переключения и возможно идея делать в одном <video />
 * не была заложенна в antMedia ибо в их демке подгружается count subTracks = <video />
 *  */

export class WebrtcStreamStore {
  websocketURL = `wss://${process.env.REACT_APP_ANT_URL}/WebRTCAppEE/websocket`

  statusSocket: StatusSocketAntMedia = StatusSocketAntMedia.INITIAL

  peerConnectionConfig = {
    iceServers: [
      {
        urls: process.env.REACT_APP_STUN_URL,
      },
    ],
  }

  error: any = null

  sdpConstraints = {
    OfferToReceiveAudio: true,
    OfferToReceiveVideo: true,
  }

  mediaConstraints = {
    audio: false,
    video: false,
  }

  adaptor: WebRTCAdaptor | null = null

  streamElementId: string = '' // id элемента в DOM

  subTracksId: Array<string> = []

  trackList: Map<string, Array<string>> = new Map() // response sub tracks under to main track

  streamId: string = '' // id stream

  playStateStream: PlayStateStream | 'not_started' = 'not_started' // state main track playing stream

  constructor() {
    makeAutoObservable(this)

    this.setStreamElementId('stream')
  }

  get isConnected(): boolean {
    return this.statusSocket === StatusSocketAntMedia.CONNECTED
  }

  get isInitial(): boolean {
    return this.statusSocket === StatusSocketAntMedia.INITIAL
  }

  connectSocket = (): void => {
    if (!this.isInitial) return

    const self = this

    this.setStatusSocket(StatusSocketAntMedia.PENDING)

    this.adaptor = new WebRTCAdaptor({
      websocket_url: this.websocketURL,
      mediaConstraints: this.mediaConstraints,
      peerconnection_config: this.peerConnectionConfig,
      sdp_constraints: this.sdpConstraints,
      remoteVideoId: null,
      isPlayMode: true,
      debug: false,
      candidateTypes: ['tcp', 'udp'],
      callback: (info: WebrtcInfoCallbackName, obj: any) => {
        if (info === 'pong' || info === 'bitrateMeasurement') return

        switch (info) {
          case 'initialized':
            this.setStatusSocket(StatusSocketAntMedia.CONNECTED)

            self.adaptor?.getTracks('main')

            break
          case 'play_started':
            self.setPlayState(obj.streamId, 'play_started')
            break
          case 'play_finished':
            self.setPlayState(obj.streamId, 'play_finished')
            break
          case 'closed':
            this.setStatusSocket(StatusSocketAntMedia.CLOSED)
            break
          case 'trackList':
            this.setTrackList(obj.streamId, obj.trackList)
            break
          default:
          // Do nothing
        }
      },
      callbackError(error: any) {
        // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        self.setError(error)
      },
    })
  }

  setTrackList = (streamId: string, trackList: Array<string>): void => {
    console.log('setTrackList', streamId, trackList)
    // nobody
  }

  setPlayState = (streamId: string, status: PlayStateStream): void => {
    this.playStateStream = status
  }

  setSubTracksId = (data: Array<string>): void => {
    this.subTracksId = [...data]
  }

  setStreamElementId = (data: string): void => {
    if (this.streamElementId) return

    this.streamElementId = data

    this.adaptor?.setIdElementRemoteVideo(data)
  }

  play = (streamId: string): void => {
    if (!this.streamElementId) return

    if (!this.isConnected) {
      when(
        () => this.isConnected,
        () => {
          this.adaptor?.play(this.streamElementId, streamId, '')
        }
      )
    } else {
      this.adaptor?.play(this.streamElementId, streamId, '')
    }
  }

  stop = (streamId: string): void => {
    if (this.playStateStream === 'play_started') this.adaptor?.stop(streamId)
  }

  setError = (data: any): void => {
    this.error = data
  }

  setStatusSocket = (data: StatusSocketAntMedia): void => {
    this.statusSocket = data
  }

  enableTrack = (mainTrack: string, track: string, enabled = true): void => {
    this.adaptor?.enableTrack(mainTrack, track, enabled)
  }
}
