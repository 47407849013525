import { makeAutoObservable } from 'mobx'

export const sessionIdUnknownStatus = -1

export default class SessionIdStore {
  id: number = sessionIdUnknownStatus

  constructor() {
    makeAutoObservable(this)
  }

  setId = (data: number): number => {
    this.id = data

    return this.id
  }
}
