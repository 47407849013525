import { createContext, useContext } from 'react'

import { ApplicationStore } from './Application.store'
import { RegistrationStore } from './Registration.store'
import { LoginStore } from './Login.store'
import { ListGamesStore } from './ListGames.store'
import { UIStore } from './UI.store'
import { ChipsetsStore } from './Chipsets.store'
import { WebrtcStreamStore } from './WebrtcStream.store'
import { HttpStore } from './Http.store'
import { NotificationStore } from './Notification.store'
import { RouletteStore } from './Roulette'
import { SentryStore } from './Sentry.store'
import { FavoriteSlotListGamesStore } from './FavoriteSlotListGames.store'

export const loginStore = new LoginStore()
export const notificationStore = new NotificationStore()
export const registrationStore = new RegistrationStore()
export const listGamesStore = new ListGamesStore()
export const uiStore = new UIStore()
export const webrtcStreamStore = new WebrtcStreamStore()
export const chipsetsStore = new ChipsetsStore()
export const rouletteStore = new RouletteStore()
export const favoriteSlotListGamesStore = new FavoriteSlotListGamesStore()
export const httpStore = new HttpStore(loginStore)
export const applicationStore = new ApplicationStore(loginStore, listGamesStore, webrtcStreamStore)
export const sentryStore = new SentryStore()

export const stores = {
  uiStore,
  notificationStore,
  loginStore,
  registrationStore,
  listGamesStore,
  webrtcStreamStore,
  chipsetsStore,
  rouletteStore,
  favoriteSlotListGamesStore,
  httpStore,
  applicationStore,
  sentryStore,
}

export const AppContext = createContext(stores)

export const useStore = (): typeof stores => useContext(AppContext) as typeof stores

export default stores
