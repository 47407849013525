import { isEmpty } from 'lodash'
import { io, Socket } from 'socket.io-client'
import { ECurrency } from '@lbs/currency'
import storage from '../services/storage'

export const SOCKET_COVER_EVENTS = {
  CONNECT_ERROR: 'connect_error',
  DISCONNECT: 'disconnect',
  SOME_TAB: 'someTab',
  BALANCE: 'balance',
  RELOAD: 'reload',
  ANOTHER_AUTH: 'anotherAuthentications',
  DISC_AND_LOGOUT: 'disconnectAndLogout',
  CREATE_GAME: 'createGame',
  CHANGE_STATUS_GAME: 'changeStatusGame',
  VERSION_UPD: 'versionUpd',
  VERSION: 'version',
}

export type BalanceEventResponse = {
  // общий баланс
  balance: number
  availableBalance: number
  currency: ECurrency
  allBetsInGames: {
    [k in string]: number
  }
  allGamesDeposits: {
    [k in string]: number
  }
  rouletteRatio: number
  playerId: string
}

export type ChangeStatusGameResponse = {
  gameSettings: {
    game_name: string // "roulette"
    game_type: string // "public"
    limitId: string // "5987-a7da-418a-9300"
    id: string // "603c-191c-57db-6a07"
    status: number // 250
  }
  status: number
  id: string
}

export class SocketCover {
  io: Socket | null

  accessToken: string = ''

  constructor(accessToken: string) {
    this.accessToken = accessToken

    this.io = io(`${process.env.REACT_APP_API_URL}/cover`, {
      query: {
        accessToken: this.accessToken,
      },
      secure: true,
      transports: ['websocket'],
      forceNew: true,
    })

    this.io.on(SOCKET_COVER_EVENTS.CONNECT_ERROR, (data: any) => {
      console.log('.EVENTS.CONNECT_ERROR', data)
    })
    this.io.on(SOCKET_COVER_EVENTS.DISCONNECT, (data: any) => {
      console.log('.EVENTS.DISCONNECT', data)
    })

    this.io.on(SOCKET_COVER_EVENTS.SOME_TAB, (data: any) => {
      console.log('.EVENTS.SOME_TAB', data)
    })
    // <-->
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.io.on(SOCKET_COVER_EVENTS.BALANCE, (data: BalanceEventResponse) => {
      // this.setBalance(data)
    })
    // <-->
    this.io.on(SOCKET_COVER_EVENTS.RELOAD, (data: any) => {
      console.log('.EVENTS.RELOAD', data)
    })
    this.io.on(SOCKET_COVER_EVENTS.ANOTHER_AUTH, (data: any) => {
      console.log('.EVENTS.ANOTHER_AUTH', data)
      // this.loginStore?.logout()
    })
    this.io.on(SOCKET_COVER_EVENTS.DISC_AND_LOGOUT, () => {
      // this.loginStore?.logout()
    })
    this.io.on(SOCKET_COVER_EVENTS.CREATE_GAME, () => {
      // console.log('.EVENTS.CREATE_GAME', data)
    })
    this.io.on(SOCKET_COVER_EVENTS.CHANGE_STATUS_GAME, () => {
      // console.log('.EVENTS.CHANGE_STATUS_GAME', data)
    })
    this.io.on(SOCKET_COVER_EVENTS.VERSION_UPD, (data: any) => {
      console.log('.EVENTS.VERSION_UPD', data)
    })
    this.io.on(SOCKET_COVER_EVENTS.VERSION, (data: { version: string }) => {
      storage.set('version', data.version)
    })
  }

  destroy = (): void => {
    if (isEmpty(this.io)) return

    this.io.removeAllListeners()
    this.io.disconnect()

    this.io = null
  }
}
