import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { RoundBtn } from '../index'
import { mediaQueries } from '../../core/theme'
import volumeMuteIcon from './img/volume-mute.svg'
import volumeIcon from './img/volume.svg'
import { useStore } from '../../stores'

const StyledImg = styled.img`
  width: 16px;
  height: 16px;

  ${mediaQueries.medium} {
    width: 21px;
    height: 21px;
  }

  ${mediaQueries.large} {
    width: 26px;
    height: 26px;
  }
`

const SoundBtn = observer(() => {
  const { rouletteStore, uiStore } = useStore()
  const { volumeStore } = rouletteStore

  return (
    <RoundBtn>
      <StyledImg
        src={volumeStore.isMuted ? volumeMuteIcon : volumeIcon}
        alt="volume"
        onClick={(e) => {
          e.preventDefault()
          if (uiStore.isTouchDevice) {
            volumeStore.setToggleMuted()
          } else {
            rouletteStore.uiStore.setIsShowVolumePopup(true)
          }
        }}
      />
    </RoundBtn>
  )
})

export default SoundBtn
