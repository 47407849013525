import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import exitIcon from './img/exit.svg'
import { RoundBtn } from '../index'
import { mediaQueries } from '../../core/theme'
import routes from '../../core/routes'

const StyledRoundBtn = styled(RoundBtn)`
  ${mediaQueries.medium} {
    margin: 0;
  }
`

const StyledImg = styled.img`
  width: 12px;
  height: 12px;

  ${mediaQueries.medium} {
    width: 17px;
    height: 17px;
  }

  ${mediaQueries.large} {
    width: 21px;
    height: 21px;
  }
`

type ExitGameBtnProps = {
  onExit?: () => void
}

const ExitGameBtn: FC<ExitGameBtnProps> = observer((props) => {
  const { onExit } = props
  const navigate = useNavigate()

  return (
    <StyledRoundBtn
      onClick={(e) => {
        e.preventDefault()

        navigate(routes.lobby, {
          replace: true,
        })

        if (onExit) onExit()
      }}>
      <StyledImg src={exitIcon} alt="exit" width={12} height={12} />
    </StyledRoundBtn>
  )
})

export default ExitGameBtn
