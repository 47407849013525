import http from '../../services/http'

const clientId: string = process.env.REACT_APP_CLIENT_ID as string
const grantTypePassword: string = 'password'
const grantTypeRefresh: string = 'refresh_token'
const clientSecret: string = process.env.REACT_APP_CLIENT_SECRET as string

export interface ILoginPayload {
  username: string
  password: string
}

export interface ISecretCodeResponse {
  secret_code: string
}

export interface ITokenPayload {
  secret_code: string
  authorization_code: string
}

export interface ITokenData {
  access_token: string // "GLamXz9TQK/kyo7qqALC/hoZcwTntCkreMj1v1x7ycg="
  refresh_token: string // "0TXpDptvp0eUnxWVZYcMzW0cOy1mwFZtVdKEkxpFG8c="
  token_life: number // 10
  token_type: string // "Bearer"
}

export interface ILoginResponse extends ITokenData {
  player: {
    id: string
    login: string
    phones: Array<{
      number: string
      priority: number
    }>
    status: number
  }
}

export interface IRegistrationPasswordPayload {
  login: string
  password: string
  secret_code: string
}

export interface IRegistrationPasswordResponse {
  user: string
  status: string
}

export interface IRegistrationCodePayload {
  registration_code: string
}

export interface IRegistrationCodeResponse {
  secret_code: string
}

export interface IRefreshTokenResponse extends ITokenData {}

class Security {
  prefix: string = '/security/player'

  login = async (data: ILoginPayload): Promise<ISecretCodeResponse> => {
    const response = await http.post(`${this.prefix}/login`, data)
    return response.data
  }

  token = async (payload: ITokenPayload): Promise<ILoginResponse> => {
    const data = new URLSearchParams()
    data.append('client_id', clientId)
    data.append('grant_type', grantTypePassword)
    data.append('client_secret', clientSecret)
    data.append('authorization_code', payload.authorization_code)
    data.append('secret_code', payload.secret_code)

    const response = await http.post(`${this.prefix}/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    return response.data
  }

  refreshToken = async (payload: { refresh_token: string }): Promise<IRefreshTokenResponse> => {
    const data = new URLSearchParams()
    data.append('client_id', clientId)
    data.append('grant_type', grantTypeRefresh)
    data.append('client_secret', clientSecret)
    data.append(grantTypeRefresh, payload.refresh_token)

    const response = await http.post(`${this.prefix}/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    return response.data
  }

  revokeToken = async (payload: { token: string }): Promise<void> => {
    const data = new URLSearchParams()
    data.append('client_id', clientId)
    data.append('token', payload.token)
    data.append('token_type_hint', 'refresh_token')
    data.append('grant_type', 'revoke_token')
    data.append('client_secret', clientSecret)

    await http.post(`${this.prefix}/revoke`, data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
  }

  registrationCode = async (data: IRegistrationCodePayload): Promise<IRegistrationCodeResponse> => {
    const response = await http.post(`${this.prefix}/registration`, data)
    return response.data
  }

  registrationPassword = async (data: IRegistrationPasswordPayload): Promise<IRegistrationPasswordResponse> => {
    const response = await http.post(`${this.prefix}/registration/password`, data)
    return response.data
  }
}

export default new Security()
