import styled from '@emotion/styled'

const PseudoLink = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffc13a;
  cursor: pointer;
`

export default PseudoLink
