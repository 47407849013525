import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Iframe from 'react-iframe'
import styled from '@emotion/styled'
import { ApiSlot } from '../../api'
import { useStore } from '../../stores'
import routes from '../../core/routes'
import ExitGameBtn from '../../components/ExitGameBtn'
import { mediaQueries } from '../../core/theme'
import { FavoriteGameBtn } from '../../components'

const TopNav = styled.div`
  width: 100%;
  height: 44px;
  background-color: #0b1b3a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;

  ${mediaQueries.medium} {
    height: 56px;
  }

  ${mediaQueries.large} {
    height: 68px;
  }
`

const StyledIframe = styled(Iframe)`
  height: calc(100% - 44px);

  ${mediaQueries.medium} {
    height: calc(100% - 56px);
  }

  ${mediaQueries.large} {
    height: calc(100% - 68px);
  }
`

const TopNavRight = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  grid-column-gap: 16px;
`

const Slot: FC = observer(() => {
  const params = useParams()
  const { slotGameId } = params as { slotGameId: string }
  const { i18n } = useTranslation()
  const { loginStore, favoriteSlotListGamesStore, applicationStore } = useStore()
  const navigate = useNavigate()

  const handleGameClose = (): void => {
    navigate(routes.lobby)
  }

  useEffect(() => {
    const fnExit = (event: MessageEvent<any>): void => {
      if (event.data === 'closeGame' || event.data === 'close' || event.data === 'notifyCloseContainer') {
        handleGameClose()
      }
    }
    window.addEventListener('message', fnExit)

    return () => {
      window.removeEventListener('message', fnExit)
    }
  }, [])

  const { data } = useQuery(
    [`open slot game by game id ${slotGameId}`, slotGameId],
    async () => {
      return ApiSlot.openGame(slotGameId, loginStore.user?.login || '', i18n.language, applicationStore.currency)
    },
    {
      enabled: !!slotGameId,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <>
      <TopNav>
        <span>{loginStore.user?.login}</span>
        <TopNavRight>
          <FavoriteGameBtn
            active={favoriteSlotListGamesStore.isFavoriteSlotGame(slotGameId)}
            onClick={() => {
              favoriteSlotListGamesStore.toggleSlotGameId(slotGameId)
            }}
          />
          <ExitGameBtn />
        </TopNavRight>
      </TopNav>
      <StyledIframe
        url={data?.content.game.url || ''}
        width="100%"
        allowFullScreen
        position="absolute"
        frameBorder={0}
        styles={{
          bottom: 0,
          left: 0,
        }}
      />
    </>
  )
})

export default Slot
