import { isEmpty, isNull } from 'lodash'
import { RouletteStore } from '../../stores/Roulette'
import { NameFieldBets } from '../nameFieldBets'
import { DepositErrorCode, ReturnedBets, TYPE_BET_ACTION } from '../../stores/Roulette/Bets.store'
import { TrackNameFieldBets } from '../trackBets'
import { WarningCode } from '../../stores/Roulette/Notification.store'
import { LimitsWarning } from '../rouletteBets'

export default class RouletteGameBets {
  store: RouletteStore

  constructor(p: RouletteStore) {
    this.store = p
  }

  addBet = (betName: NameFieldBets): boolean => {
    if (isNull(this.store.limitsStore.limits)) return false

    const betsInfo = this.store.betsStore.addBet({
      betName,
      limits: this.store.limitsStore.limits,
      deposit: this.store.depositStore.deposit,
      amount: this.store.chipsStore.selectedChip,
      modeBets: this.store.uiStore.modeBets,
    })

    const { isError } = this.processWarnings(betsInfo)

    if (isError) return false

    if (isEmpty(betsInfo.bets)) return false

    this.store.depositStore.decreaseDeposit(betsInfo.sumBets)

    this.store.sendBets({
      type: TYPE_BET_ACTION.ADD,
      validHistory: {
        id: betsInfo.idBets,
        bets: betsInfo.bets,
      },
    })

    return true
  }

  addTrackBet = (trackBetName: TrackNameFieldBets): boolean => {
    if (isNull(this.store.limitsStore.limits)) return false

    const betsInfo = this.store.betsStore.addTrackBets({
      trackBetName,
      limits: this.store.limitsStore.limits,
      deposit: this.store.depositStore.deposit,
      amount: this.store.chipsStore.selectedChip,
      modeBets: this.store.uiStore.modeBets,
    })

    const { isError } = this.processWarnings(betsInfo)

    if (isError) return false

    if (isEmpty(betsInfo.bets)) return false

    this.store.depositStore.decreaseDeposit(betsInfo.sumBets)

    this.store.sendBets({
      type: TYPE_BET_ACTION.ADD,
      validHistory: {
        id: betsInfo.idBets,
        bets: betsInfo.bets,
      },
    })

    return true
  }

  addDoubleBets = (): boolean => {
    if (isNull(this.store.limitsStore.limits)) return false

    if (isEmpty(this.store.betsStore.bets)) return false

    const betsInfo = this.store.betsStore.addDoubleBets({
      limits: this.store.limitsStore.limits,
      deposit: this.store.depositStore.deposit,
    })

    const { isError } = this.processWarnings(betsInfo)

    if (isError) return false

    if (isEmpty(betsInfo.bets)) return false

    this.store.depositStore.decreaseDeposit(betsInfo.sumBets)

    this.store.sendBets({
      type: TYPE_BET_ACTION.ADD,
      validHistory: {
        id: betsInfo.idBets,
        bets: betsInfo.bets,
      },
    })

    return true
  }

  removeLastBets = (): boolean => {
    const betsInfo = this.store.betsStore.removeLastBets()

    if (isNull(betsInfo)) return false

    this.store.depositStore.increaseDeposit(betsInfo.returnedDeposit)

    this.store.sendBets({
      type: TYPE_BET_ACTION.REDUCE,
      id: betsInfo.lastBets.id,
    })

    return true
  }

  removeAllBets = (): boolean => {
    const betsInfo = this.store.betsStore.removeAllBets()

    if (isNull(betsInfo)) return false

    this.store.depositStore.increaseDeposit(betsInfo.returnedDeposit)

    this.store.sendBets({
      type: TYPE_BET_ACTION.REDUCE_ALL,
    })

    return true
  }

  repeatBets = (): void => {
    this.store.sendBets({
      type: TYPE_BET_ACTION.REPEAT,
      validHistory: {
        id: Date.now(),
      },
    })

    this.store.betsStore.setExistsBetsPrevSession(false)
  }

  addWinBets = (): boolean => {
    if (isNull(this.store.limitsStore.limits)) return false

    if (isEmpty(this.store.betsStore.winBets)) return false

    const betsInfo = this.store.betsStore.addWinBets({
      limits: this.store.limitsStore.limits,
      deposit: this.store.depositStore.deposit,
    })

    const { isError } = this.processWarnings(betsInfo)

    if (isError) return false

    if (isEmpty(betsInfo.bets)) return false

    this.store.depositStore.decreaseDeposit(betsInfo.sumBets)

    this.store.sendBets({
      type: TYPE_BET_ACTION.ADD,
      validHistory: {
        id: betsInfo.idBets,
        bets: betsInfo.bets,
      },
    })

    // сбросить выигрышные ставки после того как поставили
    this.store.betsStore.setWinBets({})

    return true
  }

  processWarnings = (
    data: ReturnedBets
  ): {
    isError: boolean
  } => {
    const { limitWarningCode, depositErrorCode } = data

    if (depositErrorCode === DepositErrorCode.NoEnough) {
      this.store.notificationStore.setWarningCode(WarningCode.notEnoughMoneyInfo)
      return {
        isError: true,
      }
    }

    if (limitWarningCode === LimitsWarning.LessMinLimit) {
      this.store.notificationStore.setWarningCode(WarningCode.betLessMinLimit)
    }

    if (limitWarningCode === LimitsWarning.OverMaxLimit) {
      this.store.notificationStore.setWarningCode(WarningCode.betMoreMaxLimit)
    }

    return { isError: false }
  }
}
