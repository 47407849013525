import styled from '@emotion/styled'
import { FC } from 'react'
import icon from './img/icon.svg'

const Img = styled.img`
  display: inline-flex;
`

const CurrencyDiamond: FC = () => {
  return <Img src={icon} alt="currency" width={15} />
}

export default CurrencyDiamond
