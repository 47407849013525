import {
  space,
  layout,
  typography,
  color,
  flexbox,
  SpaceProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
} from 'styled-system'
import styled from '@emotion/styled'

const Box = styled.div<
  SpaceProps & LayoutProps & TypographyProps & ColorProps & FlexboxProps
>`
  ${space}
  ${layout}
  ${typography}
  ${color}
  ${flexbox}
`

export default Box
