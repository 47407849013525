export const rulSocketEvents = {
  CONNECT: 'connect', // default
  CONNECT_ERROR: 'connect_error',
  DISCONNECT: 'disconnect',
  CONNECTED: 'connected',
  JOIN_GAME: 'joinGame',
  END_BET: 'endBet',
  NEW_SESSION_OF_ROULETTE: 'newSessionOfRoulette',
  NO_SPIN: 'noSpin',
  PAUSE: 'pause',
  RESULT: 'result',
  ROULETTE_BET: 'rouletteBet',
  SELECT_NUMBER_ROULETTE: 'selectNumberRoulette',
  SESSION_END: 'sessionEnd',
  SESSION_STATUS: 'sessionStatus',
  SPIN: 'spin',
  STOP_GAME: 'stopGame',
  TIMER: 'timer',
  CHAT: 'chat',
  DEPOSIT: 'deposit',
  FORCE_DISCONNECT: 'forceDisconnect',
  SWITCH_GAME: 'switchGame',
  LEFT_GAME: 'leftGame',
  UNLOAD_GAME: 'unloadGame',
  GAME_ERROR: 'gameError',
  SUBSCRIBE_GAMES: 'subscribeGames',
  MOVE_DEPOSIT_BETWEEN_ROULETTES: 'moveDepositBetweenRoulettes',
  FINISH_GAME: 'finishGame',
}
