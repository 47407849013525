import { ECurrency } from '@lbs/currency'
import http from '../../services/http'
import routes from '../../core/routes'

export interface ISlotListGames {
  status: string
  microtime: number
  dateTime: string
  error: string
  content: Content
}

export interface Content {
  gameLabels: string[]
  gameTitles: string[]
  gameList: ISlotGame[]
}

export interface ISlotGame {
  id: string
  name: string
  name_cn: string
  name_kr: string
  img: string
  label: string
  device: string
  title: string
  categories: string
  flash: string
  vertical: string
  bm: string
  demo: string
  localhost: string
  rewriterule: string
  lines: string
  width: string
  wager: string
  bonus: string
  exitButton: string
  disableReload: string
  menu: string
  system_name2: string
}

export interface IResponseOpenGame {
  status: string
  act: string
  area: string
  microtime: number
  dateTime: string
  error: string
  content: IResponseOpenGameContent
}

export interface IResponseOpenGameContent {
  game: IResponseOpenGameContentGame
  gameRes: IResponseOpenGameContentGameRes
}

export interface IResponseOpenGameContentGame {
  url: string
  iframe: string
  sessionId: string
  width: string
  vertical: string
  withoutFrame: string
  rewriterule: string
  localhost: string
  exitButton_mobile: string
  exitButton: string
  disableReload: string
  wager: string
  bonus: string
}

export interface IResponseOpenGameContentGameRes {
  sessionId: string
}

class Slot {
  prefix = 'api/v2/slots'

  hall = {
    [ECurrency.RUB]: {
      hall: process.env.REACT_APP_RUB_HALL_ID as string,
      key: process.env.REACT_APP_RUB_HALL_KEY as string,
    },
    [ECurrency.USD]: {
      hall: process.env.REACT_APP_USD_HALL_ID as string,
      key: process.env.REACT_APP_USD_HALL_KEY as string,
    },
    [ECurrency.EUR]: {
      hall: process.env.REACT_APP_EUR_HALL_ID as string,
      key: process.env.REACT_APP_EUR_HALL_KEY as string,
    },
    [ECurrency.CNY]: {
      hall: process.env.REACT_APP_CNY_HALL_ID as string,
      key: process.env.REACT_APP_CNY_HALL_KEY as string,
    },
  }

  getGameList = async (currency: ECurrency): Promise<ISlotListGames> => {
    const response = await http.post(`${this.prefix}/games`, {
      ...this.hall[currency],
      cmd: 'gamesList',
    })

    return response.data
  }

  openGame = async (
    gameId: number | string,
    login: string,
    language: string,
    currency: ECurrency
  ): Promise<IResponseOpenGame> => {
    const response = await http.post(`${this.prefix}/game/openGame/`, {
      ...this.hall[currency],
      cmd: 'gameOpen',
      gameId,
      localhost: '0',
      domain: window.location.href,
      exitUrl: `${window.location.origin}${routes.slotClose}`,
      continent: 'eur',
      login,
      language,
    })

    return response.data
  }
}

export default new Slot()
