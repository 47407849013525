import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../core/theme'

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const GlobalStyles = css`
  ${mediaQueries.smallPortrait} {
    body {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: var(--app-height);
      height: var(--app-width);
      overflow-x: hidden;
      position: fixed;
      top: 100%;
      left: 0;
    }
  }
`

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: auto 20px;
  overflow: hidden;

  ${mediaQueries.smallPortrait} {
    width: var(--app-height);
    height: var(--app-width);
  }

  ${mediaQueries.medium} {
    grid-template-columns: auto 40px;
  }
`

export const Main = styled.div<{
  showMobileLayer: boolean
}>`
  padding: 0 10px;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 32px 50px auto 48px;
  background: ${(props) =>
    props.showMobileLayer ? 'linear-gradient(0deg, rgba(27, 27, 27, 0.7) 0%, rgba(27, 27, 27, 0.49) 93.91%)' : 'none'};

  ${mediaQueries.medium} {
    background: none;
    grid-template-rows: 120px 50px auto 80px;
    padding: 0 20px;
  }

  ${mediaQueries.large} {
    padding: 0 40px;
  }
`

export const TopInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

export const TopInfoOfGame = styled.div`
  font-size: 14px;

  ${mediaQueries.medium} {
    font-size: 18px;
  }
`

export const SubContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;

  ${mediaQueries.medium} {
    display: none;
  }
`

export const Fields = styled.div<{
  isTranslucency?: boolean
  disabled?: boolean
}>`
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  // TODO: uncomment
  //opacity: ${(props) => (props.isTranslucency ? 0.4 : 0.8)};

  ${mediaQueries.medium} {
    opacity: 1;
  }
`

export const Mid = styled.div`
  overflow: hidden;
  grid-template-columns: 1fr;
  display: grid;
  user-select: none;

  ${mediaQueries.medium} {
    grid-template-columns: 320px auto; // -20px
    grid-column-gap: 20px;
  }

  ${mediaQueries.large} {
    grid-template-columns: 360px auto;
  }

  ${mediaQueries.big} {
    grid-template-columns: 560px auto;
  }
`

export const PreTop = styled.div`
  display: none;

  ${mediaQueries.medium} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
`

export const TopActions = styled.div`
  display: inline-grid;
  align-items: center;
  grid-template-columns: 1fr;
  width: 100%;

  ${mediaQueries.medium} {
    grid-template-columns: 1fr 1fr;
  }

  ${mediaQueries.large} {
    grid-column-gap: 40px;
  }
`

export const TopActionLeft = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-start;

  ${mediaQueries.medium} {
    display: flex;
  }
`

export const TopActionRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
