import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import { RoundBtn } from '../index'
import repeatIcon from './img/repeat.svg'
import { mediaQueries } from '../../core/theme'
import { useStore } from '../../stores'

const StyledImg = styled.img`
  width: 12px;
  height: 16px;

  ${mediaQueries.medium} {
    width: 20.57px;
    height: 27.43px;
  }

  ${mediaQueries.large} {
    width: 25.71px;
    height: 34.29px;
  }
`

const RepeatBtn = observer((): JSX.Element => {
  const { rouletteStore } = useStore()
  const { betsStore, betsService, statusGameStore } = rouletteStore
  const disabled = !(statusGameStore.isBetting && betsStore.existsBetsPrevSession)

  return (
    <RoundBtn disabled={disabled} onClick={betsService.repeatBets}>
      <StyledImg src={repeatIcon} alt="repeat" />
    </RoundBtn>
  )
})

export default RepeatBtn
