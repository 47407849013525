import { FC, ReactNode } from 'react'
import { ListGamesWrapper, SectionNameListGames, WrapperInsideListGames, WrapperListGames } from './styles'

type LobbyGamesSectionProps = {
  title: string
  titleChildren?: ReactNode
  children?: ReactNode
}

const LobbyGamesSection: FC<LobbyGamesSectionProps> = (props) => {
  const { title, children, titleChildren } = props
  return (
    <WrapperListGames>
      <WrapperInsideListGames>
        <SectionNameListGames>
          {title} {titleChildren}
        </SectionNameListGames>
        <ListGamesWrapper>{children}</ListGamesWrapper>
      </WrapperInsideListGames>
    </WrapperListGames>
  )
}

export default LobbyGamesSection
