import axios from 'axios'
import stores from '../../stores'

export const UNAUTHORIZED_CODE = 401
export const FORBIDDEN_CODE = 403

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL as string,
  timeout: 5000,
})

instance.interceptors.request.use(
  (config) => {
    const { accessToken } = stores.loginStore

    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      }
    }

    return config
  },
  (error) => error
)

instance.interceptors.response.use(
  (response) => response,
  async (err) => {
    // const originalConfig = err.config
    const { loginStore } = stores

    if (loginStore.user && err.response) {
      // Access Token was expired
      // eslint-disable-next-line no-underscore-dangle
      // if (err.response.status === UNAUTHORIZED_CODE && !originalConfig._retry) {
      if (err.response.status === UNAUTHORIZED_CODE) {
        // eslint-disable-next-line no-underscore-dangle
        // originalConfig._retry = true
        try {
          await loginStore.logout()
          // await loginStore.getRefreshToken()
          // return instance(originalConfig)
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
    }
    return Promise.reject(err)
  }
)

export default instance
