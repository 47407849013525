import React from 'react'
import styled from '@emotion/styled'
import Box from '../Box'

type AuthContainerProps = {
  children?: React.ReactNode
}

const Container = styled.div`
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AuthContainer = (props: AuthContainerProps): JSX.Element => {
  const { children } = props
  return (
    <Container>
      <Box pt={10} mt={20} px={20} maxWidth={320} display="flex" justifyContent="center" flexDirection="column">
        {children}
      </Box>
    </Container>
  )
}

export default AuthContainer
