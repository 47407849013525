import { ChangeEvent, FC, InputHTMLAttributes, useState } from 'react'
import { Container, StyledInput, StyledLabel } from './styles'

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  value?: string
  label?: string
  type?: 'text' | 'password' | 'number'
  inputMode?: InputHTMLAttributes<HTMLInputElement>['inputMode']
  id: string
}

const TextField: FC<TextFieldProps> = (props) => {
  const { label = '', type = 'text', id, onChange, inputMode, ...otherProps } = props
  const [value, setValue] = useState<string>(props.value || '')

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value
    setValue(newValue)
    if (onChange) onChange(e)
  }

  return (
    <Container>
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
      <StyledInput value={value} type={type} id={id} inputMode={inputMode} onChange={handleChange} {...otherProps} />
    </Container>
  )
}

export default TextField
