import { observer } from 'mobx-react-lite'
import { NameFieldBets } from '../../services/nameFieldBets'
import { ChipCell } from '../index'
import { useStore } from '../../stores'
import { ChipContainer, TextNumberCell, WrapperTextNumberCell } from './styles'

type CellCoreMainFieldProps = {
  nameField: NameFieldBets
}

const CellCoreMainField = observer((props: CellCoreMainFieldProps): JSX.Element => {
  const { nameField } = props
  const { rouletteStore } = useStore()
  const { betsStore } = rouletteStore

  const amountBet = (nameField && betsStore.bets[nameField]) || 0

  return (
    <>
      <ChipContainer>
        <ChipCell chipValue={amountBet} transparent={betsStore.loseBets.includes(nameField)} />
      </ChipContainer>
      <WrapperTextNumberCell>
        <TextNumberCell data-image={nameField} bg={`${process.env.PUBLIC_URL}/iconTextCell/${nameField}.svg`} />
      </WrapperTextNumberCell>
    </>
  )
})

export default CellCoreMainField
