// текст на русском взят для примера из старой версии
export const gameStatuses = {
  deleted: {
    code: 100,
    name: 'Удалена',
  },
  canceledUser: {
    code: 130,
    name: 'Отменена пользователем',
  },
  canceledManager: {
    code: 140,
    name: 'Отменена менеджером',
  },
  createdUser: {
    code: 210,
    name: 'Создана пользователем',
  },
  createdManager: {
    code: 220,
    name: 'Создана менеджером',
  },
  inProgress: {
    code: 230,
    name: 'В процессе',
  },
  confirmed: {
    code: 240,
    name: 'Подтверждена',
  },
  started: {
    code: 250,
    name: 'Начата',
  },
  finished: {
    code: 290,
    name: 'Закончена',
  },
}
