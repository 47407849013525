import styled from '@emotion/styled'
import { IThumbProps } from 'react-range/lib/types'

export const VolumePercentage = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
`

export const Thumb = styled.div<IThumbProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12px;
  width: 12px;
  background: linear-gradient(180deg, #fff490 0%, #ff9a3d 100%);
  border: 1px solid #0b1b3a;
  box-shadow: 0 20px 20px -10px rgba(255, 240, 140, 0.2);
  border-radius: 50%;
  outline: none;
`

export const Line = styled.div`
  background: #040e23;
  width: 1px;
  height: 6px;
  margin: 0 1px;
`

export const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -36px;
`

export const Img = styled.img`
  width: 16px;
  margin: 0 10px;
`
