import { makeAutoObservable } from 'mobx'

function isTouchDevice(): boolean {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

export class UIStore {
  isTouchDevice: boolean = false

  constructor() {
    makeAutoObservable(this)

    this.isTouchDevice = isTouchDevice()
  }
}
