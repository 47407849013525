import styled from '@emotion/styled'

const Component = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  text-transform: uppercase;
`

const NoMatch = (): JSX.Element => {
  return <Component>404 Not Found</Component>
}

export default NoMatch
