import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { mediaQueries } from '../../core/theme'

const Line = styled.div`
  background: #69c564;
  height: 2px;
  width: 100%;
`

const Wrapper = styled.div`
  margin: 0 -10px;
  overflow: hidden;

  ${mediaQueries.medium} {
    margin: 0 -20px;
  }

  ${mediaQueries.large} {
    margin: 0 -40px;
  }
`

const Timer = observer(
  (): JSX.Element => (
    <Wrapper>
      <Line />
    </Wrapper>
  )
)

export default Timer
