import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mediaQueries } from '../../core/theme'

export const Container = styled.div`
  display: grid;
  align-content: flex-start;
  background: #262626;
  height: 100%;
  overflow: hidden;
`

export const Ball = styled.div<{
  color?: 'black' | 'red' | 'green'
}>`
  ${(props) => {
    switch (props.color) {
      case 'black':
        return css({
          color: '#fff',
        })
      case 'red':
        return css({
          color: '#D74C40',
        })
      case 'green':
        return css({
          color: '#78A977',
        })
      default:
        return css({
          color: '#D74C40',
        })
    }
  }}

  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;

  ${mediaQueries.medium} {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
`

export const WaitBall = styled(Ball)`
  background: #464646;
  color: #fff;
`
